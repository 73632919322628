<div *ngFor="let item of list; let index = index; let first = first; let last = last;" (click)="arrowClick(index)" class="cursor-pointer">

  <div fxLayout="row" style="border-bottom: 1px solid #D8D8D9; padding: 24px 0;">
    <div fxFlex fxLayout="column" *ngIf="!_toTranslate">
      <h5 tabindex="-1" class="cursor-pointer">{{item.title}}</h5>
      <h6 tabindex="-1" [@showIf] *ngIf="item.expanded">{{item.description}}</h6>
    </div>
    <div fxFlex fxLayout="column" *ngIf="_toTranslate">
      <h5 tabindex="-1" class="cursor-pointer" [translate]="item.title"></h5>
      <h6 tabindex="-1" [@showIf] *ngIf="item.expanded" [translate]="item.description"></h6>
      <div tabindex="-1" class="full-width" *ngIf="item.expanded" [@showIf] fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="26px">
        <div fxLayout="row" fxLayoutAlign="start center">
          <svg-icon [src]="first ? 'assets/img/icons/faq/arrow-up-light-red.svg' : 'assets/img/icons/faq/arrow-down-red.svg'"
            class="icon-style" [ngClass]="{'disabled': !first}"></svg-icon>
          <button (click)="moveUpClicked(index, first)">
            <p class="action-button-text" [ngClass]="{'disabled': first}" translate="generic.MOVE_UP"></p>
          </button>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center">
          <svg-icon [src]="last ? 'assets/img/icons/faq/arrow-up-light-red.svg' : 'assets/img/icons/faq/arrow-down-red.svg'"
            class="icon-style" [ngClass]="{'disabled': last}"></svg-icon>
          <button (click)="moveDownClicked(index, last)">
              <p class="action-button-text" [ngClass]="{'disabled': last}" translate="generic.MOVE_DOWN"></p>
          </button>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center">
          <svg-icon src="assets/img/icons/faq/pencil-red.svg"></svg-icon>
          <button (click)="editFAQ(item.itemId)">
            <p class="action-button-text" translate="generic.EDIT"></p>
          </button>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center">
          <svg-icon src="assets/img/icons/faq/bin-red.svg"></svg-icon>
          <button (click)="deleteFAQ(item.itemId)">
              <p class="action-button-text" translate="generic.DELETE"></p>
          </button>
        </div>
      </div>
    </div>
    <div class="expandable-list-arrow">
      <svg-icon tabindex="-1" class="cursor-pointer" [ngClass]="{'opened': item.expanded}"
        src="assets/img/icons/icon-arrow-down.svg"></svg-icon>
    </div>
  </div>

  <!--<div tabindex="-1" [id]="'expandable-list-divider' + index" class="divider"></div>-->

</div>
