<div class="percentage-container" [ngClass]="{'is-my-experience': indicatorData.isMyExperience}" fxLayout="row" fxLayoutAlign="start center">
    <div class="percentage" *ngIf="!indicatorData.isDisabled && indicatorData.percentage > 30 && !indicatorData.isMyExperience" [ngStyle]="{'width': indicatorData.percentage + '%' }"
        [ngClass]="{ 'completed': indicatorData.percentage == 100, 'incomplete': indicatorData.percentage < 100}"
        fxLayout="row" fxLayoutAlign="end center">
        <p>{{ indicatorData.percentage + '%'}}</p>
    </div>
    <div class="percentage" *ngIf="!indicatorData.isDisabled && indicatorData.percentage <= 30 && !indicatorData.isMyExperience" [ngStyle]="{'width': indicatorData.percentage + '%' }"
        [ngClass]="{ 'completed': indicatorData.percentage == 100, 'incomplete': indicatorData.percentage < 100}"
        fxLayout="row" fxLayoutAlign="end center">
    </div>
    <p *ngIf="!indicatorData.isDisabled && indicatorData.percentage <= 30 && !indicatorData.isMyExperience" style="margin-left: 4px">{{ indicatorData.percentage + '%'}}</p>
    <div class="percentage full-width disabled" *ngIf="indicatorData.isDisabled" fxLayout="row"
        fxLayoutAlign="center center">
        <p translate="statuses.DISABLED"></p>
    </div>

    <div class="percentage red" *ngIf="indicatorData.firstStep && indicatorData.isMyExperience" ngClass.xs="width5" [ngStyle]="{'width': indicatorData.percentage + '%' }"
        fxLayout="row" fxLayoutAlign="end center">
    </div>

    <div class="percentage red" *ngIf="indicatorData.secondStep && indicatorData.isMyExperience" [ngStyle]="{'width': indicatorData.percentage + '%' }"
        fxLayout="row" fxLayoutAlign="end center">
    </div>

    <div class="percentage red" *ngIf="indicatorData.thirdStep && indicatorData.isMyExperience" [ngStyle]="{'width': indicatorData.percentage + '%' }"
        fxLayout="row" fxLayoutAlign="end center">
    </div>

    <div class="percentage red" *ngIf="indicatorData.fourthStep && indicatorData.isMyExperience" [ngStyle]="{'width': indicatorData.percentage + '%' }"
        fxLayout="row" fxLayoutAlign="end center">
    </div>

</div>




















<!-- Indicatore originale -->
<!-- div class="circular-indicator-container" fxLayout="column" fxLayoutAlign="center center" [ngClass]="desktopMode ? 'desktop-mode' : ''">
    <!-- Icona ->
    <div class="icon-container">
        <svg-icon src="../../../assets/img/icons/clock-icon.svg"></svg-icon>
    </div>
    <!-- Indicatore percentuale ->
    <div class="indicator-container percentage{{indicatorData.percentage}}" fxLayout="column"
        fxLayoutAlign="center center">
        <div class="circular-progress-overlay" fxLayout="row" fxlayoutAlign="center center">
            <div class="percentage-text-container" fxLayout="row" fxlayoutAlign="center center">
                <div class="percentage-text" fxLayout="row" fxLayoutAlign="center center">{{indicatorData.percentage}}
                    <p class="symbol">%</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Titolo dell'indicatore ->
<div class="title-container" fxLayout="column" fxLayoutAlign="start center" *ngIf="!desktopMode">
    <p>{{ indicatorData.title }}</p>
</div-->