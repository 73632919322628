import { Component } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { SectionTab } from 'src/app/shared/interfaces/tab.interface';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  isDesktop: boolean;

  //#region TABS

  goToGeneric = () => {

  }

  //#endregion

  //#region SOCIALS



  //#endregion

  readonly socialButtons: SectionTab[] = [
    {
      title: 'Twitter',
      redirect: 'https://twitter.com/GENERALI',
      srcIcon: '../../../assets/img/icons/social/twitter.svg'
    }, {
      title: 'YouTube',
      redirect: 'https://www.youtube.com/user/GruppoGenerali',
      srcIcon: '../../../assets/img/icons/social/youtube.svg'
    }, {
      title: 'Instagram',
      redirect: 'https://www.instagram.com/generaligroup/',
      srcIcon: '../../../assets/img/icons/social/instagram.svg'
    }, {
      title: 'Facebook',
      redirect: 'https://www.facebook.com/GeneraliItalia/',
      srcIcon: '../../../assets/img/icons/social/facebook.svg'
    }, {
      title: 'LinkedIn',
      redirect: 'https://www.linkedin.com/company/generali?trk=top_nav_home',
      srcIcon: '../../../assets/img/icons/social/linkedin.svg'
    }
  ];

  readonly firstFooterTabs: SectionTab[] = [
    /*{
      title: 'job.TITLE',
      redirect: this.redirectService.goToJobs
    }, {
      title: 'training.TITLE',
      redirect: this.redirectService.goToCourses
    }, {
      title: 'events.TITLE',
      redirect: this.redirectService.goToEvents
    }, {
      title: 'challenges.TITLE',
      redirect: this.redirectService.goToChallenges
    }, {
      title: 'categories.TITLE',
      redirect: this.redirectService.goToCategories
    }*/
  ];

  readonly secondFooterTabs: SectionTab[] = [
    /*{
      title: 'footer.secondSection.tabs.GENERALI',
      redirect: this.goToGeneric
    }, {
      title: 'footer.secondSection.tabs.COMPANY_CHANGES',
      redirect: this.goToGeneric
    }, {
      title: 'footer.secondSection.tabs.CLAIMS',
      redirect: this.goToGeneric
    }, {
      title: 'footer.secondSection.tabs.LEGAL_NOTES',
      redirect: this.goToGeneric
    }, {
      title: 'footer.secondSection.tabs.PRIVACY',
      redirect: this.goToGeneric
    }, {
      title: 'footer.secondSection.tabs.COOKIES',
      redirect: this.goToGeneric
    }*/
  ];

  constructor(
    private modalService: ModalService,
    private redirectService: RedirectService,
    deviceService: DeviceDetectorService,
    private analyticsService: AnalyticsService) {

    this.isDesktop = deviceService.isDesktop();

  }

  // Apre la modale con la privacy
  openPrivacyModal() {
    // Apro la modale
    this.modalService.open('application-privacy');
  }

  // Apre un link social (Twitter, youtube, ecc.)
  openSocialLink(destinationUrl: string) {
    // Invio un tracciamento a Google Tag Manager
    this.analyticsService.sendOutboundLinkEvent(destinationUrl);
    // Apro l'url
    window.open(destinationUrl);
  }

}
