import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectionStrategy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'badges',
  templateUrl: './badges.component.html',
  styleUrls: ['./badges.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class BadgesComponent implements OnInit {
  @Input() percentage: number;
  @Input() badgesList: any;
  @Output() badgeClicked = new EventEmitter<any>();
  isUsingIE: boolean;

  constructor(private translate: TranslateService) {
    this.isUsingIE = this.isInternetExplorerBrowser();
  }

  ngOnInit(): void {
  }

  isInternetExplorerBrowser() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ");

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      return true;
    }
    return false;
  }

  // Premo un badge
  emitOnBadgeClicked(badges, indicator) {
    let data = {
      badges: badges,
      indicator: indicator
    }
    this.badgeClicked.emit(data);
  }
}
