<div [ngClass]="inputWrapperClass" class="input-wrapper">
  <label *ngIf="formTitle" class="control-label">{{ formTitle }}</label>
  <div [ngClass]="containerClass ? containerClass : 'small-dimension'">
    <ng-select #alloySelect [virtualScroll]="virtualScroll" [labelForId]="labelForId" [bindValue]="bindValue"
      [bindLabel]="bindLabel" [items]="items" placeholder="{{ placeholderLabel }}" [multiple]="multiple"
      (add)="emitAddItem($event)" [disabled]="disabled" (remove)="emitRemoveItem($event)"
      (change)="emitOnSelectionChanged($event)" [(ngModel)]="bindModel" (ngModelChange)="emiOnModelChanged($event)"
      [dropdownPosition]="dropdownPosition" (scrollToEnd)="emitOnScrollToEnd($event)" (close)="emitOnClose()"
      [addTag]="addTag" attr.aria-label="{{ attrAriaLabel }}" [clearOnBackspace]="clearOnBackspace"
      [searchable]="searchable" [hideSelected]="hideSelected" [typeahead]="typeahead" [loading]="loading"
      [ngClass]="{'flex-1-ie': ieFlexProblem, 'search-input-training-passport': selectTrainingPassportClass}"
      [compareWith]="compareWithFn" [markFirst]="markFirst" #alloySelect>

      <!-- Elementi selezionati -->
      <ng-template class="alloy-select" ng-label-tmp let-item="item" let-clear="clear">
        <!-- <div *ngIf="multiple && !areUsersList && !areGroupsList && !isIDPCategorySelect" fxLayout="row"
          fxLayoutAlign="start center" style="padding: 5px 10px;" [ngClass]="{'faculty-wrap' : isFacultySelect}"
          fxLayourGap="8px">
          <p>{{resolveItemTitle(item, true)}}</p> -->
        <!-- ho dovuto aggiungere un div dove aggangiare il click per risolvere un problema con ng-select, si rompeva non trovando il .classname sull'elemento SVG al click della X -->
        <!-- <div (click)="clear(item);" style="max-height: 16px; margin-left: 8px;">
            <svg-icon aria-hidden="true" src="assets/img/icons/icon-trash.svg"></svg-icon>
          </div>
        </div> -->
        <div class="container-select-chip" *ngIf="multiple && !areUsersList && !areGroupsList && !isIDPCategorySelect && !isIconSelect()"
          fxLayout="row" fxLayoutAlign="space-between center">
          <p tabindex="0">{{resolveItemTitle(item, true)}}</p>
          <button (click)="clear(item);" attr.aria-label="{{'myexperience.REMOVE_PROFESSIONAL_AREA' | translate}}">
            <svg-icon src="assets/img/icons/ios-close-empty.svg"></svg-icon>
          </button>
        </div>
        <div *ngIf="!multiple && !areUsersList && !areGroupsList && !isIconSelect()" fxLayout="row" fxLayoutAlign="start center">
          <p class="margin0">{{resolveItemTitle(item, true)}}</p>
        </div>
        <div *ngIf="isIDPCategorySelect && !multiple && !areUsersList && !areGroupsList && !isIconSelect()" fxLayout="row"
          fxLayoutAlign="start center">
          <p class="margin0">{{ item.title }}</p>
        </div>
        <div *ngIf="!isIDPCategorySelect && !multiple && !areUsersList && !areGroupsList && isIconSelect()" fxLayout="row"
          fxLayoutAlign="start center">
          <svg-icon class="margin0" [src]="item.icon" style="width: 41px; height: 31px;"></svg-icon>
          <p class="margin0">{{ item.title | translate }}</p>
        </div>
      </ng-template>

      <!-- Template per l'inizio della ricerca -->
      <ng-template class="alloy-select" ng-typetosearch-tmp>
        <div class="ng-option disabled">{{ startSearchLabel || 'Inizia una ricerca' }}</div>
      </ng-template>
      <!-- Template per i dati non trovati -->
      <ng-template class="alloy-select" ng-notfound-tmp let-searchTerm="filter">
        <div class="ng-option disabled">{{ noDataFoundLabel || 'Nessun dato trovato' }}</div>
      </ng-template>
      <!-- Template per il caricamento dei risultati -->
      <ng-template class="alloy-select" ng-loadingtext-tmp>
        <div class="ng-option disabled">{{ loadingData || 'Caricamento in corso' }}</div>
      </ng-template>
      <!-- Template per i singoli item nelle options -->
      <ng-template class="alloy-select" ng-option-tmp let-item="item" let-search="filter">
        <div
          *ngIf="!areUsersList && !areMailAndNameList && !areMailtemplateList && !isIDPCategorySelect && !isAdminSelect() && !isIconSelect()"
          class="default-option" fxLayout="row">
          <div *ngIf="item.containerClass">
            <span [ngClass]="item.containerClass" class="skills-legend"></span>
          </div>
          <p class="margin0 truncate" [ngClass]="(noWhitespace == 'true' ? 'no-whitespace' : '')">
            {{resolveItemTitle(item)}}</p><!---->
        </div>
        <!-- con tooltip -->
        <div
          *ngIf="!areUsersList && !areMailAndNameList && !areMailtemplateList && !isIDPCategorySelect && !isIconSelect() && isAdminSelect()"
          class="default-option" fxLayout="row" hide-delay="10" tooltip-class="admin-filter-tooltip" placement="right"
          tooltip="{{resolveItemTitle(item)}}" trigger="hover">
          <div *ngIf="item.containerClass">
            <span [ngClass]="item.containerClass" class="skills-legend"></span>
          </div>
          <p class="margin0 truncate" [ngClass]="(noWhitespace == 'true' ? 'no-whitespace' : '')">
            {{resolveItemTitle(item)}}</p>
        </div>

        <div *ngIf="isIconSelect()">
          <div class="select-options-card">
            <div class="card-body" fxLayout="row">
              <div class="full-width" fxLayout="row" fxLayoutAlign="start center">
                <svg-icon class="icon-select-size margin0" [src]="item.icon"></svg-icon>
                <p class="subtitle margin0">{{ item.title | translate }}</p>
              </div>
            </div>
          </div>
        </div>

        <!-- Template per i singoli item nelle options (se è una lista categorie IDP) -->
        <div *ngIf="isIDPCategorySelect">
          <div class="select-options-card">
            <div class="card-body" fxLayout="row">
              <div class="full-width" fxLayout="column">
                <p class="title margin0">{{ item.title }}</p>
                <p class="subtitle margin0">{{ item.desc }}</p>
              </div>
            </div>
          </div>
        </div>

        <!-- Template per i singoli item nelle options (se è una lista di utenti) -->
        <div *ngIf="areUsersList">
          <div class="select-options-card">
            <div class="card-body" fxLayout="row">
              <div class="full-width" fxLayout="row">
                <div class="margin-right10">
                  <img class="img-avatar-coursemanager-home small-avatar" [src]="getUserAvatar(item)"
                    alt="{{ avatarImageAccessibilityText }}" title="{{ avatarImageAccessibilityText }}">
                </div>
                <div fxLayout="column" class="padding-right25">
                  <p class="bold-text margin-bottom0">{{item.forename}} {{item.surname}}</p>
                  <h5>{{item.defTpDip}}</h5>
                  <h5 class="bold-text">{{item.cid}}</h5>
                  <h5>{{item.email}}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!-- Template per i singoli item nelle options (se è lista di nome-email) -->
        <div *ngIf="areMailAndNameList" class="select-options-card">
          <div class="card-body" fxLayout="row" fxLayoutAlign="start center">
            <div fxLayout="column">
              <svg-icon class="svg icon" src="assets/img/icons/gear-email.svg"></svg-icon>
            </div>
            <div fxLayout="column">
              <span class="card-title">{{item.name}}</span>
              <span class="card-subtitle italic text-muted">{{item.mail}}</span>
            </div>
          </div>
        </div>
        <!-- Template per i singoli item nelle options (se è lista dei template mail) -->
        <div *ngIf="areMailtemplateList" class="select-options-card">
          <div class="card-body" fxLayout="row" fxLayoutAlign="start center">
            <div fxLayout="column">
              <svg-icon class="svg icon" src="assets/img/icons/gear-email.svg"></svg-icon>
            </div>
            <div fxLayout="column">
              <span class="card-title">{{item.name}}</span>
              <span class="card-subtitle italic text-muted">{{item.subject}}</span>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-select>

    <div fxLayout="row" *ngIf="required && !bindModel && (focused || forceShowError)" class="input-error-message">
      {{ errorRequiredText }}
    </div>
  </div>
</div>
