<!-- Sidenav di sinistra -->
<ng-sidebar-container
  [ngClass]="{'scrollbar-hidden': isMainMenuSidebarOpened, 'gray-background-sidebar-content': (isThisCurrentPage('users') || isThisCurrentPage('newEditExperience') || isThisCurrentPage('experienceDetail')) }">
  <!-- Sidebar di sinistra contente il menu principale -->
  <ng-sidebar *ngIf="!(isFetchingLangs$ | async)" [(opened)]="isMainMenuSidebarOpened" mode="over" keyClose="true"
    position="left" closeOnClickBackdrop="true" showBackdrop="true" [animate]="initialAnimateEnabled" trapFocus="true"
    autoFocus="true" sidebarClass="main-menu-sidebar" (onCloseStart)="onMainMenuSidenavClose()"
    (onOpenStart)="onProfileMenuSidenavClose()" role="menu">
    <p translate="generic.MENU" class="title">
    </p>
    <button class="btn-menu" style="margin-top: -50px;" (click)="toggleMainMenuSidebar()"
      attr.aria-label="{{ isMainMenuSidebarOpened ? ('header.CLOSE_MAIN_MENU' | translate ) : ('header.OPEN_MAIN_MENU' | translate ) }}">
      <svg-icon hide-delay="50" tooltip-class="custom-tooltip"
        [src]="isMainMenuSidebarOpened ? 'assets/img/close-menu.svg' : 'assets/img/main-menu.svg'" placement="bottom"
        id="mainMenuSidebarToolip" display-mobile="false" class="header-icon-hamburger-menu">
      </svg-icon>
    </button>
    <!-- Redirect alla home -->
    <button class="item-list no-style-button" (click)="redirectService.goToHome()" role="menuitem">
      <p tabindex="0" translate="routing.HOME">
      </p>
      <!--svg-icon src="assets/img/gear-freccia-verso-destra.svg"></svg-icon-->
    </button>
    <!-- Redirect al backoffice per admin e hrbp -->
    <button
      *ngIf="isAdmin && (window.location.href.indexOf('/users/') >= 0 || window.location.href.indexOf('/contributor/') >= 0)"
      class="item-list no-style-button" role="menuitem"><!--(click)="isManager ? redirectService.goToUser() : redirectService.goToContributor()"-->
      <p tabindex="0" translate="routing.BACKOFFICE_ADMIN">
      </p>
    </button>
    <!-- Redirect al backoffice per admin e hrbp -->
    <button
      *ngIf="!isAdmin && isHRBP && (window.location.href.indexOf('/users/') >= 0 || window.location.href.indexOf('/contributor/') >= 0)"
      class="item-list no-style-button" role="menuitem">
      <p tabindex="0" translate="routing.BACKOFFICE_HRBP">
      </p>
    </button>
    <!-- Redirect al lato utente -->
    <button
      *ngIf="(isHRBP || isAdmin) && !(window.location.href.indexOf('/users/') >= 0) && !(window.location.href.indexOf('/contributor/') >= 0)"
      class="item-list no-style-button"
      role="menuitem"><!--(click)="isManager ? redirectService.goToUser() : redirectService.goToContributor()"-->
      <p tabindex="0" translate="routing.USER">
      </p>
    </button>


    <!-- Redirect alla pagina con le notifiche -->
    <!--button class="item-list no-style-button" (click)="redirectService.goToNotifications()" role="menuitem">
      <p translate="routing.NOTIFICATIONS">
      </p>
      < !-- svg-icon src="assets/img/gear-freccia-verso-destra.svg"></svg-icon->
    </button-->
    <!-- Redirect al profilo dell'utente loggato -->
    <button *ngIf="isManager && !isHRBP" class="item-list no-style-button"
      role="menuitem"><!--(click)="redirectService.goToPotential(potentialId, currentYear)"-->
      <p translate="routing.PROFILE">
      </p>
      <!--svg-icon src="assets/img/gear-freccia-verso-destra.svg"></svg-icon-->
    </button>
    <!-- Redirect alla pagina del team -->
    <button *ngIf="isManager && !isHRBP" class="item-list no-style-button"
      role="menuitem"><!--(click)="redirectService.goToPotential(potentialId, currentYear)"-->
      <p translate="routing.TEAM">
      </p>
      <!--svg-icon src="assets/img/gear-freccia-verso-destra.svg"></svg-icon-->
    </button>

    <!-- Redirect alla pagina della gestione dei touchpoints -->
    <button *ngIf="isManager && !isHRBP" class="item-list no-style-button" (click)="touchpointClicked()"
      role="menuitem">
      <p translate="routing.TOUCHPOINTS">
      </p>
      <svg-icon *ngIf="!isTouchpointOpened" class="icon-right" src="assets/img/icons/arrow-right.svg"></svg-icon>
      <svg-icon *ngIf="isTouchpointOpened" class="icon-right" src="assets/img/icons/arrow-down.svg"></svg-icon>
    </button>
    <!-- Sottomenu dei touchpoint-->
    <div class="menu-touchpoints" *ngIf="!isLoadingPhases && isTouchpointOpened" fxLayout="column"
      fxLayoutAlign="start start">

      <button class="item-list no-style-button fixed-sidebar-width" *ngIf="performanceId"
        role="menuitem"><!--(click)="redirectService.goToPotential(potentialId, currentYear)"-->
        <div class="full-width" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="2px">
          <p>{{ ('routing.touchpoints.PERFORMANCE' | translate) + ' ' + currentYear }}</p>
        </div>
      </button>
      <button class="item-list no-style-button fixed-sidebar-width" *ngIf="skillsId"
        role="menuitem"><!--(click)="redirectService.goToPotential(potentialId, currentYear)"-->
        <div class="full-width" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="2px">
          <p>{{ ('routing.touchpoints.SKILLS' | translate) + ' ' + currentYear }}</p>
        </div>
      </button>
      <button class="item-list no-style-button fixed-sidebar-width" *ngIf="potentialId"
         role="menuitem"><!--(click)="redirectService.goToPotential(potentialId, currentYear)"-->
        <div class="full-width" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="2px">
          <p>{{ ('routing.touchpoints.POTENTIAL' | translate) + ' ' + currentYear }}</p>
        </div>
      </button>
      <button class="item-list no-style-button fixed-sidebar-width" *ngIf="idpId"
        role="menuitem"><!--(click)="redirectService.goToIDP(idpId, currentYear)" -->
        <div class="full-width" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="2px">
          <p>{{ ('routing.touchpoints.IDP' | translate) + ' ' + (currentYear + 1) }}</p>
        </div>
      </button>
      <button [disabled]="blockedFeedback" class="item-list no-style-button fixed-sidebar-width" *ngIf="feedbackId"
        role="menuitem"><!--(click)="redirectService.goToFeedback(feedbackId, currentYear)"-->
        <div class="full-width" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="2px">
          <p>{{ ('routing.touchpoints.FEEDBACK' | translate) + ' ' + currentYear }}</p>
        </div>
      </button>
      <button class="item-list no-style-button fixed-sidebar-width" *ngIf="goalId"
        role="menuitem">
        <div class="full-width" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="2px">
          <p>{{ ('routing.touchpoints.GOAL' | translate) + ' ' + (currentYear + 1) }}</p>
        </div>
      </button>
    </div>
    <!-- Redirect alla pagina dei contributor -->
    <!--button class="item-list no-style-button" (click)="redirectService.goToContributor()" role="menuitem">
      <p translate="routing.CONTRIBUTOR">
      </p>
    </button-->
    <!-- Redirect alla pagina della dashboard -->
    <button *ngIf="isHRBP || isAdmin" class="item-list no-style-button"
      role="menuitem">
      <p *ngIf="isHRBP" translate="routing.DASHBOARD_HRBP">
      <p *ngIf="isAdmin" translate="routing.DASHBOARD_ADMIN">
      </p>
    </button>
    <!-- Redirect alla pagina delle impostazioni -->
    <!--button *ngIf="isManager" class="item-list no-style-button" (click)="redirectService.goToSettings()"
      role="menuitem">
      <p translate="routing.SETTINGS">
      </p>
    </button-->
    <!-- Redirect alla pagina delle fAQ -->
    <button *ngIf="isManager && !isHRBP" class="item-list no-style-button"
      role="menuitem">
      <p translate="routing.FAQ">
      </p>
      <!--svg-icon src="assets/img/gear-freccia-verso-destra.svg"></svg-icon-->
    </button>
    <!-- Redirect alla pagina del tutorial -->
    <button class="item-list no-style-button"
      role="menuitem">
      <p translate="routing.TUTORIAL">
      </p>
      <!--svg-icon src="assets/img/gear-freccia-verso-destra.svg"></svg-icon-->
    </button>
    <!-- Redirect alla pagina del tutorial -->
    <button class="item-list no-style-button" (click)="openSupportModal()" role="menuitem">
      <p translate="routing.SUPPORT">
      </p>
    </button>
    <!-- Home Utente -->
    <button *ngIf="false" class="item-list no-style-button" role="menuitem"><!-- (click)="redirectService.goToUsersHome()"-->
      <p translate="routing.USER"></p>
    </button>
    <!-- Home Admin o HRBP -->
    <!-- <button *ngIf="isAdmin || isHRBP" class="item-list no-style-button" (click)="redirectService.goToHomeManage()" role="menuitem">
      <p translate="routing.ADMIN_HRBP_HOME"></p>
    </button> -->
    <!-- Logout -->
    <!--button class="item-list no-style-button" (click)="onLogout()" role="menuitem">
      <p translate="routing.LOGOUT">
      </p>
      <!--svg-icon src="assets/img/gear-freccia-verso-destra.svg"></svg-icon->
    </button-->
  </ng-sidebar>

  <!-- Sidebar di destra contenente le impostazioni di profilo (notifiche, impostazioni, logout) -->
  <ng-sidebar *ngIf="!(isFetchingLangs$ | async)" [(opened)]="isProfileMenuSidebarOpened" mode="over" keyClose="true"
    position="right" closeOnClickBackdrop="true" showBackdrop="true" [animate]="initialAnimateEnabled" trapFocus="true"
    autoFocus="true" sidebarClass="profile-menu-sidebar" role="menu" (onCloseStart)="onProfileMenuSidenavClose()"
    (onOpenStart)="onMainMenuSidenavClose()">
    <div *ngIf="loggedUser && loggedUser.user" role="menuitem" class="margin-top16">
      <img class="img-avatar-coursemanager-home" [src]="avatar"
        alt="{{'accessibilityAttributes.AVATAR_IMAGE_USER' | translate}}"
        title="{{'accessibilityAttributes.AVATAR_IMAGE_USER' | translate}}">
      <div class="wrapper-name-role truncate">
        <p class="user-name margin0">{{ loggedUser.user.forename }} {{ loggedUser.user.surname }}</p>
        <p *ngIf="loggedUser.user.jobFamilyPrimaria" class="user-role margin0">{{ loggedUser.user.jobFamilyPrimaria }}
        </p>
      </div>
    </div>
    <hr>
    <div *ngIf="false" class="item-list" role="menuitem" (click)="redirectService.goToHome()" role="menuitem">
      <span class="badge-notify-coursemanager-sidenav margin0">{{notificationList.length}}</span>
      <p translate="header.NOTIFICATIONS">
      </p>
      <!--svg-icon src="assets/img/gear-freccia-verso-destra.svg"></svg-icon-->
    </div>
    <div *ngIf="false" class="item-list" role="menuitem"
      role="menuitem"><!-- (click)="redirectService.goToProfile(currentYear)"-->
      <svg-icon src="assets/img/gear-impostazioni.svg"></svg-icon>
      <p translate="header.SETTINGS">
      </p>
      <!--svg-icon src="assets/img/gear-freccia-verso-destra.svg"></svg-icon-->
    </div>
    <!-- Logout -->
    <!--div class="item-list" role="menuitem" (click)="onLogout()">
      <p translate="header.LOGOUT">
      </p>
    </div-->
  </ng-sidebar>

  <div ng-sidebar-content>
    <app-header ngClass.xs="mobile"
      *ngIf="isAuthenticated && !(isFetchingLangs$ | async) && !isThisCurrentPage('nineboxmatrix') && !isThisCurrentPage('403') && !isThisCurrentPage('comparison') && !isThisCurrentPage('comparison-potential')"
      (activateAnimation)="activateAnimation()" class="sticky-header"></app-header>

    <!-- Nome della pagina-->
    <div fxLayout="row" *ngIf="!(isFetchingLangs$ | async) && !showApplicationLoader && getPageName()" fxFlexFill
      fxLayoutAlign="center center" class="sectionTitle">
      <p>{{getPageName()}}</p>
    </div>
    <!-- Se si sta impersonificando qualcuno-->
    <div fxLayout="row"
      *ngIf="!(isFetchingLangs$ | async) && !showApplicationLoader && loggedUser && isImpersonificationBanner"
      fxFlexFill fxLayoutAlign="center center" class="sectionTitle">
      <p>{{'admin.YOU_ARE_IMPERSONIFICHING' | translate}} {{loggedUser.user.forename}} {{loggedUser.user.surname}}</p>
    </div>
    <router-outlet *ngIf="!(isFetchingLangs$ | async) && !showApplicationLoader">
    </router-outlet>
    <app-footer *ngIf="!(isFetchingLangs$ | async) && !showApplicationLoader && isAuthenticated && showFooter">
    </app-footer>

    <div *ngIf="!(isFetchingLangs$ | async) && !showApplicationLoader && isAuthenticated && isUsersHome()"
      class="footer" fxLayout="column" fxLayoutAlign="start start">
      <div class="content footer-padding">
        <div fxLayout="row" fxLayoutAlign="start center">
          <h1 translate="generic.NEED_HELP"></h1>
          <!--<svg-icon class="support" src="assets/img/application-pics/support.svg"></svg-icon>-->
        </div>
        <div class="links" fxLayout="column" fxLayoutAlign="start start">
          <p tabindex="0" translate="generic.FAQ"></p>
          <p tabindex="0" translate="generic.VIDEOS">
          </p>
        </div>
        <div class="support-section" fxLayout="column" fxLayoutAlign="start start">
          <p tabindex="0" translate="generic.SUPPORT"></p>
          <span class="margin-bottom8" tabindex="0" translate="generic.SUPPORT_DESCR"></span>
          <a class=" full-width red-link" tabindex="0" target="_blank"
            (click)="sendOutboundLinkEvent('http://nuovaassistenza-dir.generali.it/arsys/forms/lmlifh25itp100.generali.it/SRS:ServiceRequestConsole')"
            href="http://nuovaassistenza-dir.generali.it/arsys/forms/lmlifh25itp100.generali.it/SRS:ServiceRequestConsole">http://nuovaassistenza-dir.generali.it/arsys/forms/lmlifh25itp100.generali.it/SRS:ServiceRequestConsole</a>
          <button class="padding-0" (click)="downloadTicketsGuide()">
            <p class="red-link underline" tabindex="0" translate="generic.DOWNLOAD_GUIDE"></p>
          </button>
        </div>
      </div>
    </div>

    <!-- Modale supporto -->
    <modal id="supportModal" [showBackButton]="true">
      <div class="control-matrice-modal-container support-modal">
        <div class="full-width" fxLayout="row" fxLayoutAlign="center center">
          <!--<svg-icon src="assets/img/application-pics/support.svg"></svg-icon>-->
        </div>
        <!-- Titolo-->
        <div class="title-container" fxLayout="column" fxLayoutAlign="center center">
          <h3 translate="generic.SUPPORT"></h3>
        </div>
        <div class="area-modal-content" fxLayout="column" fxLayoutAlign="center center">
          <span class="margin-bottom8" tabindex="0" translate="generic.SUPPORT_DESCR"></span>
          <a class="red-link" tabindex="0" target="_blank"
            (click)="sendOutboundLinkEvent('http://nuovaassistenza-dir.generali.it/arsys/forms/lmlifh25itp100.generali.it/SRS:ServiceRequestConsole')"
            href="http://nuovaassistenza-dir.generali.it/arsys/forms/lmlifh25itp100.generali.it/SRS:ServiceRequestConsole">http://nuovaassistenza-dir.generali.it/arsys/forms/lmlifh25itp100.generali.it/SRS:ServiceRequestConsole</a>
        </div>

        <div class="full-width close-button-container">
          <custom-button (onButtonClicked)="downloadTicketsGuide()" class="full-width" dimension="full-width" importance="text-only"
            text="{{ 'generic.DOWNLOAD_GUIDE' | translate }}" fxLayout="row" fxLayoutAlign="center center">
          </custom-button>
        </div>
      </div>
    </modal>

  </div>
</ng-sidebar-container>

<div *ngIf="(isFetchingLangs$ | async) || showApplicationLoader" class="app-loading">
  <div class="logo"></div>
  <div class="lds-ellipsis ">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <p *ngIf="true">Caricamento in corso</p>
  </div>
</div>