/*
 * Componente per l'errore 404, pagina richiesta non trovata
*/

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsService } from '../../shared/services/analytics.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  constructor(private analyticsService: AnalyticsService,
    private router: Router) { }

  ngOnInit() {
    // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
    //this.analyticsService.sendVirtualPageViewEvent(this.router.url, "Errore pagina non trovata");
  }

}
