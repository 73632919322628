import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Router } from "@angular/router";
import { map, tap, switchMap, mergeMap, withLatestFrom } from "rxjs/operators";
import { from, Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import * as CoreActions from "../../core/ngrx/core.actions";
import * as ProfileActions from "../../users/profile/ngrx/profile.actions";
import { Store } from "@ngrx/store";
import * as AuthActions from "./auth.actions";
import { AuthService } from "../services/auth.service";
import { ToastrService } from "ngx-toastr";
import * as fromApp from "../../ngrx/app.reducers";
import { SenecaResponse, UserAcknowledges, JwtPayload, ErrorCodes } from "../../../cm2-commonclasses";
import { TranslateService } from "@ngx-translate/core";
import { authControl } from "src/app/shared/models/global-application-data.model";
import { UrlService } from "src/app/shared/services/url.service";
import { DeviceDetectorService } from 'ngx-device-detector';
import { LangsService } from "src/app/core/services/langs.service";
// Importo il componente per decodificare il token JWT
const jwtDecode = require("jwt-decode");

@Injectable()
export class AuthEffects {
  constructor(
    private urlService: UrlService,
    private store: Store<fromApp.AppState>,
    private actions$: Actions,
    private router: Router,
    private langsService: LangsService,
    private authService: AuthService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private deviceService: DeviceDetectorService
  ) {
    let redirectUrl$: Observable<string> = this.store.select(
      fromApp.getRedirectUrl
    );
    redirectUrl$.subscribe(redirectUrl => {
      this.redirectUrl = redirectUrl;
    });
  }

  token: string;
  tinyToken: string;
  redirectUrl: string;
  isSso: boolean;
  authDataMail: string;
  tinyTokenObj;

  @Effect()
  authLogin$ = this.actions$
    .pipe(
      ofType(AuthActions.DO_LOGIN)
      , map((action: AuthActions.DoLogin) => {
        return action.payload;
      }),
      switchMap(
        (authData: {
          email: string;
          password: string;
          isSso?: boolean;
        }) => {

          this.isSso = authData.isSso;
          this.authDataMail = authData.email;
          const deviceInfo = this.deviceService.getDeviceInfo();
          const userAgent = deviceInfo && deviceInfo.userAgent;
          let deviceType;
          if (this.deviceService.isMobile()) {
            // Salvo il fatto che è uno smartphone
            deviceType = "P";
          } else if (this.deviceService.isTablet()) {
            // Salvo il fatto che è un tablet
            deviceType = "T";
          } else if (this.deviceService.isDesktop()) {
            // Salvo il fatto che è un computer desktop
            deviceType = "D";
          }
          return from(
            this.authService.login(
              authData.email,
              authData.password,
              deviceType,
              userAgent
            )
          );
        }
      ),
      switchMap((tinyTokenObj: SenecaResponse<any>) => {
        if (tinyTokenObj.error) {
          throw new Error(tinyTokenObj.error);
        }
        this.tinyTokenObj = tinyTokenObj;
        return from(this.authService.getJWTToken(tinyTokenObj.response));
      }),
      mergeMap((tinyTokenObj: SenecaResponse<any>) => {
        let decodedJwt: JwtPayload = jwtDecode(tinyTokenObj.response);
        let actionsContainer = [];
        let authObject = null;
        authObject = authControl(decodedJwt && decodedJwt.auths);
        if (this.redirectUrl) {
          this.router.navigate([this.redirectUrl]);
        // } else if(authObject.isHRBP || authObject.isAdmin || authObject.isManager) {
        } else if(authObject.isAdmin || authObject.isAdminEbrm || authObject.isAdminAts) {
          this.router.navigate(["admin"]);
        } else {
          this.router.navigate(["users"]);
        }
        actionsContainer.push(
          {
            type: AuthActions.SET_TOKEN,
            payload: this.tinyTokenObj.response
          },
          {
            type: AuthActions.SET_USER_AUTHENTICATED
          },
          {
            type: CoreActions.REMOVE_REDIRECT_URL
          },
          {
            type: CoreActions.START_RENEW_TOKEN_POLLING
          }
        );

        return actionsContainer;
      }),
      catchError((err, caught) => {
        if (err && err.message) {
          this.toastr.error(this.translate.instant("errors." + err.message));
        }
        return caught;
      })
    );

  // Effect che recupera la lista delle userAcknowledges dell'utente collegato
  @Effect()
  acknowledgedGet = this.actions$
    .pipe(
      ofType(AuthActions.RETRIEVE_USER_ACKNOWLEDGES)
      , switchMap(() => {
        return from(this.authService.getAllUserAcknowledges());
      })
      , map(
        (data: SenecaResponse<UserAcknowledges>) => {
          if (data) {
            if (data.error) {
              // Catturo l'errore
              throw (new Error(data.error));
            } else {
              return {
                type: AuthActions.SET_USER_ACKNOWLEDGES,
                payload: data.response
              }
            }
          }
        }
      )
      , catchError((err, caught) => {
        if (err && err.message) {
          this.toastr.error(this.translate.instant('errors.' + err.message));
        }
        this.store.dispatch(new AuthActions.SetUserAcknowledges(null));
        return caught;
      })
    );

  // Effect che aggiorna la lista delle userAcknowledges dell'utente collegato
  @Effect()
  acknowledgedUpdate = this.actions$
    .pipe(
      ofType(AuthActions.UPDATE_USER_ACKNOWLEDGES)
      , switchMap((action: any) => {
        return from(this.authService.updateUserAcknowledges(action.payload));
      })
      , map(
        (data: SenecaResponse<UserAcknowledges>) => {
          if (data) {
            if (data.error) {
              // Catturo l'errore
              throw (new Error(data.error));
            } else {
              return {
                type: AuthActions.RETRIEVE_USER_ACKNOWLEDGES,
                payload: null
              }
            }
          }
        }
      )
      , catchError((err, caught) => {
        if (err && err.message) {
          this.toastr.error(this.translate.instant('errors.' + err.message));
        }
        this.store.dispatch(new AuthActions.SetUserAcknowledges(null));
        return caught;
      })
    );

    @Effect()
    authLogout$ = this.actions$
      .pipe(
        ofType(AuthActions.LOGOUT)
        , switchMap(() => {
          return from(this.authService.logout());
        })
        , mergeMap(
          (data: SenecaResponse<any>) => {
            if(data.response === null && data.error === null) {
              
            let actionsContainer = [
              {
              type: AuthActions.SESSION_LOGOUT
              },
              {
              type: AuthActions.SET_USER_ACKNOWLEDGES,
              payload: null
              },
              {
              type: CoreActions.REMOVE_APPLICATION_LANG
              },
              {
                type: ProfileActions.CANCEL_LOGGED_USER
                }
            ];
            this.router.navigate(['/login']);
            return actionsContainer;
          }
        }
        )
        , catchError((err, caught) => {
          if (err && err.message) {
            this.toastr.error(this.translate.instant('errors.' + err.message));
          }
          return caught;
        })
      );

      @Effect()
      authTokenLogin$ = this.actions$.pipe(
          ofType(AuthActions.DO_TOKEN_LOGIN),
          map((action: AuthActions.DoTokenLogin) => {
              // Grazie al map, il payload sarà wrappato in un nuovo Observable, così da poter far in seguito la concatenazione di altri operatori
              return action.payload;
          }),
          switchMap((payload: { identityToken: string }) => {
              if (payload.identityToken) {
                  this.tinyToken = payload.identityToken;
                  return this.authService.validateToken(payload.identityToken);
              } else {
                  throwError(new Error('TOKEN_NOT_FOUND'));
              }
          }),
          map((tokenObj: any) => {
              if (tokenObj.error) {
                  throw (new Error(tokenObj.error));
              } else if (tokenObj && tokenObj.response) {
                  // Salvo il tiny token. Salvo questo perché è quello che mi servirà nelle chiamate rest, e che utilizzerà quindi l'interceptor
                  // Se è valido salvo il tiny token
                  this.store.dispatch(new AuthActions.SetToken(this.tinyToken));
                  this.store.dispatch(new AuthActions.SetUserAuthenticated);
                  //return this.store.dispatch(new ProfileActions.SetLoggedSocialUser(tokenObj.response)); CORRECT?
              }
              throw (new Error(ErrorCodes.UNEXPECTED_ERROR));
          }),
          withLatestFrom(this.store.select(fromApp.getLoggedUser)),
          map(([action, loggedUser]) => {
              // Se ho settato l'utente loggato (e quindi decodificato il token) posso settare la lingua di sistema con quella scelta dall'utente
              let langToUse = this.langsService.getUserLang(loggedUser.user);
              // Prima di salvare la lingua dello store applicativo, la imposto anche per il componente che si occupa delle traduzioni
              this.langsService.useLanguage(langToUse);
              return new CoreActions.SetApplicationLang(langToUse);
          }),
          catchError((err, caught) => {
              if (err && err.message) {
                  this.toastr.error(this.translate.instant('errors.' + err.message));
              }
              return caught;
          })
      )
}

