import { RequiredAuth } from 'atfcore-commonclasses';

/*
 * Modello che dinisce una classe con i dati comuni e utili per l'applicazione (tipo il context)
*/

export class GlobalApplicationData {
  // Prefisso applicativo (context) e le altre varibili del server
  public applicationContext: string;
  public isAliveAddress: string;
  public isMaintenanceAddress: string;
  public eTicketResourcePath: string;
  public orginalRequestedURL: string;
  // Indica se mostrare un alert di "sessione/token scaduta/o"
  public showSessionExpiredAlert: boolean;
  // Indica alla pagina di autorizzazione che deve effettuare un reload completo, in modo da pulire tutti gli status
  public forceFullPageRefresh: boolean;
  // Ruoli disponibili
  // Questo array di oggetti role/label sarà popolato durante la chiamata che recupera i dati dell'utente loggato
  public availableUserGroups: string[];
  // Azioni a cui un utente è abilitato
  public actions: string[];
  // Indica se deve essere forzata l'apertura della dialog delle preferenze utente
  public forceShowUserPrefDialog: boolean;
  // Tipo di device
  public deviceType: string;
  // Agent dell'utente
  public userAgent: string;

  constructor(applicationContext: string, isAliveAddress: string, isMaintenanceAddress: string, eTicketResourcePath: string, orginalRequestedURL: string, showSessionExpiredAlert: boolean, forceFullPageRefresh: boolean, availableUserGroups: string[], actions: string[], forceShowUserPrefDialog: boolean, deviceType: string, userAgent: string) {
    this.applicationContext = applicationContext;
    this.isAliveAddress = isAliveAddress;
    this.isMaintenanceAddress = isMaintenanceAddress;
    this.eTicketResourcePath = eTicketResourcePath;
    this.orginalRequestedURL = orginalRequestedURL;
    this.showSessionExpiredAlert = showSessionExpiredAlert;
    this.forceFullPageRefresh = forceFullPageRefresh;
    this.availableUserGroups = availableUserGroups;
    this.actions = actions;
    this.forceShowUserPrefDialog = forceShowUserPrefDialog;
    this.deviceType = deviceType;
    this.userAgent = userAgent;
  }
}

const genertelTenants = ["GENERTEL_GRP", "GENERTEL_AMM_GRP", "GENERTELLIFE_AMM_GRP", "GENERTEL_CC_CLAIMS_GRP", "GENERTEL_CC_BACKOFFICE_GRP", "GENERTEL_CC_SALES_GRP", "GENERTELLIFE_CC_GRP"];

export function isGenertelTenant(tenant) {
  if (tenant) {
    for (let adminTenantsCounter = 0, adminTenantsLength = genertelTenants.length; adminTenantsCounter < adminTenantsLength; adminTenantsCounter++) {
      let currentAdminTenant = genertelTenants[adminTenantsCounter];
      if (tenant === currentAdminTenant) {
        return true;
      }
    }
  }
  return false;
}

export function authControl(auths: string[]) {
  let authObject: any = {};
  if (auths && auths.filter((y: any) => {
    return y == RequiredAuth.TALENT_FARM_ADMIN;
  }).length
  ) {
    authObject.isAdmin = true;
  }
  if (auths && auths.filter((y: any) => {
    return y == RequiredAuth.TALENT_FARM_ADMIN_EBRM;
  }).length
  ) {
    authObject.isAdminEbrm = true;
  }
  if (auths && auths.filter((y: any) => {
    return y == RequiredAuth.TALENT_FARM_ADMIN_ATS;
  }).length
  ) {
    authObject.isAdminAts = true;
  }
  /*if (auths && auths.filter((y: any) => {
    return y == RequiredAuth.PERFORMANCEMANAGEMENT_HRBP;
  }).length
  ) {
    authObject.isHRBP = true;
  }*/
  return authObject;
}