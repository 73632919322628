<div class="input-wrapper" [ngClass]="grayBackground ? 'gray-background' : ''">
  <label *ngIf="title" class="control-label" for="searchinput">{{ title }}</label>
  <div [ngClass]="inputContainerClass">
    <p tabindex="0" *ngIf="textAreaAdvice" class="full-width margin-bottom8 advice">{{ textAreaAdvice }}</p>
    <textarea [(ngModel)]="bindValue" [name]="name" [placeholder]="placeholder" attr.aria-label="{{ attrAriaLabel }}"
      [disabled]="disabled" (ngModelChange)="emitOnModelChanged($event)" #textareaEl="ngModel"></textarea>
    <p tabindex="0" *ngIf="subtitle" class="help-block">{{ subtitle }}</p>
  </div>
  <div fxLayout="row" *ngIf="textareaEl.invalid && (textareaEl.dirty || textareaEl.touched)"
    class="input-error-message">
    <div *ngIf="textareaEl.errors.required">
      {{ errorRequiredText }}
    </div>
    <div *ngIf="textareaEl.errors.minlength">
      {{ errorMinLengthText }}
    </div>
    <div *ngIf="textareaEl.errors.maxlength">
      {{ errorMaxLengthText }}
    </div>
    <div *ngIf="textareaEl.errors.forbiddenName">
      {{ errorForbiddenText }}
    </div>
  </div>
</div>