import { Action } from '@ngrx/store';
import { GlobalApplicationData } from 'src/app/shared/models/global-application-data.model';

// Setta il prefisso applicativo (context) e le altre varibili del server
export const SET_CORE_APPLICATION_DATA = 'SET_CORE_APPLICATION_DATA';

// Pulisce il prefisso applicativo (context) e le altre varibili del server
export const REMOVE_CORE_APPLICATION_DATA = 'REMOVE_CORE_APPLICATION_DATA';

// Recupera le lingue disponibili a sistema
export const GET_AVAILABLE_LANGS = 'GET_AVAILABLE_LANGS';

// Setta come completato il caricamento delle lingue disponibili
export const GET_AVAILABLE_LANGS_FINISHED = 'GET_AVAILABLE_LANGS_FINISHED';

// Setta la lingua di default
export const SET_DEFAULT_LANG = 'SET_DEFAULT_LANG';

// Salva l'url richiesto prima di eseguire il redirect alla pagina di login
export const SAVE_REDIRECT_URL = 'SAVE_REDIRECT_URL';

// Cancella l'url di redirect dopo il login
export const REMOVE_REDIRECT_URL = 'REMOVE_REDIRECT_URL';

// Apre la sidebar di sinistra col menu principale
export const OPEN_MAIN_MENU_SIDEBAR = 'OPEN_MAIN_MENU_SIDEBAR';

// Chiude la sidebar di sinistra col menu principale
export const CLOSE_MAIN_MENU_SIDEBAR = 'CLOSE_MAIN_MENU_SIDEBAR';

// Apre la sidebar di destra col menu utente
export const OPEN_PROFILE_MENU_SIDEBAR = 'OPEN_PROFILE_MENU_SIDEBAR';

// Chiude la sidebar di destra col menu utente
export const CLOSE_PROFILE_MENU_SIDEBAR = 'CLOSE_PROFILE_MENU_SIDEBAR';

// Inizia il polling per il renew del token
export const START_RENEW_TOKEN_POLLING = 'START_RENEW_TOKEN_POLLING';

// Setta la lingua dell'applicazione
export const SET_APPLICATION_LANG = 'SET_APPLICATION_LANG';

// Cancella la lingua dell'applicazione
export const REMOVE_APPLICATION_LANG = 'REMOVE_APPLICATION_LANG';

// Setta il contatore delle notifiche
export const SET_NOTIFICATIONS_COUNTER = 'SET_NOTIFICATIONS_COUNTER';

// Recupera le info delle fasi
export const GET_PHASES_INFO = 'GET_PHASES_INFO';

// Setta le info delle fasi nello store
export const SET_PHASES = 'SET_PHASES';

// Recupera l'anno in corso
export const GET_CURRENT_YEAR = 'GET_CURRENT_YEAR';

// Setta l'anno in corso
export const SET_CURRENT_YEAR = 'SET_CURRENT_YEAR';

// Setta se vedere il banner impersonifica
export const SET_IMPERSONIFICATION_BANNER = 'SET_IMPERSONIFICATION_BANNER';

// Starta il debounce per il conteggio delle competenze da confermare
export const START_COUNT_ASKED_COMPETENCES = 'START_COUNT_ASKED_COMPETENCES';

// Setta il numero delle competenze da confermare
export const SET_ASKED_COMPETENCES = 'SET_ASKED_COMPETENCES';

export class SetCoreApplicationData implements Action {
    readonly type = SET_CORE_APPLICATION_DATA;

    // Payload dell'azione
    constructor(public payload: GlobalApplicationData) { }
}

export class SaveRedirectUrl implements Action {
    readonly type = SAVE_REDIRECT_URL;

    // Payload dell'azione, che è l'url richiesto
    constructor(public payload: string) { }
}

export class SetApplicationLang implements Action {
    readonly type = SET_APPLICATION_LANG;

    // Payload dell'azione, che contiene l'abbreviazione della lingua scelta (es 'it')
    constructor(public payload: string) { }
}

export class SetDefaultLang implements Action {
    readonly type = SET_DEFAULT_LANG;

    constructor(public payload: string) { }
}

export class OpenMainMenuSidebar implements Action {
    readonly type = OPEN_MAIN_MENU_SIDEBAR;
}

export class CloseMainMenuSidebar implements Action {
    readonly type = CLOSE_MAIN_MENU_SIDEBAR;
}

export class OpenProfileMenuSidebar implements Action {
    readonly type = OPEN_PROFILE_MENU_SIDEBAR;
}

export class CloseProfileMenuSidebar implements Action {
    readonly type = CLOSE_PROFILE_MENU_SIDEBAR;
}

export class GetAvailableLangs implements Action {
    readonly type = GET_AVAILABLE_LANGS;
}

export class RemoveApplicationLang implements Action {
    readonly type = REMOVE_APPLICATION_LANG;
}

export class GetAvailableLangsFinished implements Action {
    readonly type = GET_AVAILABLE_LANGS_FINISHED;
}

export class StartRenewTokenPolling implements Action {
    readonly type = START_RENEW_TOKEN_POLLING;
    constructor(public payload?: { redirectUrl?: string, forceRefreshUser?: boolean }) { }
}

export class RemoveCoreApplicationData implements Action {
    readonly type = REMOVE_CORE_APPLICATION_DATA;
}

export class RemoveRedirectUrl implements Action {
    readonly type = REMOVE_REDIRECT_URL;
}

export class SetNotificationsCounter implements Action {
    readonly type = SET_NOTIFICATIONS_COUNTER;

    constructor(public payload: number) { }
}

export class SetPhases implements Action {
    readonly type = SET_PHASES;

    constructor(public payload: any) { }
}

export class GetPhases implements Action {
    readonly type = GET_PHASES_INFO;
    constructor(public payload) { }
}

export class GetCurrentYear implements Action {
    readonly type = GET_CURRENT_YEAR;
    constructor(public payload) {}
}

export class SetCurrentYear implements Action {
    readonly type = SET_CURRENT_YEAR;
    constructor(public payload: any) { }
}

export class SetImpersonificationBanner implements Action {
    readonly type = SET_IMPERSONIFICATION_BANNER;

    // Payload dell'azione, che è un boolean
    constructor(public payload: boolean) { }
}

export class StartCountsAskedCompetences implements Action {
    readonly type = START_COUNT_ASKED_COMPETENCES;

    constructor(public payload: any) { }
}

export class SetAskedCompetences implements Action {
    readonly type = SET_ASKED_COMPETENCES;

    constructor(public payload: any) { }
}

// Definisco un tipo custom per eseguire il bundle delle azioni in un singolo export
export type CoreActions = SetCoreApplicationData
    | GetAvailableLangs
    | GetAvailableLangsFinished
    | RemoveCoreApplicationData
    | SaveRedirectUrl
    | OpenMainMenuSidebar
    | SetDefaultLang
    | CloseMainMenuSidebar
    | OpenProfileMenuSidebar
    | CloseProfileMenuSidebar
    | StartRenewTokenPolling
    | SetApplicationLang
    | RemoveApplicationLang
    | RemoveRedirectUrl
    | SetNotificationsCounter
    | SetPhases
    | GetPhases
    | GetCurrentYear
    | SetCurrentYear
    | SetImpersonificationBanner
    | StartCountsAskedCompetences
    | SetAskedCompetences;