import { Router } from "@angular/router";
import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subscription, combineLatest } from "rxjs";
import { Store } from "@ngrx/store";
import { SectionTab } from "../interfaces/tab.interface";
import * as fromApp from '../../ngrx/app.reducers';
import * as CoreActions from '../../core/ngrx/core.actions';
import { AuthService } from 'src/app/auth/services/auth.service';
import { JwtPayload } from 'atfcore-commonclasses';
import { onceIfNotEmpty } from 'src/app/ngrx/util';
import { authControl } from '../models/global-application-data.model';

/*
*    Servizio che gestisce i vari redirect
*/
@Injectable()
export class RedirectService implements OnDestroy {
    isMainMenuSidebarOpened: boolean;
    isProfileMenuSidebarOpened: boolean;
    sidenavObservables$: Subscription;
    loggedUser: JwtPayload;
    authObject;
    constructor(private store: Store<fromApp.AppState>,
        private authService: AuthService,
        private router: Router) {
        // Recupero dallo Store applicativo le variabili che mi indicano se le sidenav sono aperte
        let isMainMenuSidebarOpened$: Observable<boolean> = this.store.select(fromApp.isMainMenuSidebarOpened);
        // Recupero dallo Store applicativo le variabili che mi indicano se le sidenav sono aperte
        let isProfileMenuSidebarOpened$: Observable<boolean> = this.store.select(fromApp.isProfileMenuSidebarOpened);
        const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser).pipe(onceIfNotEmpty());
        const combinedSelectes$ = combineLatest(isMainMenuSidebarOpened$, isProfileMenuSidebarOpened$, loggedUser$);

        this.sidenavObservables$ = combinedSelectes$.subscribe(
            ([isMainMenuSidebarOpened, isProfileMenuSidebarOpened, loggedUser]) => {
                this.isMainMenuSidebarOpened = isMainMenuSidebarOpened;
                this.isProfileMenuSidebarOpened = isProfileMenuSidebarOpened;
                this.loggedUser = loggedUser;
                if (this.loggedUser && this.loggedUser.auths) {
                    this.authObject = authControl(this.loggedUser && this.loggedUser.auths);
                }
            });
    }

    // Quando il componente è distrutto, eseguo anche l'unsubscribe
    ngOnDestroy() {
        if (this.sidenavObservables$) {
            this.sidenavObservables$.unsubscribe();
        }
    }

    goBack(route: any, secondPage?: boolean, isTouchpoint?: boolean) {
        if (isTouchpoint) {
            if (secondPage) {
                this.router.navigate(['../'], { relativeTo: route });
            } else {
                this.router.navigate(['../../home'], { relativeTo: route });
            }
        } else {
            this.router.navigate(['../home'], { relativeTo: route });
        }
    }

    // Chiude la sidebar di sinistra contente il menu principale
    closeSidenavMainMenu() {
        this.store.dispatch(new CoreActions.CloseMainMenuSidebar());
    }

    // Chiude la sidebar di destra con il profilo utente
    closeSidenavProfileMenu() {
        this.store.dispatch(new CoreActions.CloseProfileMenuSidebar());
    }



    // START ADMIN REDIRECT *********

    // Porta all'home page
    goToHome() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        if (this.authObject && (this.authObject.isAdmin || this.authObject.isAdminEbrm)) {
            this.router.navigate(['admin/home']);
            return;
        } else {
            //this.router.navigate(['admin/home']);
        }
    }

    goToManageWelcomePage() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        if (this.authObject && this.authObject.isAdmin) {
            this.router.navigate(['admin/manageWelcomePage']);
            return;
        }
    }

    goToManageHomePage() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        if (this.authObject && this.authObject.isAdmin) {
            this.router.navigate(['admin/manageHomepage']);
            return;
        }
    }

    goToEditContent(type, itemId) {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        this.router.navigate(["admin/editcontent/" + type + "/" + itemId]);
    }

    goToListBlogs() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        if (this.authObject && this.authObject.isAdmin) {
            this.router.navigate(["admin/listblogs"]);
            return;
        }
    }

    goToListPrems() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        if (this.authObject && this.authObject.isAdmin) {
            this.router.navigate(["admin/listprems"]);
            return;
        }
    }

    goToEditBlog() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        this.router.navigate(["admin/editcontent/BLOGS"]);
    }

    goToManageGallery(itemId, title) {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        if (this.authObject && this.authObject.isAdmin) {
            this.router.navigate(["admin/manageGallery" + "/" + itemId + "/" + title]);
            return;
        }
    }

    goToManageDocuments(itemId, title) {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        if (this.authObject && this.authObject.isAdmin) {
            this.router.navigate(["admin/manageDocuments" + "/" + itemId + "/" + title]);
            return;
        }
    }

    goToEditDocument(eventId, eventTitle, docId?) {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        if (this.authObject && this.authObject.isAdmin) {
            if (docId) this.router.navigate(["admin/editDocument" + "/" + eventId + "/" + eventTitle + "/" + docId]);
            else this.router.navigate(["admin/editDocument" + "/" + eventId + "/" + eventTitle]);
            return;
        }
    }

    goToManageFAQ() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        this.router.navigate(["admin/manageFAQ"]);
    }

    goToManageFaqAts() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        if (this.authObject && this.authObject.isAdminEbrm) {
            this.router.navigate(["admin/manageFaqATS"]);
        }
    }

    goToCreateUpdateFAQ(faqId?: string) {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        if (faqId) {
            this.router.navigate(["admin/createUpdateFaq/" + "/" + faqId]);
        } else {
            this.router.navigate(["admin/createUpdateFaq"]);
        }
    }

    goToCreateUpdateFAQAts(faqId?: string) {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        if (faqId) {
            this.router.navigate(["admin/createUpdateFaqATS/" + "/" + faqId]);
        } else {
            this.router.navigate(["admin/createUpdateFaqATS"]);
        }
    }

    goToManageChallenges() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        this.router.navigate(["admin/manageChallenges"]);
    }

    goToEditChallenge(challengeType, challengeId?) {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        if (challengeType && challengeId) {
            this.router.navigate(["admin/editChallenge/" + challengeType + '/' + challengeId]);
        } else if (challengeType) {
            this.router.navigate(["admin/editChallenge/" + challengeType]);
        } else {
            this.router.navigate(["admin/editChallenge"]);
        }
    }

    goToEditEasyRecrueChallenge(challengeId?) {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        if (challengeId) {
            this.router.navigate(["admin/editEasyRecrueChallenge/" + challengeId]);
        } else {
            this.router.navigate(["admin/editEasyRecrueChallenge"]);
        }
    }

    goToEditPrem(premId?) {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        if (premId) {
            this.router.navigate(["admin/editPrem/" + premId]);
        } else {
            this.router.navigate(["admin/editPrem"]);
        }
    }

    goToManageRanking(challengeId?) {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        this.router.navigate(["admin/manageRanking/" + challengeId]);
    }

    goToCreateTeam(challengeId) {
        this.router.navigate(["admin/createTeam/" + challengeId]);
    }

    goToManageTeam(challengeId) {
        this.router.navigate(["admin/manageTeam/" + challengeId]);
    }

    goToFolderDetail(challengeId, folderId) {
        this.router.navigate(["admin/folderDetail/" + challengeId + "/folder/" + folderId]);
    }

    goToManageTeamRanking(challengeId) {
        this.router.navigate(["admin/manageTeamRanking/" + challengeId]);
    }

    goToTeamDetail(teamId, challengeId) {
        this.router.navigate(["admin/teamDetail/" + challengeId + '/team/' + teamId]);
    }

    goToTeamManageDocuments(challengeId, teamId) {
        this.router.navigate(["admin/manageTeamDocuments/" + challengeId + "/team/" + teamId]);
    }

    goToManageDocumentsEdit(challengeId, tagId) {
        this.router.navigate(["admin/manageDocumentsEdit/" + challengeId + '/folder/' + tagId]);
    }

    goToDownloadReport() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        this.router.navigate(["admin/downloadReport"]);
    }

    goToManageValue() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        this.router.navigate(["admin/manageValue"]);
    }

    goToManageHomepage() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        this.router.navigate(["admin/manageHomepage"]);
    }

    goToHomepageHero() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        this.router.navigate(["admin/homepageHero"]);
    }

    goToListManageCoursesAndEvents() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        this.router.navigate(["admin/manageCoursesAndEvents"]);
    }

    goToManageCategories(category) {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        this.router.navigate(["admin/manageCategory/" + category]);
    }

    goToEditCategory(itemId) {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        this.router.navigate(["admin/editCategory/" + itemId]);
    }

    goToListCategories() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        this.router.navigate(["admin/listCategories"]);
    }

    goToHomepageVideoWelcome() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        this.router.navigate(["admin/homepageVideoWelcome"]);
    }

    goToManagePreviewPosition() {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        this.router.navigate(["admin/managePreviewPosition"]);
    }

    goToEditArea(idArea) {
        if (this.isMainMenuSidebarOpened) {
            this.closeSidenavMainMenu();
        }
        if (this.isProfileMenuSidebarOpened) {
            this.closeSidenavProfileMenu();
        }
        this.router.navigate(["admin/editArea/" + idArea]);
    }


    /*
    goToCreateNewContent(contentType: string) {
        this.router.navigate(["admin/newcontent/" + contentType]);
    }

    goToListContent() {
        this.router.navigate(["admin/listcontent"]);
    }
    */

    //END ADMIN REDIRECT ************




    // START FOOTER REDIRECT  **********

    goToSectionTab(tab: SectionTab): void {
        this.router.navigate([tab.link]);
    }

    // END FOOTER REDIRECT  **********

    goToPreviewPrem(prizeId: string) {
        this.router.navigate(["admin/previewPrize/" + prizeId]);
    }

}
