import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsService } from '../../shared/services/analytics.service';
@Component({
    selector: 'error-page',
    templateUrl: './error-page.component.html'
})
export class ErrorPageComponent implements OnInit {
    constructor(private analyticsService: AnalyticsService,
        private router: Router) { }
    ngOnInit() {
        // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
        this.analyticsService.sendVirtualPageViewEvent(this.router.url, "Errore generico");
    }
}
