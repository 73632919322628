<div class="card-container">
  <div class="image-container">
    <img [src]="image ? image : 'assets/img/placeholder-image.svg'">
  </div>
  <div class="info-container">
    <div class="header-info-container">
      <div class="first-row">
        <div fxLayout="row" class="full-width" fxLayoutAlign="space-between center">
          <p class="type-text" translate="generic.LIVE_EVENT"></p>
          <p class="link-text" (click)="emitOnModelChanged()" translate="generic.SELECT"></p>
        </div>
      </div>
      <div class="divisor-line"></div>
      <div class="second-row">
        <p>{{ liveTitle }}</p>
      </div>
    </div>
    <div class="date-container">
    <p class="date-text">{{ date }}</p>
    <div (click)="showAllInfo()" class="major-info">
      <p translate="generic.MORE_DETAILS"></p>
      <svg-icon [src]="showAllData ? 'assets/img/icons/arrow-top.svg' : 'assets/img/icons/arrow-bottom.svg'"></svg-icon>
    </div>
  </div>
    <div *ngIf="showAllData" class="description-container">
      <p>{{ liveDescription }}</p>
    </div>
    <div *ngIf="categories && categories.length > 0" class="type-container">
      <div *ngFor="let cat of categories" class="container-box categories-tag-text" [title]="cat">
        <p>{{ cat }}</p>
      </div>
    </div>
  </div>
</div>
