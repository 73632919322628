import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'circular-indicator',
  templateUrl: './circular-indicator.component.html',
  styleUrls: ['./circular-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CircularIndicatorComponent {
  @Input() indicatorData;
  @Input() desktopMode: boolean;
  @Input() indicatorPercentage: number;

  constructor() { }
}
