/*
*  Reducer che gestiscono e modificano lo State applicativo riguardante i processi
*/

import * as fromApp from '../../ngrx/app.reducers';
import * as AdminProcessesActions from "./process.actions";

// I admin sono una feature dell'applicazione, infatti è un modulo caricato in maniera lazy (e quindi anche lo Store).
// Creo un'interfaccia, FeatureState, in cui mappo i processi dello state Processstate
// Estendo fromApp.AppState così per ereditare tutte le proprietà dello Store principale
export interface FeatureState extends fromApp.AppState {
    admin: ProcessState
}

// Interfaccia dello Store che torna la lista dei processi admin recuperata
export interface ProcessState {
    processes: any[];
    count: number;
    fromRecord: number;
    numRecords: number;
    page: number;
    isFetchingProcesses: boolean;
    users: any[];
    totalUsers: any[];
    countUsers: number;
    fromRecordUsers: number;
    numRecordsUsers: number;
    pageUsers: number;
    isFetchingUsers: boolean;
    isFetchingTotalUsers: boolean;
    setupProcesses: any[];
    setupCount: number;
    setupFromRecord: number;
    setupNumRecords: number;
    setupPage: number;
    setupIsFetchingProcesses: boolean;
    mailReminds: any[],
    mailRemindsCount: number,
    mailRemindsFromRecord: number,
    mailRemindsNumRecords: number,
    mailRemindsPage: number,
    isFetchingMailReminds: boolean
}

// Store iniziale
const initialState: ProcessState = {
    processes: [], // lista dei processi
    count: 0, // contatore totale dei processi
    fromRecord: 0, // numero da cui recuperare gli elementi
    numRecords: 5, // elementi da recuperare
    page: 1, // pagina della tabella visualizzata
    isFetchingProcesses: false, // verifica se sto eseguendo una chiamata per recuperare i processi
    users: [],
    totalUsers: [],
    countUsers: 0,
    fromRecordUsers: 0,
    numRecordsUsers: 10,
    pageUsers: 1,
    isFetchingUsers: false,
    isFetchingTotalUsers: false,
    setupProcesses: [],
    setupCount: 0,
    setupFromRecord: 0,
    setupNumRecords: 5,
    setupPage: 1,
    setupIsFetchingProcesses: false,
    mailReminds: [],
    mailRemindsCount: 0,
    mailRemindsFromRecord: 0,
    mailRemindsNumRecords: 5,
    mailRemindsPage: 1,
    isFetchingMailReminds: false
};

export function adminProcessesReducer(state = initialState, action: AdminProcessesActions.AdminProcessesActions) {
    switch (action.type) {
        case AdminProcessesActions.SET_PROCESSES_ADMIN:
            return {
                ...state,
                isFetchingProcesses: false,
                processes: [...action.payload] // sovrascrivo i processi
            };
        case AdminProcessesActions.SET_PAGINATION_ADMIN:
            return {
                ...state,
                fromRecord: action.payload.fromRecord,
                numRecords: action.payload.numRecords
            };
        case AdminProcessesActions.GET_PROCESSES_ADMIN:
            return {
                ...state,
                isFetchingProcesses: true
            };
        case AdminProcessesActions.SET_PAGE_ADMIN:
            return {
                ...state,
                page: action.payload
            };
        case AdminProcessesActions.SET_PROCESSES_COUNT_ADMIN:
            return {
                ...state,
                count: action.payload,
            };

        case AdminProcessesActions.SET_USERS_ADMIN:
            return {
                ...state,
                isFetchingUsers: false,
                users: [...action.payload] // sovrascrivo gli utenti
            };
        case AdminProcessesActions.SET_TOTAL_USERS_ADMIN:
            return {
                ...state,
                isFetchingTotalUsers: false,
                totalUsers: [...action.payload] // sovrascrivo gli utenti
            };
        case AdminProcessesActions.SET_PAGINATION_USERS_ADMIN:
            return {
                ...state,
                fromRecordUsers: action.payload.fromRecord,
                numRecordsUsers: action.payload.numRecords
            };
        case AdminProcessesActions.GET_USERS_ADMIN:
            return {
                ...state,
                isFetchingUsers: true
            };
        case AdminProcessesActions.GET_TOTAL_USERS_ADMIN:
            return {
                ...state,
                isFetchingTotalUsers: true
            };
        case AdminProcessesActions.SET_PAGE_USERS_ADMIN:
            return {
                ...state,
                pageUsers: action.payload
            };
        case AdminProcessesActions.SET_USERS_COUNT_ADMIN:
            return {
                ...state,
                countUsers: action.payload,
            };
        case AdminProcessesActions.SET_SETUP_PROCESSES_ADMIN:
            return {
                ...state,
                setupIsFetchingProcesses: false,
                setupProcesses: [...action.payload] // sovrascrivo i processi
            };
        case AdminProcessesActions.SET_SETUP_PAGINATION_ADMIN:
            return {
                ...state,
                setupFromRecord: action.payload.fromRecord,
                setupNumRecords: action.payload.numRecords
            };
        case AdminProcessesActions.GET_SETUP_PROCESSES_ADMIN:
            return {
                ...state,
                setupIsFetchingProcesses: true
            };
        case AdminProcessesActions.SET_SETUP_PAGE_ADMIN:
            return {
                ...state,
                setupPage: action.payload
            };
        case AdminProcessesActions.SET_SETUP_PROCESSES_COUNT_ADMIN:
            return {
                ...state,
                setupCount: action.payload,
            };

        case AdminProcessesActions.SET_MAIL_REMINDS_ADMIN:
            return {
                ...state,
                isFetchingMailReminds: false,
                mailReminds: [...action.payload] // sovrascrivo i mail reminds
            };
        case AdminProcessesActions.SET_MAIL_REMINDS_PAGINATION_ADMIN:
            return {
                ...state,
                mailRemindsFromRecord: action.payload.fromRecord,
                mailRemindsNumRecords: action.payload.numRecords
            };
        case AdminProcessesActions.GET_MAIL_REMINDS_ADMIN:
            return {
                ...state,
                isFetchingMailReminds: true
            };
        case AdminProcessesActions.SET_MAIL_REMINDS_PAGE_ADMIN:
            return {
                ...state,
                mailRemindsPage: action.payload
            };
        case AdminProcessesActions.SET_MAIL_REMINDS_COUNT_ADMIN:
            return {
                ...state,
                mailRemindsCount: action.payload,
            };
        default:
            return state;
    }
}

// Export delle funzioni che tornano una parte dello Store applicativo
export const getProcesses = (state: ProcessState) => (state && state.processes) || [];
export const getProcessesCount = (state: ProcessState) => (state && state.count) || 0;
export const getProcessesFromRecord = (state: ProcessState) => (state && state.fromRecord) || 0;
export const getProcessesNumRecords = (state: ProcessState) => (state && state.numRecords) || 15;
export const getProcessesPage = (state: ProcessState) => (state && state.page) || 0;
export const isFetchingProcesses = (state: ProcessState) => (state && state.isFetchingProcesses);

export const getUsers = (state: ProcessState) => (state && state.users) || [];
export const getTotalUsers = (state: ProcessState) => (state && state.totalUsers) || [];
export const getUsersCount = (state: ProcessState) => (state && state.countUsers) || 0;
export const getUsersFromRecord = (state: ProcessState) => (state && state.fromRecordUsers) || 0;
export const getUsersNumRecords = (state: ProcessState) => (state && state.numRecordsUsers) || 10;
export const getUsersPage = (state: ProcessState) => (state && state.pageUsers) || 0;
export const isFetchingUsers = (state: ProcessState) => (state && state.isFetchingUsers);
export const isFetchingTotalUsers = (state: ProcessState) => (state && state.isFetchingTotalUsers);


export const getSetupProcesses = (state: ProcessState) => (state && state.setupProcesses) || [];
export const getSetupProcessesCount = (state: ProcessState) => (state && state.setupCount) || 0;
export const getSetupProcessesFromRecord = (state: ProcessState) => (state && state.setupFromRecord) || 0;
export const getSetupProcessesNumRecords = (state: ProcessState) => (state && state.setupNumRecords) || 5;
export const getSetupProcessesPage = (state: ProcessState) => (state && state.setupPage) || 0;
export const isFetchingSetupProcesses = (state: ProcessState) => (state && state.setupIsFetchingProcesses);


export const getMailReminds = (state: ProcessState) => (state && state.mailReminds) || [];
export const getMailRemindsCount = (state: ProcessState) => (state && state.mailRemindsCount) || 0;
export const getMailRemindsFromRecord = (state: ProcessState) => (state && state.mailRemindsFromRecord) || 0;
export const getMailRemindsNumRecords = (state: ProcessState) => (state && state.mailRemindsNumRecords) || 5;
export const getMailRemindsPage = (state: ProcessState) => (state && state.mailRemindsPage) || 0;
export const isFetchingMailReminds = (state: ProcessState) => (state && state.isFetchingMailReminds);
