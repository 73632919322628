import { Component, OnInit } from '@angular/core';
import { BaseSubscriberComponent } from '../../shared/components/base-subscriber.component';
import { RedirectService } from '../../shared/services/redirect.service';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import * as fromApp from '../../ngrx/app.reducers';
import { authControl } from 'src/app/shared/models/global-application-data.model';
import { Router } from '@angular/router';
import * as CoreActions from "../../core/ngrx/core.actions";

@Component({
  selector: 'app-home-redirect-token',
  templateUrl: './home-redirect-token.component.html',
  styleUrls: ['./home-redirect-token.component.scss']
})
export class HomeRedirectTokenComponent extends BaseSubscriberComponent implements OnInit {
  authObject;

  constructor(
    public redirectService: RedirectService,
    public translate: TranslateService,
    private router: Router,
    private store: Store<fromApp.AppState>) {
    super();
  }

  ngOnInit() {
    this.store.dispatch(new CoreActions.SetImpersonificationBanner(true));

    this.store.select(fromApp.getLoggedUser).subscribe(
      (loggedUser) => {
        if (loggedUser) {
          this.authObject = authControl(loggedUser && loggedUser.auths);
          if (this.authObject.isHRBP || this.authObject.isAdmin) {
            this.router.navigate(["homeManage"]);
          } else if (this.authObject.isManager) {
            this.router.navigate(["/users/home"]);
          } else {
            this.router.navigate(["contributor"]);
          }
        }
      })
  }

  ngOnDestroy() {

  }

}