import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsService } from '../../shared/services/analytics.service';

@Component({
    selector: 'app-compatibility-error',
    templateUrl: './compatibility-error.component.html',
    styleUrls: ['./compatibility-error.component.scss']
})
export class CompatibilityErrorComponent implements OnInit {

    constructor(private analyticsService: AnalyticsService,
        private router: Router) { }

    ngOnInit() {
        // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
        this.analyticsService.sendVirtualPageViewEvent(this.router.url, "Errore compatibilità");
    }

}
