<div [ngClass]="inputWrapperClass" class="input-wrapper">
  <label *ngIf="formTitle" class="control-label">{{ formTitle }}</label>
  <div>
    <!-- [ngClass]="containerClass ? containerClass : 'small-dimension'">-->
    <ng-select [virtualScroll]="virtualScroll" labelForId="{{labelForId ? labelForId : 'filter-generic-id'}}"
      [bindValue]="bindValue" [bindLabel]="bindLabel" [items]="items" placeholder="{{ placeholderLabel }}"
      [multiple]="multiple" (add)="emitAddItem($event)" [disabled]="disabled" [closeOnSelect]="closeOnSelect"
      attr.aria-label="{{'generic.SELECT' | translate}}" (remove)="emitRemoveItem($event)"
      (change)="emitOnSelectionChanged($event)" [(ngModel)]="bindModel" (ngModelChange)="emiOnModelChanged($event)"
      [dropdownPosition]="dropdownPosition" (scrollToEnd)="emitOnScrollToEnd($event)" (close)="emitOnClose()"
      [addTag]="addTag" [clearOnBackspace]="clearOnBackspace" [searchable]="searchable" [hideSelected]="hideSelected"
      [typeahead]="typeahead" [loading]="loading"
      [ngClass]="{'flex-1-ie': ieFlexProblem, 'on-comparison': isOnComparison}" [compareWith]="compareWith"
      [markFirst]="markFirst" #alloySelect>

      <!-- Elementi selezionati -->
      <ng-template class="alloy-select" ng-label-tmp let-item="item" let-clear="clear">
        <div *ngIf="!item.isCheckBox" fxLayout="row" fxLayoutAlign="start center">
          <p class="margin0 option-class" translate="{{'generic.SELECT' | translate}}"></p>
        </div>
      </ng-template>

      <!-- Template per l'inizio della ricerca -->
      <ng-template class="alloy-select" ng-typetosearch-tmp>
        <div class="ng-option disabled">{{ startSearchLabel || 'Inizia una ricerca' }}</div>
      </ng-template>
      <!-- Template per i dati non trovati -->
      <ng-template class="alloy-select" ng-notfound-tmp let-searchTerm="filter">
        <div class="ng-option disabled">{{ noDataFoundLabel || 'Nessun dato trovato' }}</div>
      </ng-template>
      <!-- Template per il caricamento dei risultati -->
      <ng-template class="alloy-select" ng-loadingtext-tmp>
        <div class="ng-option disabled">{{ loadingData || 'Caricamento in corso' }}</div>
      </ng-template>
      <!-- Template per i singoli item nelle options -->
      <ng-template class="alloy-select" ng-option-tmp let-item="item" let-search="filter" let-item$="item$">
        <div>
          <div [ngClass]="{'on-comparison': isOnComparison}" fxLayout="row" class="option-container"
            (click)="emitOnOptionClicked(item)">
            <label class="container-checkbox" [ngClass]="item.isActive ? 'red-detail' : ''">
              <div class="margin-left7 height-100" fxLayoutAlign="center center" fxLayout="column">
                <p class="option-class">{{ item.name ? item.name : resolveItemTitle(item)}}</p>
              </div>
              <input [ngModel]="item.isActive" id="checkbox{{item.key}}" type="checkbox"
                attr.aria-label="{{ placeholderLabel ? placeholderLabel : 'filter-generic-aria-label' }}">
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
      </ng-template>
    </ng-select>

  </div>
</div>
