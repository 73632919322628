<div *ngFor="let badges of badgesList" class="margin-bottom20 section-container">
  <div fxLayout="column" class="accordion-container dimension-component dashboard">
    <div class="badges-container" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="column"
      fxLayoutAlign.gt-sm="start start">
      <div class="title-container" fxLayout="row" fxLayoutAlign="start center" fxLayout.gt-sm="row"
        fxLayoutAlign.gt-sm="start center">
        <h3 tabindex="0">{{badges.title}}</h3>
      </div>
      <!-- Badge -->
      <div *ngIf="badges && !!badges.indicators && !!badges.indicators.length" class="badge-content" fxLayout.xs="row" ngClass.xs="scrollable"
        fxLayoutAlign.xs="start center" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="32px">
        <div class="indicator-container" fxLayout="column" fxLayoutAlign="center center"
          *ngFor="let indicator of badges.indicators">
          <button attr.aria-label="{{'badge ' + indicator.status + ' ' + ('profile.badges.' + badges.key | translate)}}" (click)="emitOnBadgeClicked(badges, indicator)" *ngIf="badges.key != 'SURVEY' && !indicator.isMock">
          <svg-icon
            src="{{'assets/img/badges/' + indicator.status.toLowerCase() + '/' + badges.key.toLowerCase() + '.svg'}}">
          </svg-icon>
        </button>
        <button attr.aria-label="{{'badge ' + indicator.status + ' ' + ('profile.badges.' + badges.key | translate)}}" (click)="emitOnBadgeClicked(badges, indicator)" *ngIf="badges.key != 'SURVEY' && indicator.isMock">
          <svg-icon
            src="{{'assets/img/badges/' + indicator.status.toLowerCase() + '/' + badges.key.toLowerCase() + '-lock.svg'}}">
          </svg-icon>
        </button>
        <button attr.aria-label="{{'badge ' + indicator.status + ' ' + ('profile.badges.' + indicator.key | translate)}}" (click)="emitOnBadgeClicked(badges, indicator)" *ngIf="badges.key == 'SURVEY' && !indicator.isMock">
          <svg-icon
            src="{{'assets/img/badges/' + indicator.status.toLowerCase() + '/' + indicator.key.toLowerCase() + '.svg'}}">
          </svg-icon>
        </button>
        <button attr.aria-label="{{'badge ' + indicator.status + ' ' + ('profile.badges.' + indicator.key | translate)}}" (click)="emitOnBadgeClicked(badges, indicator)" *ngIf="badges.key == 'SURVEY' && indicator.isMock">
          <svg-icon
            src="{{'assets/img/badges/' + indicator.status.toLowerCase() + '/' + indicator.key.toLowerCase() + '-lock.svg'}}">
          </svg-icon>
        </button>
        </div>
      </div>
      <!-- No badge -->
      <!-- la condizione è strana perchè ci sono casi in cui gli indicators sono undefined o [] e in quei casi aveva comportamenti anomali -->
      <div *ngIf="!badges.indicators || !(!!badges.indicators.length)" class="badge-content" fxLayout.xs="row" ngClass.xs="scrollable"
        fxLayoutAlign.xs="start center" fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="32px">
        <div class="indicator-container no-badge-data" fxLayout="column" fxLayoutAlign="center center">
          <svg-icon src="assets/img/badges/no-badge.svg"></svg-icon>
          <p tabindex="0" translate="generic.NO_BADGE_FOR_SECTION"></p>
        </div>
      </div>
    </div>
  </div>
</div>