<div class="fixHeader">
  <div class="content-header position-relative admin-container-header">
    <div class="main-menu-header" aria-level="1" fxLayout="row" fxLayoutAlign="start center" role="heading">
      <!-- header sx (menu, logo)-->
      <div class="full-width" fxLayout="row" fxLayoutAlign="center center">
        <div fxFlex fxLayout="row" role="navigation">
          <!--<button class="btn-menu" (click)="toggleMainMenuSidebar()"
            attr.aria-label="{{ isMainMenuSidebarOpened ? ('header.CLOSE_MAIN_MENU' | translate ) : ('header.OPEN_MAIN_MENU' | translate ) }}">
            <svg-icon hide-delay="50" tooltip-class="custom-tooltip"
              [src]="isMainMenuSidebarOpened ? 'assets/img/close-menu.svg' : 'assets/img/main-menu.svg'"
              placement="bottom" id="mainMenuSidebarToolip" display-mobile="false" class="header-icon-hamburger-menu">
            </svg-icon>
          </button>-->
          <button (click)="goToHome()" class="logo-wrapper icon-style-header"
            attr.aria-label="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}">
            <img placement="bottom" hide-delay="50" tooltip-class="custom-tooltip"
              tooltip="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}"
              title="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}"
              alt="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}" class="logoRegular cursor-pointer"
              src="assets/img/icons/logo-header-generali.svg">
          </button>
        </div>
        <div fxFlex></div>
        <!--<button class="btn-menu" 
          attr.aria-label="{{ 'header.OPEN_MAIN_MENU' | translate }}">
          <svg-icon hide-delay="50" tooltip-class="custom-tooltip" src="../../../assets/img/main-menu.svg"
            placement="bottom" id="mainMenuSidebarToolip" display-mobile="false" class="header-icon-hamburger-menu">
          </svg-icon>
        </button>--><!--(click)="toggleMainMenuSidebar()"-->
      </div>

      <!-- header dx (avatar, notifiche) -->
      <!-- <div fxHide.xs fxHide.sm fxLayout="row" fxLayoutAlign="start center">
          <div class="header-linkApp-coursemanager">
            <div class="header-linkApp-coursemanager" fxLayout="row" fxLayoutAlign="start center">
              <button (click)="redirectService.goToNotifications()" class="cursor-pointer icon-style-header"
                placement="bottom" hide-delay="50" tooltip-class="custom-tooltip"
                tooltip="{{ 'header.NOTIFICATIONS' | translate }}" role="button"
                attr.aria-label="{{ 'header.NOTIFICATIONS' | translate }}" fxLayout="row" fxLayoutAlign="start center">
                <svg-icon class="header-icon-notify" src="assets/img/icons/notify_icon.svg"></svg-icon>
                <span class="badge-notify-coursemanager margin0">{{notificationsCounter || 0}}</span>
              </button>
              <div class="section-profile">
                <button layout="column" class="img-avatar-coursemanager-home-wrapper icon-style-header"
                  attr.aria-label="{{ 'header.PROFILE' | translate }}" role="button" fxLayout="row"
                  fxLayoutAlign="center center" (click)="goToProfile()" tooltip-class="custom-tooltip"
                  tooltip="{{ 'header.PROFILE' | translate }}" placement="bottom" hide-delay="50">
                  <img class="img-avatar-coursemanager-home" [src]="avatar" alt="avatar" title="avatar">
                </button>
              </div>
            </div>
          </div>
        </div> -->
    </div>
    <div class="back-button-container-wrapper" fxHide.xs *ngIf="isValidPage()">
      <div class="back-button-container" fxLayout="row" fxLayout="start center">
        <button class="back-button" tabindex="0" (click)="goBack()">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="9px">
            <svg-icon src="../../../../assets/img/icons/arrow-left.svg"></svg-icon>
            <p translate="generic.BACK"></p>
          </div>
        </button>
      </div>
    </div>
  </div>
</div>