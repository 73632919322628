import { Action } from "@ngrx/store";
import { UserAcknowledges } from 'atfcore-commonclasses';

export const DO_LOGIN = "DO_LOGIN";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const LOGOUT_FOR_IMPERSONIFICATE = "LOGOUT_FOR_IMPERSONIFICATE";
export const SET_USER_AUTHENTICATED = "SET_USER_AUTHENTICATED";
export const SET_TOKEN = "SET_TOKEN";
export const SET_USER_ACKNOWLEDGES = 'SET_USER_ACKNOWLEDGES';
export const GET_USER_ACKNOWLEDGES = 'GET_USER_ACKNOWLEDGES';
export const RETRIEVE_USER_ACKNOWLEDGES = 'RETRIEVE_USER_ACKNOWLEDGES';
export const UPDATE_USER_ACKNOWLEDGES = 'UPDATE_USER_ACKNOWLEDGES';
export const SESSION_LOGOUT = "SESSION_LOGOUT";
export const DO_TOKEN_LOGIN = 'DO_TOKEN_LOGIN';

export class DoLogin implements Action {
  readonly type = DO_LOGIN;

  // Payload dell'azione
  constructor(
    public payload: {
      email: string;
      password: string;
      isSso?: boolean;
    }
  ) { }
}
export class DoTokenLogin implements Action {
  readonly type = DO_TOKEN_LOGIN;

  // Payload dell'azione
  constructor(public payload: { identityToken: string }) { }
}

export class Login implements Action {
  readonly type = LOGIN;
}

export class SetUserAcknowledges implements Action {
  readonly type = SET_USER_ACKNOWLEDGES;

  constructor(public payload: UserAcknowledges) { }
}

export class UpdateUserAcknowledges implements Action {
  readonly type = UPDATE_USER_ACKNOWLEDGES;

  constructor(public payload: any) { }
}

export class GetUserAcknowledges implements Action {
  readonly type = GET_USER_ACKNOWLEDGES;

  constructor(public payload: UserAcknowledges) { }
}

export class RetrieveUserAcknowledges implements Action {
  readonly type = RETRIEVE_USER_ACKNOWLEDGES;

  constructor() { }
}

export class Logout implements Action {
  readonly type = LOGOUT;
}

export class SessionLogout implements Action {
  readonly type = SESSION_LOGOUT;
}

export class LogoutForImpersonificate implements Action {
  readonly type = LOGOUT_FOR_IMPERSONIFICATE;
}

export class SetUserAuthenticated implements Action {
  readonly type = SET_USER_AUTHENTICATED;
}

export class SetToken implements Action {
  readonly type = SET_TOKEN;

  constructor(public payload: string) { }
}

export type AuthActions =
  | Login
  | Logout
  | SessionLogout
  | SetToken
  | SetUserAuthenticated
  | SetUserAcknowledges
  | GetUserAcknowledges
  | RetrieveUserAcknowledges
  | UpdateUserAcknowledges
  | LogoutForImpersonificate
  | DoLogin;
