import { animate, style, transition, trigger } from '@angular/animations';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'expandable-list',
  templateUrl: './expandable-list.component.html',
  styleUrls: ['./expandable-list.component.scss'],
  animations: [
    trigger(
      'showIf', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms', style({ opacity: 1 }))
      ])
    ]
    )
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpandableListComponent {

  @Input() list: ExpandableItem[];
  @Output() editToFAQ = new EventEmitter();
  @Output() deleteToFAQ = new EventEmitter();
  @Output() moveUp = new EventEmitter();
  @Output() moveDown = new EventEmitter();

  _toTranslate: boolean;
  @Input() set toTranslate(value: boolean | string) {
    this._toTranslate = coerceBooleanProperty(value);
  };

  constructor() {
  }

  /**
   * Arrow action that open/close the list
   */
  arrowClick(index: number) {
    if (this.list[index].expanded) {
      this.list[index].expanded = false;
    } else {
      for (let i = 0; i < this.list.length; i++) {
        this.list[i].expanded = index === i;
      }
    }
  }

  editFAQ(faqId) {
    this.editToFAQ.emit(faqId);
  }

  deleteFAQ(faqId) {
    this.deleteToFAQ.emit(faqId);
  }

  moveUpClicked(index, first) {
    if(!first) this.moveUp.emit(index);
  }

  moveDownClicked(index, last) {
    if(!last) this.moveDown.emit(index);
  }

}


export interface ExpandableItem {
  id: string,
  title: string,
  description: string,
  expanded: boolean
}
