<div class="page-container" fxLayout="column" fxLayoutAlign="start center">
    <div class="banner-header" [ngClass]="imageHeightClass" fxLayout="row" fxLayoutAlign="start start">

        <!-- Immagine con layer blur -->
        <div class="img-banner-header" [ngClass]="{'is-page-badge': isBadgePage, 'is-my-exp': isMyExperienceIcon, 'is-home-my-exp': isHomeMyExperience, 'is-my-exp-experiences': isMyExpExperiences, 'is-my-exp-competences': isMyExpCompetences, 'is-my-exp-personas': isMyExpPersonas, 'is-my-console-exp': isMyExpConsole, 'is-personas-profile-list': isPersonasProfilesList }" style="background-image: url('{{imgSrc}}')" *ngIf="!isHome && !isProfilePage()">
        </div>
        <!-- Mask content -->
        <div class="banner-header-content-mask">
            <div *ngIf="showBackBtn" class="back-button-container-wrapper" fxHide fxShow.xs>
                <div class="back-button-container" fxLayout="row" fxLayout="start center">
                    <button class="back-button" tabindex="0" (click)="goBack()">
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                            <svg-icon src="../../../../assets/img/icons/arrow-left.svg"></svg-icon>
                            <p translate="generic.BACK"></p>
                        </div>
                    </button>
                </div>
            </div>

            <button *ngIf="!noVideoButton" (click)="onVideoClicked()" class="video-button-container" fxLayout="row"
                fxLayoutAlign="space-between center" [ngClass]="{'home-video-button': isHome}">
                <svg-icon src="../../../../assets/img/icons/play-icon.svg"></svg-icon>
                <span class="text-title">Video Tutorial</span>
            </button>

            <!-- Dati utente -->
            <!--div *ngIf="withUserData" class="user-data-container" fxLayout="row" fxLayoutAlign="start center">
                <img class="img-avatar-coursemanager-home2" [src]="userAvatar" alt="avatar" title="avatar">
                <div fxLayout="column" fxLayoutAlign="center start">
                    <span tabindex="0" class="user-text">{{'generic.HELLO' | translate}}</span>
                    <span class="bold-text user-text">{{userForename}}</span>
                </div>
            </div-->

            <!-- Titolo pagina -->
            <div *ngIf="!withUserData" [ngClass]="{'is-my-exp': isMyExperienceIcon}" class="banner-header-title" fxLayout="row" fxLayoutAlign="start center">
                <h2 tabindex="0" [innerHTML]="(title | translate) | safeHtml: 'html'"></h2>
            </div>

            <div class="user-avatar" *ngIf="isProfilePage()">
                <img [src]="userAvatar">
            </div>

        </div>
    </div>

    <div class="general-page-content full-width"
        [ngClass]="{'negative-margin-top': !notNegativeMarginTop, 'home-negative-margin-top': isHome, 'is-my-exp-experiences': isMyExpExperiences, 'is-my-exp-competences': isMyExpCompetences, 'is-my-exp-personas': isMyExpPersonas, 'is-page-badge': isBadgePage }">
        <div class="content">
            <ng-content></ng-content>
        </div>
    </div>
</div>