<div class="searchbar-container" [ngClass]="{'from-ninebox': fromNinebox, 'no-margin-top': noMarginTop }" fxLayout="row" fxLayoutAlign="start center">
    <form class="input-search-container" fxLayout="row" fxLayoutAlign="start center" (ngSubmit)="onSubmit()">
        <input type="text" name="search" [placeholder]="placeholder" attr.aria-label="{{placeholder | translate}}" (ngModelChange)="updateSearchedText($event)"
            [(ngModel)]="bindValue">
        <div class="icon-container" fxLayout="row" fxLayoutAlign="center center">
            <svg-icon *ngIf="!fromNinebox" [src]="'../../../../assets/img/icons/search-icon-filled.svg'"></svg-icon>
            <svg-icon *ngIf="fromNinebox" [src]="'../../../../assets/img/icons/search-icon-ninebox.svg'"></svg-icon>
        </div>
    </form>
    <button *ngIf="button" class="search-btn" tabindex="0" (click)="onSubmit()" [disabled]="disabled">
        <div fxLayout="row" fxLayoutAlign="center center">
            <p translate="generic.SEARCH"></p>
        </div>
    </button>
</div>