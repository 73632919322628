import { Component, Input, Output, EventEmitter, OnInit, ViewChild, HostListener } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.scss']
})
export class ItemListComponent {
  @Input() isInDashboard: boolean;
  @Input() iconLeft: string;
  @Input() iconRight: string;
  @Input() imageRight: string;
  @Input() imageSrc: string;
  @Input() statusToLeft: boolean;
  @Input() statusToRight: boolean;
  @Input() status: string;
  @Input() statusClass: string;
  @Input() title: string;
  @Input() mobileTitle: string;
  @Input() order: number;
  @Input() isOpen: boolean;
  @Input() isPastObjective: boolean;
  @Input() isObjective: boolean;
  @Input() isCheckbox: boolean;
  @Input() isChecked: boolean;
  @Input() rightText: string;
  @Input() useHighlighTextPipe?: boolean;
  @Input() searchedText?: string;
  @Input() contents: any;
  @Input() hasTooltip: boolean;
  @Input() weightEdit?: boolean;
  @Input() isRequired?: boolean;
  @Input() isDisabled?: boolean;
  @Input() isDisabledText?: string;
  @Input() boldText?: boolean;
  @Output() onItemClicked = new EventEmitter<any>();
  @Output() onWeightChanged = new EventEmitter<any>();
  @Input() weightModel;
  @Input() secondWordLeft: string;
  @Input() thirdWordLeft: string;
  @Input() iconLeftAvatar: string;
  @Input() withSmallHeight?: boolean;
  @Input() isDisabledUser?: boolean;
  @Input() isAvatar?: boolean;
  @Input() isMediumSized?: boolean;
  @Input() isBig?: boolean;
  @Input() fromGSHistory?: boolean;
  @Input() hideStatus?: boolean;
  @Input() isWeightOver?: boolean;
  @Input() userWeight?: number;
  @Input() showTrainingActionInfo?: boolean;
  @Input() hasMoreTrainingAction?: boolean;
  @Input() actionCategory?: string;
  @Input() isModalAction?: boolean;
  @Input() competencesAskedCount?: number;
  @Input() myExpHome?: boolean;
  @Input() requestCompetenceStatus?: string;
  @Input() isDisabledUserCompetence?: boolean;
  @Input() isDisabledModalText?: string;

  innerWidth;

  constructor() {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit() {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  getCategory() {
    let category;
    if (this.contents && this.contents.length) {
      category = this.contents && this.contents.filter((x) => x.id == 'category') && this.contents.filter((x) => x.id == 'category')[0];
      if (category && category.selectedOption) {
        category = category.selectedOption.desc;
      } else {
        category = this.contents && this.contents.filter((x) => x.id == 'category') && this.contents.filter((x) => x.id == 'category')[0];
        if (category && category.value) {
          category = category.value;
        } else {
          category = '-';
        }
      }
    }
    return category;
  }

  getWeight() {
    let weight = '-';
    if (this.contents && this.contents.length) {
      weight = this.contents && this.contents.filter((x) => x.id == 'weight') && this.contents.filter((x) => x.id == 'weight')[0].value || '-'
    }
    return weight;
  }

  emitOnItemClicked() {
    this.onItemClicked.emit();
  }

  preventDefault(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  emitOnWeightInput(event) {
    this.onWeightChanged.emit(event)
  }

  toggleTooltip(event) {
    this.preventDefault(event);
  }
}
