import { NgModule, Injectable } from '@angular/core';
import { HeaderComponent } from './header/header.component';
import { SharedModule } from '../shared/shared.module';
import { UrlService } from '../shared/services/url.service';
import { RedirectService } from '../shared/services/redirect.service';
import { AuthService } from '../auth/services/auth.service';
import { AuthGuard } from '../auth/services/auth-guard.service';
import { CompatibilityGuard } from '../shared/services/compatibility-guard.service';
import { LoginGuard } from '../shared/services/login-guard.service';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { CompatibilityErrorComponent } from './compatibility-error/compatibility-error.component';
import { LangsService } from './services/langs.service';
import { ErrorPageComponent } from './error/error-page.component';
import { AppTranslateModule } from '../translate/translate.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { OwlDateTimeIntl, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { BackOfficeGuard } from '../shared/services/back-office-guard.service';
import { PageNotAuthorizedComponent } from './page-not-authorized/page-not-authorized.component';
import { HomeManageGuard } from '../shared/services/home-manage-guard.service';
import { HomeRedirectTokenComponent } from './home-redirect-token/home-redirect-token.component';

export const MY_NATIVE_FORMATS = {
    fullPickerInput: { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' },
    datePickerInput: { year: 'numeric', month: 'numeric', day: 'numeric' },
    timePickerInput: { hour: 'numeric', minute: 'numeric' },
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
};

@Injectable()
export class DefaultIntl extends OwlDateTimeIntl {
    upSecondLabel = 'Add a second';
    downSecondLabel = 'Minus a second';
    upMinuteLabel = 'Add a minute';
    downMinuteLabel = 'Minus a minute';
    upHourLabel = 'Add a hour';
    downHourLabel = 'Minus a hour';
    prevMonthLabel = 'Previous month';
    nextMonthLabel = 'Next month';
    prevYearLabel = 'Previous year';
    nextYearLabel = 'Next year';
    prevMultiYearLabel = 'Previous 21 years';
    nextMultiYearLabel = 'Next 21 years';
    switchToMonthViewLabel = 'Change to month view';
    switchToMultiYearViewLabel = 'Choose month and year';
    cancelBtnLabel = 'Annulla';
    setBtnLabel = 'Conferma';
    rangeFromLabel = 'From';
    rangeToLabel = 'To';
    hour12AMLabel = 'AM';
    hour12PMLabel = 'PM';
}

@NgModule({
    declarations: [
        HomeRedirectTokenComponent,
        HeaderComponent,
        PageNotFoundComponent,
        PageNotAuthorizedComponent,
        CompatibilityErrorComponent,
        ErrorPageComponent,
    ],
    imports: [
        SharedModule,
        AppTranslateModule,
        NgxPaginationModule
    ],
    exports: [
        HeaderComponent,
        ErrorPageComponent
    ],
    providers: [
        UrlService,
        RedirectService,
        AuthService,
        AuthGuard,
        CompatibilityGuard,
        LoginGuard,
        BackOfficeGuard,
        HomeManageGuard,
        LangsService,
        { provide: OWL_DATE_TIME_LOCALE, useValue: 'it' },
        { provide: OWL_DATE_TIME_FORMATS, useValue: MY_NATIVE_FORMATS },
        { provide: OwlDateTimeIntl, useClass: DefaultIntl }
    ]
})
export class CoreModule { }
