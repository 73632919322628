<div class="video-test-container-wrapper" fxShow.xs fxHide fxLayout="row" fxLayoutAlign="center center">
    <div fxLayout="column" fxLayoutAlign="center center">
        <div class="title-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.gt-sm="center center"
            fxLayoutGap="8px">
            <svg-icon src="../../../assets/img/icons/video-icon.svg"></svg-icon>
            <p tabindex="0" translate="generic.VIDEO_TEST"></p>
        </div>
        <div class="description-container" fxLayout="column" fxLayoutAlign="start center"
            fxLayoutAlign.gt-sm="center center">
            <p tabindex="0">{{ description }}</p>
        </div>
        <div class="full-width" fxLayout="column" fxLayoutAlign="center center" fxLayoutAlign.gt-sm="center center"
            fxLayoutGap="24px">
            <custom-button class="full-width" dimension="full-width" importance="primary"
                text="{{ 'generic.WATCH' | translate }}" (click)="onWatchButtonClick($event)" fxLayout="row"
                fxLayoutAlign="center center"></custom-button>
            <custom-button class="full-width" dimension="full-width" importance="text-only-orange"
                text="{{ 'generic.DONT_SHOW_AGAIN' | translate }}" (click)="onNeverAgainClick()" fxLayout="row"
                fxLayoutAlign="center center"></custom-button>
        </div>
    </div>
</div>


<div class="video-test-container-wrapper desktop" fxHide.xs fxHide.gt-sm fxLayout="row" fxLayoutAlign="start start">
    <div class="full-width" fxLayout="row" fxLayoutAlign="start start">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
            <svg-icon src="../../../assets/img/icons/video-icon.svg"></svg-icon>
        </div>
        <div class="margin-left40 full-width" fxLayout="column" fxLayoutAlign="start center">
            <div class="title-container" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="8px">
                <p tabindex="0" translate="generic.VIDEO_TEST"></p>
            </div>
            <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start start" class="description-container no-center">
                <p tabindex="0">{{ description }}</p>
            </div>
            <div class="full-width" fxLayout="row" fxLayoutAlign="start start">
                <custom-button class="full-width" dimension="full-width" importance="primary"
                    text="{{ 'generic.WATCH' | translate }}" (click)="onWatchButtonClick($event)" fxLayout="row"
                    fxLayoutAlign="start start"></custom-button>
                <custom-button class="full-width" dimension="full-width" importance="text-only-orange-underline"
                    text="{{ 'generic.DONT_SHOW_AGAIN' | translate }}" (click)="onNeverAgainClick()" fxLayout="row"
                    fxLayoutAlign="start start"></custom-button>
            </div>
        </div>

    </div>
</div>

<!-- Video per desktop -->
<div class="video-test-container-wrapper desktop" fxShow.gt-sm fxHide fxLayout="row" fxLayoutAlign="start start">
    <div class="full-width" fxLayout="row" fxLayoutAlign="start start">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
            <svg-icon src="../../../assets/img/icons/video-icon.svg"></svg-icon>
        </div>
        <div class="margin-left20 full-width" fxLayout="row" fxLayoutAlign="space-between center">
            <!-- Titolo e descrizione -->
            <div class="full-width margin-right16" fxLayout="column" fxLayoutAlign="start center">
                <div class="title-container" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="8px">
                    <p tabindex="0" translate="generic.VIDEO_TEST"></p>
                </div>
                <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start start"
                    class="description-container no-center">
                    <p tabindex="0">{{ description }}</p>
                </div>
            </div>
            <!-- Button azioni -->
            <div class="button-container" fxLayout="column" fxLayoutAlign="center center">
                <custom-button class="full-width" dimension="full-width" importance="primary"
                    text="{{ 'generic.WATCH' | translate }}" (click)="onWatchButtonClick($event)" fxLayout="row"
                    fxLayoutAlign="start start"></custom-button>
                <custom-button class="full-width" dimension="full-width" importance="text-only-orange-underline"
                    text="{{ 'generic.DONT_SHOW_AGAIN' | translate }}" (click)="onNeverAgainClick()" fxLayout="row"
                    fxLayoutAlign="start start"></custom-button>
            </div>
        </div>

    </div>
</div>