import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { ErrorPageComponent } from './core/error/error-page.component';
import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';
import { CompatibilityGuard } from './shared/services/compatibility-guard.service';
import { AuthGuard } from './auth/services/auth-guard.service';
import { LoginSamlErrorComponent } from './auth/login/login-saml-error.component';
import { LogoutSsoCompletedComponent } from './auth/login/logout-sso-completed.component';
import { CompatibilityErrorComponent } from './core/compatibility-error/compatibility-error.component';
import { LocalLoginComponent } from './auth/login/local-login.component';
import { LoginGuard } from './shared/services/login-guard.service';
import { BackOfficeGuard } from './shared/services/back-office-guard.service';
import { PageNotAuthorizedComponent } from './core/page-not-authorized/page-not-authorized.component';
import { LoginSSOComponent } from './auth/login-sso/login-sso.component';

const appRoutes: Routes = [
  { path: 'localLogin', component: LocalLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  { path: 'loginSSO', component: LoginSSOComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  { path: 'loginSamlError', component: LoginSamlErrorComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  { path: 'logoutSsoCompleted', component: LogoutSsoCompletedComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), canActivate: [AuthGuard, CompatibilityGuard, BackOfficeGuard] },
  { path: 'compatibilityError', component: CompatibilityErrorComponent },
  { path: 'error', component: ErrorPageComponent },
  { path: '403', component: PageNotAuthorizedComponent },
  { path: '', redirectTo: 'admin/home', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { useHash: true, preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }