import { CommonConstants } from "atfcore-commonclasses";
import { environment } from "src/environments/environment";

/*
*    Servizio che costruisce e tornag li URL delle varie applicazioni
*/
export class UrlService {
    private development: boolean;
    private staging: boolean;
    private production: boolean;

    constructor() {
        this.checkHostName();
    }

    checkHostName() {

        // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
        let firstDot = 0;
        let lastDot = window.location.hostname.lastIndexOf(".");
        if (lastDot != -1) {
            firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
        }
        if (firstDot == -1) {
            firstDot = 0;
        }
        // Per debug verifico se il primo pezzo del dominio Ã¨ "localhost o atf", allora tengo questo invece del "www"
        if (window.location.hostname.indexOf("localhost") == 0) {
            this.development = true;
        }
        else if ((window.location.hostname.indexOf("atf") == 0 && firstDot > 0)) {
            this.development = true;
        }
        else if (window.location.hostname.indexOf("test") == 0 && firstDot > 0) {
            this.staging = true;
        }
        else {
            this.production = true;
        }
    }
    applicationUrl = {
        url: null,
        baseUrl: null,
        protocol: '',
        domain: '',
        secondLevelDomain: '',
        thirdLevelDomain: ''
    };
    // Ritorna l'URL dell'applicazione
    getApplicationUrl() {


    // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
    let firstDot = 0;
    let lastDot = window.location.hostname.lastIndexOf('.');
    if (lastDot != -1) {
      firstDot = window.location.hostname.lastIndexOf('.', lastDot - 1);
    }
    if (firstDot == -1) {
      firstDot = 0;
    }
    let secondLevelDomain = window.location.hostname.substring(firstDot, window.location.hostname.length);
    if (secondLevelDomain.charAt(0) == '.') {
      secondLevelDomain = secondLevelDomain.substring(1, window.location.hostname.length);
    }

    // Controllo addizionale se è effettivamente localhost avviato da ng serve
    const isLocalhost = window.location.href.indexOf('localhost:') !== -1;
    if (isLocalhost) {
      this.applicationUrl.domain = 'atftfa.alloy.it';
      this.development = true;
    } else {
      let url = window.location.hostname.substring(0, firstDot);
      if (url) {
        url = url + '.';
      }
      this.applicationUrl.domain = url + secondLevelDomain;

      if (window.location.hostname.indexOf('atf') == 0 && firstDot > 0) {
        this.development = true;
      } else if (window.location.hostname.indexOf('test') == 0 && firstDot > 0) {
        this.staging = true;
      } else {
        this.production = true;
      }
    }

    // Calcolo il dominio di appartenenza per chiamare i servizi Rest
    this.applicationUrl.protocol = window.location.protocol === 'http:' ? 'http' : 'https';

    // Forzo https nel caso di localhost per evitare problemi di CORS
    if (isLocalhost && secondLevelDomain === 'localhost') {
      this.applicationUrl.protocol = 'https';
    }

    this.applicationUrl.baseUrl = this.applicationUrl.protocol + '://' + this.applicationUrl.domain + '/';
    this.applicationUrl.url = this.applicationUrl.protocol + '://' + this.applicationUrl.domain;
    this.applicationUrl.domain = this.applicationUrl.domain;
    return this.applicationUrl;
  }

    // Ritorna l'URL dell'applicazione
    getSSOUrl(deviceType: string, userAgent: string, urlExtension?: string) {
        let domain = "";
        let ssoUrl: string = null;
        let ssoAdditionalParam = '';
        let ssoReturnPage: string = null;
        // Calcolo il dominio di appartenenza
        const protocol = window.location.protocol === "http:" ? "http" : "https";

        // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
        let firstDot = 0;
        const lastDot = window.location.hostname.lastIndexOf(".");
        if (lastDot != -1) {
            firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
        }
        if (firstDot == -1) {
            firstDot = 0;
        }
        let secondLevelDomain = window.location.hostname.substring(firstDot, window.location.hostname.length);
        if (secondLevelDomain.charAt(0) == ".") {
            secondLevelDomain = secondLevelDomain.substring(1, window.location.hostname.length);
        }
        // Per debug verifico se il primo pezzo del dominio Ã¨ "localhost o atf", allora tengo questo invece del "www"
        if (window.location.hostname.indexOf("localhost") == 0) {
            domain = "localhosts.alloy.it";
        }
        else if ((window.location.hostname.indexOf("atf") == 0 && firstDot > 0)) {
            domain = "atfs.alloy.it";
        }
        else if (window.location.hostname.indexOf("test") == 0 && firstDot > 0) {
            domain = "testsso." + secondLevelDomain;
        }
        else {
            // ssoAdditionalParam = 'jwt=no&';
            domain = "sso.generalilearningplatform.com";
        }
        ssoReturnPage = this.getApplicationUrl().url;
        if (urlExtension && urlExtension.length) {
            ssoReturnPage = ssoReturnPage + urlExtension;
        };
        ssoUrl = protocol + "://" + 
        domain +
        //"/login?originApplicationName=" +
        '/login?' +
        ssoAdditionalParam +
        'originApplicationName=' +
        encodeURIComponent(CommonConstants.APPLICATION_TALENT_FARM) +
        "&applicationUrl=" +
        encodeURIComponent(ssoReturnPage) +
        "&deviceType=" +
        encodeURIComponent(deviceType) +
        "&userAgent=" +
        encodeURIComponent(userAgent);
        return ssoUrl;
    }

    // Url progetto ATS
    getATSUrl() {
        let protocol = "";
        let domain = "";
        let url: string = null;
        // Calcolo il dominio di appartenenza per chiamare i servizi Rest
        let port = window.location.protocol === "http:" ? 80 : 443;
        protocol = window.location.protocol === "http:" ? "http" : "https";
        // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
        let firstDot = 0;
        let lastDot = window.location.hostname.lastIndexOf(".");
        if (lastDot != -1) {
            firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
        }
        if (firstDot == -1) {
            firstDot = 0;
        }

        // Per debug verifico se il primo pezzo del dominio è "localhost o atf", allora tengo questo invece del "www"
        if (window.location.hostname.indexOf("localhost") == 0) {
            domain = "localhost.";
        }
        else if (window.location.hostname.indexOf("atf") == 0 && firstDot > 0) {
            domain = "dev-ats.alloy.it";
        }
        else if (window.location.hostname.indexOf("test") == 0 && firstDot > 0) {
            domain = "testats.genurl.it";
        }
        else {
            domain = "admin.gogenerali.com/ats";
        }

        url = protocol + "://" + domain;

        return url;
    }

    // Metodo che ritorna l'URL per della GLP
    getGLPURL(addDefaultDestinationPage?: boolean) {
        let protocol = "";
        let secondLevelDomain = "";
        let thirdLevelDomain = "";
        let url: string = null;
        // Calcolo il dominio di appartenenza per chiamare i servizi Rest
        let port = window.location.protocol === "http:" ? 80 : 443;
        protocol = window.location.protocol === "http:" ? "http" : "https";
        // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
        let firstDot = 0;
        let lastDot = window.location.hostname.lastIndexOf(".");
        if (lastDot != -1) {
            firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
        }
        if (firstDot == -1) {
            firstDot = 0;
        }
        secondLevelDomain = window.location.hostname.substring(firstDot, window.location.hostname.length);
        if (secondLevelDomain.charAt(0) == ".") {
            secondLevelDomain = secondLevelDomain.substring(1, window.location.hostname.length);
        }
        // Per debug verifico se il primo pezzo del dominio è "localhost o atf", allora tengo questo invece del "www"
        if (window.location.hostname.indexOf("localhost") == 0) {
            thirdLevelDomain = "localhost.";
        }
        else if (window.location.hostname.indexOf("atf") == 0 && firstDot > 0) {
            thirdLevelDomain = "atf.";
        }
        else if (window.location.hostname.indexOf("test") == 0 && firstDot > 0) {
            thirdLevelDomain = "test.generalilearningplatform.com";
            secondLevelDomain = '';
        }
        else {
            thirdLevelDomain = "generalilearningplatform.com";
            secondLevelDomain = '';
        }

        url = protocol + "://" + thirdLevelDomain + secondLevelDomain;

        /* Il routing di Angular inserisce in coda all'url il nome della pagina di default, mettendolo dopo anche eventuali parametri in Query string. Pertanto se, ad esempio, sto facendo un semplice redirect
         * alla Library appenendoci il Token dell'utente (es http://localhost.alloy.it?token=DFGH39ODS), Angular lo trasformerà in http://localhostl.alloy.it?token=DFGH39ODS/#/app/login. Per prevenire tale
         * comportamento è necessario quindi specificare una pagina precisa prima degli eventuali parametri in query string. L'URL definitivo sarà, ad esempio, http://localhostl.alloy.it/#/app/login?token=DFGH39ODS
        */
        if (addDefaultDestinationPage) {
            url += "/#/app/login";
        }
        return url;
    };

    // Proxa l'url di un'immagine
    getExternalResourceName = (requestUrl: string, requestName: string) => {
        return this.getApplicationUrl().baseUrl + 'rest-api/talent-farm-mediator/proxy-url/' + encodeURIComponent(requestName) + '?requestUrl=' + encodeURIComponent(requestUrl);
    }
}
