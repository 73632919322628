import { Component, EventEmitter, Input, Output } from '@angular/core';
import { allSettled } from 'q';

@Component({
  selector: 'card-object',
  templateUrl: './card-object.component.html',
  styleUrls: ['./card-object.component.scss']
})
export class CardObjectComponent {
  @Input() title: string;
  @Input() typeName?: string;
  @Input() subtitle: string;
  @Input() description: string;
  @Input() image: string;
  @Input() cardObject: any;
  @Input() duration?: string;
  @Input() categories?;
  //@Input() subCategory: string;
  @Input() date: string;
  @Input() isChecked: boolean = false;

  @Output() checkboxClicked = new EventEmitter();

  showAllData: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  emitOnModelChanged(object) {
    this.checkboxClicked.emit(object);
  }
  
  showAllInfo() {
    this.showAllData = !this.showAllData;
  }
}
