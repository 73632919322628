<div class="tab-container" fxLayout="column" fxLayoutAlign="center center">
    <div class="tabs" fxLayout="row" fxLayoutAlign="start center">
        <!-- Tab -->
        <button *ngFor="let tab of tabs" [ngClass]="{ 'active': currentTabId == tab.id }" class="tab-element"
            fxLayout="row" fxLayoutAlign="center center" (click)="selectTab(tab.id)">
            <p class="title">{{ tab.title}}</p>
        </button>
    </div>
    <!-- Contenuto delle tab -->
    <div class="tab-content" fxLayout="column" fxLayoutAlign="center center">
        <ng-content></ng-content>
    </div>
</div>