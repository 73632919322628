import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { DateUtil } from '../../../cm2-commonclasses';
import { BaseFormComponent } from 'src/app/shared/components/base-form.component';

@Component({
    selector: 'datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DatepickerComponent), multi: true },
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => DatepickerComponent), multi: true }
    ]
})
export class DatepickerComponent extends BaseFormComponent implements OnInit {
    @Input() id: string;
    @Input() label: string;
    @Input() reviewMode: boolean;
    @Input() options: any;
    @Input() pickerType: string;
    @Input() pickerMode: string;
    @Input() disabled: boolean;
    @Input() isMin: boolean;
    @Input() maxDate: Date;
    @Input() minDate: Date;
    @Input() utcMode: boolean;
    @Input() isFromProfilePage: boolean;
    @Input() fromUserFeedback: boolean;
    @Input() fromAdminPhaseTable: boolean;
    @Input() grayBackground: boolean;

    @Output() dateChanged = new EventEmitter();

    @ViewChild('datePickerInput') datePickerInput: ElementRef;
    @ViewChild('dt2') owlDateTimeComp: any;

    newDate: any;

    constructor(private __translate: TranslateService,
        private cdr: ChangeDetectorRef,
        private __renderer: Renderer2) {
        super(__translate, __renderer);
    }

    ngOnInit(): void {
        // se non specificato lavoro in UTC tenendo a modello il valore in UTC e a livello di view il formato del Locale
        this.utcMode = this.utcMode === undefined ? true : this.readBooleanInput(this.utcMode);

        this.pickerType = this.pickerType || 'calendar'; // calendar, timer, both
        this.pickerMode = this.pickerMode || 'popup'; // popup, dialog

        this.options = Object.assign({
            firstDayOfWeek: 1,
            bigBanner: true,
            timePicker: false,
            format: 'dd-MM-yyyy',
            defaultOpen: true
        }, this.options || {});

        this.value = this.value || '';
        this.placeholder = this.placeholder || '';
        this.reviewMode = this.readBooleanInput(this.reviewMode);
        this.markAsDirty = this.reviewMode ? true : this.markAsDirty;

        if (this.markAsDirty) {
            this._markAsDirty();
        }

        if (this.isMin) {
            this.newDate = new Date();
            this.newDate.setDate(this.newDate.getDate() - 1);
        }
    }

    updateModel(componentRef: this, $event): void {
        this.value = this.utcMode ? DateUtil.convertModelToUTC($event && $event.value) : $event && $event.value;
        //     this.valueChange.emit(this.value);
        this.writeValue(this.value);
        this.dateChanged.emit(this.value)
    }

    updateModelFromInputvalue(componentRef: this): void {
        let inputValue: string = componentRef && componentRef.value;
        let newValue: string = "";
        if (!!inputValue
            && (
                /^([1-3][0-9]|[1-9])(\-|\/| )([1-3][0-9]|[1-9])(\-|\/| )([0-9][0-9][0-9][0-9])$/.test(inputValue)
                || /^([1-3][0-9]|[1-9])(\-|\/| )([1-3][0-9]|[1-9])(\-|\/| )([0-9][0-9][0-9][0-9])(, )([0-1][0-9]|2[0-3]):[0-5][0-9]$/.test(inputValue)
            )) {
            // questo componente quando raccoglie il date e time insieme separe con la virgola
            let tks = inputValue.split(",");
            let _date = tks && tks.length ? moment(tks[0], 'L', this.owlDateTimeComp.dateTimeAdapter.locale).toISOString() : null;
            if (_date && tks && tks.length > 1) {
                let _time = moment(tks[1], 'LT', this.owlDateTimeComp.dateTimeAdapter.locale).toISOString();
                newValue = DateUtil.calculateDateTimeByDay(_date, _time);
            } else {
                newValue = _date;
            }
            this.value = newValue;
            this.valueChange.emit(newValue);
            this.writeValue(newValue);
        }
    }

    cleanOpenedStyle() {
        // alla chiusura pulisce il bordino che evidenzia l'input del date/time picker
        if (this.__renderer)
            this.__renderer.removeAttribute(this.datePickerInput.nativeElement, 'aria-owns');
    }

}
