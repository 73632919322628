import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, switchMap, filter } from 'rxjs/operators';
// Import dello State dell'applicativo
import * as fromApp from '../../ngrx/app.reducers';
// Import delle azioni del core
import { RequiredAuth } from 'atfcore-commonclasses';
import { AuthService } from '../../auth/services/auth.service';
import { onceIfNotEmpty } from '../../ngrx/util';

@Injectable()
export class BackOfficeGuard implements CanActivate {

    constructor(private store: Store<fromApp.AppState>,
        private authService: AuthService,
        private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.store.select(fromApp.isAuthenticated)
            .pipe(
                filter((isAuthenticated) => isAuthenticated),
                switchMap(() => {
                    return this.store.select(fromApp.getLoggedUser);
                }),
                onceIfNotEmpty(),
                map(loggedUser => {
                    if (loggedUser && loggedUser.auths && loggedUser.auths.length
                        && (this.authService.isUserAuthorized(RequiredAuth.TALENT_FARM_ADMIN, loggedUser.auths) || this.authService.isUserAuthorized(RequiredAuth.TALENT_FARM_ADMIN_EBRM, loggedUser.auths) || (this.authService.isUserAuthorized(RequiredAuth.TALENT_FARM_ADMIN_ATS, loggedUser.auths)))) {
                        return true;
                    } else {
                        this.router.navigateByUrl('/403');
                        return false;
                    }
                })
            );
    }
}
