<div fxLayout="row" class="star-rating-wrapper" [ngClass]="isPastRating ? 'past-background' : ''"
  fxLayoutAlign="space-between center">
  <div class="full-width" fxLayout="row" fxLayoutAlign="start center">
    <svg-icon class="margin-right10" aria-hidden="true" [src]="entireObject && entireObject.iconLeft"></svg-icon>
    <div class="full-width" fxLayout="row">
      <div fxLayout="row" fxLayoutAlign="space-between center" class="full-width" fxLayout.xs="column"
        fxLayoutAlign.xs="start start">
        <div class="full-width" fxLayout="row" fxLayoutAlign="start center">
          <!-- Titolo e * required-->
          <div class="title120" fxLayout="row" fxLayoutAlign="start center">
            <span tabindex="0" class="padding-left10 padding-bottom5 title-competence">{{entireObject.title}}</span>
            <div *ngIf="!isPastRating && !isRated(entireObject)" class="red-text">*</div>
          </div>
          <!-- Tooltip-->
          <div attr.aria-label="{{entireObject.title}}" class="info-icon" fxLayoutAlign="end end"
            aria-hidden="true" (click)="emitOnTooltipClicked(entireObject.title)">
            <!--div tabindex="0" attr.aria-label="{{entireObject.title}}" class="info-icon" fxLayoutAlign="end end"
            tooltip-class="tooltip-star-class" aria-hidden="true" tooltip="{{entireObject.title}}"-->
            <svg-icon class="info-icon" src="assets/img/icons/gear-informativa.svg">
            </svg-icon>
          </div>
        </div>
        <!-- Stelle rating -->
        <div *ngIf="!isPastRating" fxLayout="column" fxLayoutAlign="end end" fxLayoutAlign.xs="start center">
          <div tabindex="0" attr.aria-label="{{'competence.VALUTATE_COMPETENCE' | translate}}" fxLayout="row" fxLayoutAlign="end end" fxLayoutAlign.xs="start start">
            <button attr.aria-label="{{('competenceMark.' + (i + 1)) | translate }}" (click)="buttonClicked(star.id)" *ngFor="let star of entireObject.stars; let i = index"
              (mouseover)="onStarMouseOver(star.id)" (mouseout)="onStarMouseOut()" class="button-star"
              [ngClass]="{ 'hovered' : star.isHovered || star.isClicked, 'disabled': disabled }">
              <svg-icon src="assets/img/icons/star.svg">
              </svg-icon>
            </button>
          </div>
          <div class="mark-label" fxLayout="row" fxLayoutAlign="center center">
            <p>{{ markLabel }}</p>
          </div>
        </div>
        <!-- Stelle rating -->
        <div *ngIf="isPastRating" fxLayout="column" fxLayoutAlign="end end" fxLayoutAlign.xs="start center">
          <div fxLayout="row" fxLayoutAlign="end end" fxLayoutAlign.xs="start start">
            <button *ngFor="let star of entireObject.stars; let i = index" class="button-star past-rating"
              [ngClass]="{ 'hovered' : star.isClicked }">
              <svg-icon src="assets/img/icons/star.svg">
              </svg-icon>
            </button>
          </div>
          <div class="mark-label" fxLayout="row" fxLayoutAlign="center center">
            <p tabindex="0">{{ markLabel }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>