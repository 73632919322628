<div class="card-container">
  <div class="image-container">
    <img [src]="image ? image : 'assets/img/placeholder-image.svg'">
  </div>
  <div class="info-container">
    <div class="header-info-container">
      <div class="first-row">
        <div fxLayout="row" class="full-width" fxLayoutAlign="space-between center">
        <p class="type-text">{{typeName | translate}}</p>
      </div>
        <label class="container-checkbox card-object">
          <input type="checkbox" [checked]="isChecked" (click)="emitOnModelChanged($event)"
            attr.aria-label="{{ 'filter-generic-aria-label' }}">
          <span class="checkmark"></span>
        </label>
      </div>
      <div class="divisor-line"></div>
      <div class="title">
        <p>{{title}}</p>
      </div>
      <div class="full-width" fxLayout="row" fxLayoutAlign="space-between center">
        <p class="date-text">{{ date }}</p>
        <div (click)="showAllInfo()" class="major-info">
          <p translate="generic.MORE_DETAILS"></p>
          <svg-icon [src]="showAllData ? 'assets/img/icons/arrow-top.svg' : 'assets/img/icons/arrow-bottom.svg'"></svg-icon>
        </div>
      </div>
      <div class="second-row">
        <p>{{subtitle}}</p>
      </div>
      <p *ngIf="duration" class="duration-text">{{ duration }}</p>
    </div>
    <div *ngIf="showAllData" class="description-container">
      <p [innerHTML]="description"></p>
    </div>
    <div *ngIf="categories && categories.length > 0" class="type-container">
      <div *ngFor="let cat of categories" class="container-box categories-tag-text" [title]="cat" style="margin-top: 10px;">
        <p>{{ cat }}</p>
      </div>
    </div>
  </div>
</div>
