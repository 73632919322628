import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'card-episode',
  templateUrl: './card-episode.component.html',
  styleUrls: ['./card-episode.component.scss']
})
export class CardEpisodeComponent {
  @Input() cardObject: any;
  @Input() title: string;
  @Input() description: string;
  @Input() image: string;
  @Input() duration?;
  @Input() categories?;
  //@Input() subCategory: string;
  @Input() index: string;
  @Output() onMenuButtonClicked: EventEmitter<any> = new EventEmitter();
  menuItems: string[];
  constructor() { }

  ngOnInit() {
    this.menuItems = ['EDIT_OBJECT', 'DELETE_OBJECT', 'MOVE_UP', 'MOVE_DOWN']
  }

  menuAction(choose: string) {
    this.onMenuButtonClicked.emit({
      choose: choose,
      object: this.cardObject
    });
  }
}
