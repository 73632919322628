import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as fromApp from '../../ngrx/app.reducers';
import { Observable, combineLatest, Subscription, of } from 'rxjs';
import { GlobalApplicationData } from 'src/app/shared/models/global-application-data.model';
import { UrlService } from 'src/app/shared/services/url.service';
import * as CommonClasses from "../../../cm2-commonclasses";
import { UserAcknowledges, SenecaResponse } from '../../../cm2-commonclasses';

@Injectable()
export class HomeService {
    applicationData: GlobalApplicationData;
    result$: Subscription;
    applicationContext: any;
    constructor(private store: Store<fromApp.AppState>,
        private urlService: UrlService,
        private http: HttpClient) {
        let globalApplicationData$: Observable<GlobalApplicationData> = this.store.select(fromApp.getGlobalApplicationData);
        const combinedSelectes$ = combineLatest(globalApplicationData$);
        this.result$ = combinedSelectes$.subscribe(
            ([globalApplicationData]) => {
                this.applicationData = globalApplicationData;
                this.applicationContext = this.urlService.getApplicationUrl().baseUrl;
            });
    }

    getDashboardIndicators() {
        return this.http.get<CommonClasses.SenecaResponse<UserAcknowledges>>(this.applicationContext + 'rest-api/talent-farm-mediator/get-all-userAcknowledges');
    }
    
    getTouchpointData(year: string, withCompletionPercentageLoaded: boolean, propertiesFilters?, forceHrbp?: boolean, forceManager?: boolean) {
        return this.http.post<CommonClasses.SenecaResponse<any>>(this.applicationContext + 'rest-api/talent-farm-mediator/get-performance-assessment-phases', {
            year: year,
            withCompletionPercentageLoaded: withCompletionPercentageLoaded ? "true" : "false",
            propertiesFilters: propertiesFilters,
            forceHrbp: forceHrbp,
            forceManager: forceManager
        });
    }

    getValutationsYears() {
        /* return this.http.get<CommonClasses.SenecaResponse<UserAcknowledges>>(this.applicationContext + 'rest-api/talent-farm-mediator/get-all-userAcknowledges'); */
        return of(new SenecaResponse<any>(null, true));
    }

    getIdpYears() {
        /* return this.http.get<CommonClasses.SenecaResponse<UserAcknowledges>>(this.applicationContext + 'rest-api/talent-farm-mediator/get-all-userAcknowledges'); */
        return of(new SenecaResponse<any>(null, true));
    }

    getCurrentYear() {
        return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationContext + 'rest-api/talent-farm-mediator/get-performance-assessment-year-for-user', {});
    }
}
