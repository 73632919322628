<button tabindex="0" class="custom-button-container" [ngClass]="(disabled ? 'disabled' : '') + ' ' + (dimension ? dimension : 'btn-regular') + ' ' + 
        (importance ? importance : 'primary') + ' ' + 
        (fromNinebox ? 'from-ninebox': '')" (click)="onButtonClick($event)">
    <div class="button-content" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="text-container" fxLayout="row" fxLayoutAlign="center center">
            <div *ngIf="leftIcon" class="left-icon" fxLayoutAlign="end center">
                <svg-icon src="assets/img/icon-plus-filled.svg">
                </svg-icon>
            </div>
            <p [ngClass]="{'uppercase': uppercase, 'library-competence-button-text': isLibraryOnlineImg}">{{ text }}</p>
        </div>

        <div *ngIf="isLibraryOnlineImg">
            <img src="assets/img/library-online-img.png">
        </div>

        <div *ngIf="rightIcon" class="right-icon" fxLayout="row" fxLayoutAlign="start center">
            <svg-icon *ngIf="rightIcon.indexOf('/matr') >= 0" class="matrix" [src]="rightIcon" style="width:16px;">
            </svg-icon>
            <svg *ngIf="!(rightIcon.indexOf('/matr') >= 0)" id="Livello_1" data-name="Livello 1"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                <title>gear-scarica</title>
                <path
                    d="M38.77,19.22H34a.65.65,0,1,0,0,1.3h4.77A1.43,1.43,0,0,1,40.2,22v17.1a1.43,1.43,0,0,1-1.43,1.43H11.23A1.43,1.43,0,0,1,9.8,39.05V22a1.43,1.43,0,0,1,1.43-1.43h5.29a.65.65,0,1,0,0-1.3H11.23A2.73,2.73,0,0,0,8.5,22v17.1a2.73,2.73,0,0,0,2.73,2.73H38.77a2.73,2.73,0,0,0,2.73-2.73V22A2.74,2.74,0,0,0,38.77,19.22Z" />
                <path
                    d="M31.48,25a.65.65,0,0,0-.92,0l-4.91,4.91V10.19a.65.65,0,1,0-1.3,0v19.7L19.5,25a.65.65,0,1,0-.92.92l6,6a.65.65,0,0,0,.46.19.66.66,0,0,0,.25,0h0a.64.64,0,0,0,.19-.13h0l6-6A.65.65,0,0,0,31.48,25Z" />
            </svg>
        </div>
    </div>
</button>