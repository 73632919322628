import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { ModalService } from './modal.service';

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Input() id: string;
  @Input() modalType: string;
  @Input() defaultStyle = true;
  @Input() firstTabIndex;
  @Input() title?: string;
  @Input() isLoading = false;
  @Input() rejectButton = false;
  @Input() confirmButton = false;
  @Input() rejectLabel?: string;
  @Input() confirmLabel?: string;
  @Input() showBackButton;
  @Input() closeWithoutButton;
  @Input() noPadding: boolean;
  @Input() uploadModal = false;
  @Output() onCloseWithoutButtonFunction = new EventEmitter<any>();
  @Output() onBackButtonFunction = new EventEmitter<any>();
  @Output() onInfoIconOpenModalFunction = new EventEmitter<any>();
  @Output() onConfirmButtonClicked = new EventEmitter<any>();
  @Output() onRejectButtonClicked = new EventEmitter<any>();

  private element: any;
  @ViewChild('modalEl') modalEl;
  focusedElBeforeOpen: any;

  constructor(
    private modalService: ModalService,
    private el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    const modal = this;
    // Devo assicurarmi di avere l'id della modale
    if (!this.id) {
      return;
    }

    // Affinché venga resa visibile ovunque, la appendo appena dopo la chiusura del </body>
    document.body.appendChild(this.element);

    // Chiudo la modale al click sullo sfondo
    this.element.addEventListener('click', (e: any) => {
      if (e.target.className === 'modal') {
        if(this.closeWithoutButton) {
          this.onCloseWithoutButtonFunction.emit()
        }
        modal.close();
      }
      if(e.target.innerHTML == ("Indietro" || "Back")) {
        this.onBackButtonFunction.emit();
      }
    });

    // Aggiungo la modale al servizio affinché venga resa accessibile dai vari componenti
    this.modalService.add(this);
  }

  // Apre la modale
  open(): void {
    this.focusedElBeforeOpen = document.activeElement;
    this.element.style.display = 'block';
    document.body.classList.add('modal-open');
    setTimeout(()=> {
      this.modalEl.nativeElement.focus();
      },500)
  }

  // Chiude la modale
  close(): void {
    this.element.style.display = 'none';
    document.body.classList.remove('modal-open');
    if(this.focusedElBeforeOpen) {
    this.focusedElBeforeOpen.focus();
    }
  }

  // Apri modale dall'icona info
  openModalInfoIcon() {
    this.onInfoIconOpenModalFunction.emit();
  }

  // Quando la direttiva è distrutta, tolgo la modale dal servizio
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    if (typeof this.element.remove === 'function') {
      this.element.remove();
    } else {
      this.element.parentNode.removeChild(this.element);
    }
  }

  confirmButtonClicked() {
    this.onConfirmButtonClicked.emit();
  }

  rejectButtonClicked() {
    this.onRejectButtonClicked.emit();
  }

}
