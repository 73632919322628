<div class="footer" fxLayout="column" fxLayoutAlign="center center">

  <!-- Primo footer -->
  <div class="footer-content" fxLayout="column">
    <div class="footer-content-social" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign.lt-md="center center">
      <!-- Icona -->
      <div tabindex="0" class="footer-content-logo"></div>
      <!-- Riferimenti social -->
      <div fxFlex fxFlexOffset.lt-md="29px" class="footer-icon" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end center">
        <!-- Desktop -->
        <p tabindex="0" fxShow fxHide.lt-md translate="footer.FOLLOW_SOCIAL"></p>
        <!-- Mobile -->
        <p tabindex="0" fxHide fxShow.lt-md translate="footer.FOLLOW_SOCIAL_MOB1"></p>
        <p tabindex="0" fxHide fxShow.lt-md translate="footer.FOLLOW_SOCIAL_MOB2"></p>

        <div fxLayout="row" class="footer-content-margin" fxLayoutAlign.lt-md="space-between center">
          <!-- Social buttons -->
          <button tabindex="0" role="button" *ngFor="let social of socialButtons"
            (click)="openSocialLink(social.redirect)" class="footer-content-icon">
            <svg-icon [src]="social.srcIcon"></svg-icon>
          </button>
        </div>

      </div>
    </div>
    <!-- Sezioni principali -->
    <div fxFlexOffset="78px" fxFlexOffset.lt-md="0px" fxLayout="row" fxLayout.lt-md="column"
      fxLayoutAlign="space-between center">
      <div tabindex="0" role="button" *ngFor="let tab of firstFooterTabs" class="no-user-select cursor-pointer"
        [ngClass.lt-md]="'footer-lt-md-content-section'" (click)="tab.redirect()" fxLayout.lt-md="row"
        fxLayoutAlign.lt-md="space-between center">
        <h5>{{isDesktop ? ((tab.title | translate) | uppercase) : (tab.title | translate)}}</h5>
        <svg-icon fxHide fxShow.lt-md src="../../../assets/icons/icon-plus.svg" height="12px" width="12px">
        </svg-icon>
      </div>
    </div>
  </div>

  <!-- Secondo footer -->
  <div class="footer-second-content" fxLayout="column" fxLayoutAlign="space-between stretch">
    <!-- Sezioni secondarie -->
    <div tabindex="0" role="button" class="titles" fxLayout="row wrap" fxLayoutAlign.lt-md="space-between center">
      <p class="cursor-pointer no-user-select" *ngFor="let tab of secondFooterTabs" (click)="tab.redirect()"
        [translate]="tab.title"></p>
    </div>
    <!-- Riferimenti -->
    <p tabindex="0" fxLayout="row" fxLayout.lt-md="column">
      <span>© Generali Italia S.p.A. -&nbsp;</span>
      <span>C.F. 00409920584 - P.IVA 00885351007 -&nbsp;</span>
      <a role="button" href="mailto:generaliitalia@pec.generaligroup.com">generaliitalia@pec.generaligroup.com</a>
    </p>
  </div>

</div>