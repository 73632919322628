import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent {
  @Input() fromGSHistory?: boolean;
  @Input() isInDashboard?: boolean;
  @Input() accordion;
  @Input() isPastObjective: boolean;
  @Input() isCheckboxList: boolean;
  @Input() rightText: string;
  @Input() useHighlighTextPipe: boolean;
  @Input() searchedText?: string;
  @Input() hasTooltip?: boolean;
  @Input() weightModel?: string;
  @Input() weightEdit?: boolean;
  @Input() isRequired?: boolean;
  @Output() onAccordionClicked = new EventEmitter<any>();
  @Output() onWeightChanged = new EventEmitter<any>();
  @Input() isDisabled?: boolean;
  @Input() isDisabledText?: string;
  @Input() withPadding?: boolean;
  @Input() boldText?: boolean;
  @Input() imageRight?: string;
  @Input() overflowUnset?: boolean;
  @Input() isDesktop?: boolean;
  @Input() hideStatus?: boolean;
  @Input() negativeMarginContent?: boolean;
  @Input() isWeightOver?: boolean;
  @Input() userWeight?: number;
  @Input() showTrainingActionInfo?: boolean;
  @Input() hasMoreTrainingAction?: boolean;
  @Input() actionCategory?: string;
  @Input() isModalAction?: boolean;
  @Input() requestCompetenceStatus?: string;
  @Input() isDisabledUserCompetence?: boolean;
  @Input() isDisabledModalText?: string;

  constructor() { }

  onItemClicked() {
    this.onAccordionClicked.emit(this.accordion);
  }

  emitOnWeightChanged(event) {
    this.onWeightChanged.emit(event)
  }
}
