<div class="modal">
    <div #modalEl class="modal-body modal-wepa" tabindex="-1" aria-modal="true" role="dialog" [ngClass]="{'upload-width': uploadModal}">
        <!--<button class="back-button" tabindex="0" (click)="close()">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                <svg-icon src="../../../../assets/img/icons/arrow-left.svg"></svg-icon>
                <p translate="generic.BACK"></p>
            </div>
        </button>-->

        <!--modal title-->
        <div *ngIf="defaultStyle" class="modal-title" fxLayout="row" fxLayoutAlign="start center">
            <h3>{{ title | translate }}</h3>
        </div>
        
        <!--loader-->
        <div *ngIf="isLoading" class="full-width">
            <generic-list-placeholder class="full-width" *ngFor="let number of [0,1]"></generic-list-placeholder>
        </div>

        <!--modal content-->
        <div *ngIf="!isLoading">
            <div class="modal-content">
                <ng-content></ng-content>
            </div>
            <div *ngIf="defaultStyle" class="full-width modal-buttons" fxLayout.xs="column" fxLayout="row" [fxLayoutAlign]="((!rejectButton || !confirmButton) ? 'center center' : 'space-between center')">
                <custom-button *ngIf="rejectButton" id="rejectBtn" dimension="btn-modal" importance="secondary" 
                    text="{{ rejectLabel | translate }}" (onButtonClicked)="rejectButtonClicked()">
                </custom-button>
                <custom-button *ngIf="confirmButton" id="confirmBtn" dimension="btn-modal" importance="primary"
                    text="{{ confirmLabel | translate }}" (onButtonClicked)="confirmButtonClicked()">
                </custom-button>
            </div>
        </div>

    </div>
</div>