import { Component, Input } from '@angular/core';

@Component({
  selector: 'accordion-content',
  templateUrl: './accordion-content.component.html',
  styleUrls: ['./accordion-content.component.scss']
})
export class AccordionContentComponent {
  @Input() isPastObjective: boolean;
  @Input() withPadding: boolean;
  @Input() isOpen: boolean;
  @Input() overflowUnset: boolean;
  @Input() negativeMarginContent: boolean;
  constructor() { }
}
