import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RedirectService } from 'src/app/shared/services/redirect.service';

@Component({
    selector: 'video-intro',
    templateUrl: './video-intro.component.html',
    styleUrls: ['./video-intro.component.scss']
})

export class VideoIntroComponent implements OnInit {
    @Input() description: string;
    @Output() onWatchButtonClicked = new EventEmitter();
    @Output() onNeverAgainClicked = new EventEmitter();
    isUsingIE: boolean;
    constructor(
        public redirectService: RedirectService,
        private translate: TranslateService) {
    }

    ngOnInit() {
        this.isUsingIE = this.isInternetExplorerBrowser();
    }

    onWatchButtonClick(data?) {
        this.onWatchButtonClicked.emit(data);
    }

    onNeverAgainClick(data?) {
        this.onNeverAgainClicked.emit(data);
    }

    isInternetExplorerBrowser() {
        const ua = window.navigator.userAgent;
        const msie = ua.indexOf("MSIE ");

        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
            return true;
        }
        return false;
    }
}