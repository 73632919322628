import { Component, Input, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss']
})
export class StarRatingComponent implements OnInit {
  stars;
  @Input() entireObject;
  @Input() isPastRating;
  @Input() disabled;
  @Output() onTooltipClicked: EventEmitter<string> = new EventEmitter();
  markLabel;
  originalMarkLabel;
  translations: any;
  constructor(public translate: TranslateService) {
    this.translate.get([
      'competenceMark.0',
      'competenceMark.1',
      'competenceMark.2',
      'competenceMark.3',
      'competenceMark.4',
      'competenceMark.5'
    ]).subscribe((translations) => {
      this.translations = translations;
    })
  }

  ngOnInit() {
    this.markLabel = this.translations['competenceMark.0'];
    if (this.entireObject && this.entireObject.stars){
      let mark;
      for (let i = 0; i < this.entireObject.stars.length; i++){
        if (this.entireObject.stars[i].isClicked){
          mark = i;
        }
      }
      if (mark || mark == 0){
        this.markLabel = this.translations['competenceMark.' + (mark + 1)];
        this.originalMarkLabel = this.markLabel;
      } else {
        this.markLabel = this.translations['competenceMark.0'];
        this.originalMarkLabel = this.markLabel;
      }
    }
  }

  onStarMouseOver(id: number) {
    if (!this.disabled) {
      for (let i = 0; i <= id; i++) {
        this.entireObject.stars[i].isHovered = true;
        this.markLabel = this.translations['competenceMark.' + (id + 1)];
      }
    }
  }

  onStarMouseOut() {
    if (!this.disabled) {
      for (let i = 0; i < this.entireObject.stars.length; i++) {
        this.entireObject.stars[i].isHovered = false;
      }
    }
    if (this.originalMarkLabel) {
      this.markLabel = this.originalMarkLabel;
    }
  }

  buttonClicked(id: number) {
    if (!this.disabled) {
      for (let i = 0; i < this.entireObject.stars.length; i++) {
        this.entireObject.stars[i].isClicked = false;
        this.entireObject.stars[i].isHovered = false;
      }
      for (let i = 0; i <= id; i++) {
        this.entireObject.stars[i].isClicked = true;
        this.originalMarkLabel = this.markLabel;
      }
    }
  }

  isRated(currentObject) {
    if (currentObject.stars && currentObject.stars[0] && currentObject.stars[0].isClicked) {
      return true;
    }
    return false;
  }

  emitOnTooltipClicked(title) {
    this.onTooltipClicked.emit(title)
  }
}
