import { Component, Input, Output, EventEmitter } from '@angular/core';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'page-container',
    templateUrl: './page-container.component.html',
    styleUrls: ['./page-container.component.scss']
})

export class PageContainerComponent {
    @Input() isTouchpoint: boolean;
    @Input() secondPage: boolean;
    @Input() title: string;
    @Input() imgSrc: string;
    @Input() videoSrc: string;
    @Input() showBackBtn: boolean;
    @Input() noMarginTop: boolean;
    @Input() userForename: string;
    @Input() userAvatar: string;
    @Input() noVideoButton: boolean;
    @Input() notNegativeMarginTop: boolean;
    @Input() withUserData: boolean;
    @Input() imageHeightClass: string;
    @Input() isHome: boolean;
    @Input() isMyExperienceIcon: boolean;
    @Input() isHomeMyExperience: boolean;
    @Input() isMyExpExperiences: boolean;
    @Input() isMyExpCompetences: boolean;
    @Input() isMyExpPersonas: boolean;
    @Input() isMyExpConsole: boolean;
    @Input() isPersonasProfilesList: boolean;
    @Input() isBadgePage: boolean;
    @Output() onVideoButtonClicked: EventEmitter<any> = new EventEmitter();
    @Output() customGoBack: EventEmitter<any> = new EventEmitter();

    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public redirectService: RedirectService) {
    }

    goBack() {
        window.history.back();
        // if (this.customGoBack && this.customGoBack instanceof EventEmitter) {
        //     this.customGoBack.emit();
        // } else {
        //     // this.redirectService.goBack(this.route, this.secondPage, this.isTouchpoint)
        // }
    }

    isProfilePage(){
        return window.location.href.indexOf('users/profile') >= 0;
    }

    onVideoClicked() {
        this.onVideoButtonClicked.emit();
    }
}