/*
* Componente che crea una singola Tab
*/

import { EventEmitter, Input, OnChanges, OnDestroy, Output, OnInit, Renderer2, Injectable, Component } from '@angular/core';
import { ControlValueAccessor, FormControl } from '@angular/forms';
import { BaseSubscriberComponent } from './base-subscriber.component';
import { parseBoolean } from '../../../cm2-commonclasses';
import { TranslateService } from '@ngx-translate/core';

/**
 * Classe che rappresenta le caratteristiche base dei componenti custom per gestione della validazioni
 */

@Component({
    template: '<span></span>'
})
export class BaseFormComponent extends BaseSubscriberComponent implements ControlValueAccessor, OnChanges, OnDestroy {

    @Input() id: string;
    // Viene aggiunto per l'accessibilità come testo da mettere nell' input dell' ng-select(aria-label)
    @Input() ariaLabelText: string;
    @Input() fControl: FormControl;
    @Input() value: any;
    // mappa che contiene i messaggi di errore associati agli errori di validazione del Form
    // da definire insieme ai FormControls
    @Input() errors: any;
    @Input() placeholder: string;
    @Input() disabled: boolean;
    @Input() markAsDirty = undefined;
    @Input() required = false;

    @Output() valueChange: any = new EventEmitter<any>();

    
    constructor(public translate: TranslateService,
        public renderer: Renderer2) {
        super();

        // default validation errors
        this.errors = {
            required: 'errors.forms.generic.ERROR_FIELD_REQUIRED',
            minlength: 'errors.forms.generic.ERROR_FIELD_MINLENGTH',
            maxlength: 'errors.forms.generic.ERROR_FIELD_MAXLENGTH',
            forbidden: 'errors.forms.generic.ERROR_FIELD_FORBIDDEN'
        };
    }

    // Viene messo per l'accessibilità per aggiungere agli input degli ng-select l'aria-label con un valore
    ngAfterViewInit(): void {
        if (this.ariaLabelText && !!this.getNativeInputElement()) {
            this.translate.get(this.ariaLabelText).subscribe((translatedText: string) => {
                this.renderer.setAttribute(this.getNativeInputElement(), "aria-label", translatedText);
            })
        } else if(!!this.getNativeInputElement()){
            this.renderer.setAttribute(this.getNativeInputElement(), "aria-label", "Generico");
        }
    }

    // Viene presa la funzione con lo stesso nome ma entra su quella del componente figlio
    getNativeInputElement() {
        return null;
    }

    ngOnChanges(changes: any): void {
        if (changes.value) {
            this.valueChange.emit(this.value);
            this.propagateChange(this.value);
        }

        if (changes.markAsDirty && parseBoolean(changes.markAsDirty.currentValue)) {
            this._markAsDirty();
        }
    }

    _markAsDirty() {
        this.markAsDirty = true;
        if (this['cdr'] && this.fControl) {
            this.fControl.markAsTouched({ onlySelf: true });
            this.fControl.markAsDirty();
            this['cdr'].detectChanges();
        }
    }

    updateModel(componentRef: this, $event): void {
        this.value = $event;
        this.valueChange.emit(this.value);
        this.writeValue($event);
    }

    getFormError(formControl: FormControl) {
        if (formControl && formControl.errors) {
            if (formControl.errors.required) {
                return this.errors.required;
            }
        }
        return '';
    }

    // dispatch dell'evento onchange del componente costum
    propagateChange: any = () => { };

    // optional custom validation
    validateFn: any = () => { };

    validate(c: FormControl) {
        return this.validateFn(c);
    }

    writeValue(obj: any): void {
        this.value = obj;
        this.propagateChange(this.value);
    }

    // aggancia il listener per propagare l'onChange anche al FormControl
    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    // per eventi Touch
    registerOnTouched(fn: any): void {
    }

    // permette ad Angular di gestire il disabled
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

}
