<div class="full-width container-item">
  <button *ngIf="!weightEdit" class="item-list-wrapper full-width no-style-button" (click)="emitOnItemClicked()"
    [ngClass]="{'no-border-bottom': (isOpen && !fromGSHistory), 'pointer-none': (isDisabledUser || iconLeftAvatar) , 'margin-top-with-user-data': iconLeftAvatar, 'past-objective': isPastObjective, 'objective': isObjective,
    'is-disabled': isDisabled, 'big': isBig}" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
    <div *ngIf="iconLeft || iconLeftAvatar || imageSrc || (statusToLeft && !hideStatus)">
      <svg-icon *ngIf="iconLeft" aria-hidden="true" class="icon-man-style" [src]="iconLeft"></svg-icon>
      <img *ngIf="iconLeftAvatar" class="img-avatar no-pointer" [src]="iconLeftAvatar">
      <img *ngIf="imageSrc" class="img-avatar" [src]="imageSrc">
      <div class="circle {{statusClass}}" *ngIf="statusToLeft && !hideStatus">
        <p fxShow fxHide.xs translate="statuses.{{status}}"></p>
      </div>
    </div>

    <!-- Contenuto centrale titoli, nome, link -->
    <div class="full-width item-list-container" fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div *ngIf="isDisabledUser" class="title-container" fxLayout="column" fxLayoutAlign="start start">
          <div fxLayout="row" fxLayoutAlign="center center">
            <p *ngIf="!useHighlighTextPipe" class="title"
              [ngClass]="{'bold': isObjective || boldText, 'use-all-space': !rightText && !iconRight}">{{ mobileTitle ? (innerWidth >= 960 ? title : mobileTitle) : title}}</p>
          </div>
          <span class="is-disabled-user-text grey" translate="generic.NOT_ACTIVE_PROCESS"></span>
          <span [ngClass]="{'no-events': isInDashboard}" class="is-disabled-user-text margin-top4 pointable"
            translate="generic.STORIC_VALUTATIONS"></span>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" class="title-container">
          <!-- Contenuti accordion effettivo -->
          <div class="full-width" fxLayout="row" fxLayoutAlign="start center" *ngIf="!isDisabled">
            <div fxLayout="column">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <span *ngIf="isObjective" class="bold-text">{{order}}.</span>
                <div *ngIf="!secondWordLeft && !thirdWordLeft && !isDisabledUser && !requestCompetenceStatus" class="full-width" fxLayout="row"
                  fxLayoutAlign="start center">
                  <p *ngIf="!useHighlighTextPipe" class="title"
                    [ngClass]="{'bold': isObjective || boldText, 'use-all-space': !rightText && !iconRight}">{{ mobileTitle ? (innerWidth >= 960 ? title : mobileTitle) : title}}</p>
                    <p [ngClass]="{'bold': boldText}" *ngIf="useHighlighTextPipe"
                    [innerHTML]="title" class="title"></p>
                  <div *ngIf="isRequired" class="red-text">*</div>
                </div>
                <div *ngIf="requestCompetenceStatus" class="full-width" fxLayout="column"
                fxLayoutAlign="start start">
                <p class="title"
                    [ngClass]="{'bold': isObjective || boldText, 'use-all-space': !rightText && !iconRight}">{{ mobileTitle ? (innerWidth >= 960 ? title : mobileTitle) : title}}</p>
                  <p translate="myexperience.competenceUserStatus.COMPETENCE_REQUESTED" *ngIf="requestCompetenceStatus && requestCompetenceStatus == 'COMPETENCE_REQUESTED'"></p>
                  <p translate="myexperience.competenceUserStatus.COMPETENCE_CONFIRMED" *ngIf="requestCompetenceStatus && requestCompetenceStatus == 'COMPETENCE_CONFIRMED'"></p>
                  <p translate="myexperience.competenceUserStatus.COMPETENCE_REFUSED" *ngIf="requestCompetenceStatus && requestCompetenceStatus == 'COMPETENCE_REFUSED'"></p>
                </div>
                <div *ngIf="secondWordLeft || thirdWordLeft" class="full-width" fxLayout="column"
                  fxLayoutAlign="start start">
                  <p *ngIf="!useHighlighTextPipe" class="title"
                    [ngClass]="{'bold': isObjective || boldText, 'use-all-space': !rightText && !iconRight}">{{ mobileTitle ? (innerWidth >= 960 ? title : mobileTitle) : title}}</p>
                  <span *ngIf="secondWordLeft" class="title-other-word">{{secondWordLeft}}</span>
                  <span *ngIf="thirdWordLeft" class="title-other-word">{{thirdWordLeft}}</span>
                </div>
                <svg-icon *ngIf="hasTooltip" aria-hidden="true" class="info-icon" (click)="toggleTooltip($event)"
                  [src]="'assets/img/icons/gear-informativa.svg'" tooltip-class="custom-tooltip" hide-delay="250"
                  tooltip="{{('generic.LOGBOOK_TOOLTIP' | translate )}}" trigger="click" placement="top">
                </svg-icon>
              </div>
              <!-- peso e per gli utenti -->
              <div *ngIf="userWeight || userWeight == 0" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px"
                class="category-class user-weight">
                <span *ngIf="userWeight <= 100">{{ ('goal.ASSIGNED_WEIGHT' | translate )}}: {{ userWeight }}%</span>
                <span *ngIf="userWeight > 100" class="red-text">{{ ('goal.ALERT_ASSIGNED_WEIGHT' | translate )}}</span>
              </div>
              <!-- Informazioni azione formazione -->
              <div *ngIf="showTrainingActionInfo && !isOpen" fxLayout="row" fxLayoutAlign="start center"
                fxLayoutGap="8px" class="category-class action-training">
                <span *ngIf="!isModalAction">{{ ('generic.CATEGORY' | translate )}}: <b
                    [ngClass]="{'red-text': hasMoreTrainingAction }">{{ actionCategory }}</b></span>
                <span *ngIf="isModalAction && hasMoreTrainingAction" class="warning-message">{{
                  ('idp.MODAL_TRAINING_ACTION_ASSIGNED' | translate )}}</span>
                <span *ngIf="!isModalAction && hasMoreTrainingAction" class="warning-message">{{
                  ('idp.TRAINING_ACTION_ASSIGNED' | translate )}}</span>
                <div *ngIf="!isModalAction && hasMoreTrainingAction" fxLayout="row" fxLayoutAlign="center center"
                  class="warning-message-icon">
                  <svg-icon fxLayout="row" fxLayoutAlign="center center" src="assets/img/icons/esclamation-mark.svg">
                  </svg-icon>
                </div>
              </div>

              <!-- peso e categoria per gli obiettivi -->
              <div *ngIf="isObjective && !isOpen" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px"
                class="category-class" [ngClass]="{'fixed-left': !statusToLeft}">
                <span>{{ ('generic.CATEGORY' | translate )}}: <b>{{ getCategory() }}</b></span>
                <span *ngIf="!weightEdit">{{ ('generic.WEIGHT' | translate )}}:
                  <b>{{ getWeight() }}</b></span>

                <!--div *ngIf="weightEdit" fxLayout="row" fxLayoutAlign="start center"
                                    style="max-height: 20px">
                                    <span>{{ ('generic.WEIGHT' | translate )}}:</span>
                                    <custom-input [type]="'text'" [bindValue]="weightModel" inputClass="weight-input"
                                        [placeholder]="''" (onModelChanged)="emitOnWeightInput($event)"
                                        inputContainerClass="header-searchBar">
                                    </custom-input>
                                </div-->
              </div>
            </div>
          </div>
          <!-- Contenuti accordion disabilitato -->
          <div fxLayout="row" fxLayoutAlign="start center" *ngIf="isDisabled" class="disabled-container">
            <div *ngIf="!secondWordLeft && !thirdWordLeft" fxLayout="row" fxLayoutAlign="start center"
              class="full-width">
              <p *ngIf="!isDisabledModalText" class="title white-space-normal">{{title}}
                <span class="is-disabled-hint">{{isDisabledText || ''}}</span>
              </p>
              <div *ngIf="isDisabledModalText" class="full-width" fxLayout="column" fxLayoutAlign="start start">
                <p class="title">{{title}}</p>
                <span class="warning-message-modal">{{isDisabledModalText || ''}}</span>
              </div>
            </div>
            <div *ngIf="secondWordLeft || thirdWordLeft" class="full-width" fxLayout="column"
              fxLayoutAlign="start start">
              <p class="title with-other-words"
                [ngClass]="{'bold': isObjective || boldText, 'use-all-space': !rightText && !iconRight}">{{ mobileTitle ? (innerWidth >= 960 ? title : mobileTitle) : title}}</p>
              <span *ngIf="secondWordLeft" class="title-other-word">{{secondWordLeft}}</span>
              <span *ngIf="thirdWordLeft" class="title-other-word title">{{thirdWordLeft}}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- container immagine destra -->
      <div *ngIf="!isCheckbox && !rightText && ((iconRight && !isDisabled) || imageRight)" class="image-right-class"
        fxLayout="row" fxLayoutAlign="center center">
        <div style="height: 50px" fxLayout="row" fxLayoutAlign="center center">
          <svg-icon *ngIf="iconRight && !isDisabled" [src]="iconRight"></svg-icon>
        </div>
        <svg-icon [ngClass]="{ 'is-profile': isAvatar}" *ngIf="imageRight && imageRight.indexOf('svg') >= 0"
          [src]="imageRight"></svg-icon>
        <img class="avatar-image-class" [ngClass]="{ 'is-profile-img': isAvatar}"
          *ngIf="imageRight && imageRight.indexOf('svg') < 0" [src]="imageRight">
      </div>
      <div *ngIf="!isCheckbox && !rightText && statusToRight" class="circle {{statusClass}}">
        <p fxShow fxHide.xs translate="statuses.{{status}}"></p>
      </div>
      <div *ngIf="isCheckbox && !requestCompetenceStatus" class="checkbox-indicator" [ngClass]="{ 'checked': isChecked }">
        <svg-icon *ngIf="isChecked" src="../../../../assets/img/icons/check-mark.svg"></svg-icon>
      </div>
      <div *ngIf="rightText" class="right-text">
        <p>{{ rightText }}</p>
      </div>
    </div>
  </button>


  <!-- Obiettivo con peso editabile -->
  <div *ngIf="weightEdit" class="item-list-wrapper full-width"
    [ngClass]="{'no-border-bottom': (isOpen && !fromGSHistory), 'pointer-none': (isDisabledUser || iconLeftAvatar) , 'margin-top-with-user-data': iconLeftAvatar, 'past-objective': isPastObjective, 'objective': isObjective, 'is-disabled': isDisabled, 'big': isBig, 'weight-edit-wrapper': weightEdit}"
    fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
    <div *ngIf="iconLeft || iconLeftAvatar || imageSrc || (statusToLeft && !hideStatus)">
      <svg-icon *ngIf="iconLeft" aria-hidden="true" class="icon-man-style" [src]="iconLeft"></svg-icon>
      <img *ngIf="iconLeftAvatar" class="img-avatar no-pointer" [src]="iconLeftAvatar">
      <img *ngIf="imageSrc" class="img-avatar" [src]="imageSrc">
      <div class="circle {{statusClass}}" *ngIf="statusToLeft && !hideStatus">
        <p fxShow fxHide.xs translate="statuses.{{status}}"></p>
      </div>
    </div>

    <!-- Contenuto centrale titoli, nome, link -->
    <div class="full-width item-list-container" fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div *ngIf="isDisabledUser" class="title-container" fxLayout="column" fxLayoutAlign="start start">
          <div fxLayout="row" fxLayoutAlign="center center">
            <p *ngIf="!useHighlighTextPipe" class="title"
              [ngClass]="{'bold': isObjective || boldText, 'use-all-space': !rightText && !iconRight}">{{ mobileTitle ? (innerWidth >= 960 ? title : mobileTitle) : title}}
            </p>
          </div>
          <span class="is-disabled-user-text grey" translate="generic.NOT_ACTIVE_PROCESS"></span>
          <span [ngClass]="{'no-events': isInDashboard}" class="is-disabled-user-text margin-top4 pointable"
            translate="generic.STORIC_VALUTATIONS"></span>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" class="title-container">
          <!-- Contenuti accordion effettivo -->
          <div class="full-width" fxLayout="row" fxLayoutAlign="start center" *ngIf="!isDisabled">
            <div fxLayout="column">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <span *ngIf="isObjective" class="bold-text">{{order}}.</span>
                <div *ngIf="!secondWordLeft && !thirdWordLeft && !isDisabledUser" class="full-width" fxLayout="row"
                  fxLayoutAlign="start center">
                  <p *ngIf="!useHighlighTextPipe" class="title"
                    [ngClass]="{'bold': isObjective || boldText, 'use-all-space': !rightText && !iconRight}">{{ mobileTitle ? (innerWidth >= 960 ? title : mobileTitle) : title}}</p>
                  <p [ngClass]="{'bold': boldText}" *ngIf="useHighlighTextPipe"
                    [innerHTML]="title" class="title"></p>
                  <div *ngIf="isRequired" class="red-text">*</div>
                </div>
                <div *ngIf="secondWordLeft || thirdWordLeft" class="full-width" fxLayout="column"
                  fxLayoutAlign="start start">
                  <p *ngIf="!useHighlighTextPipe" class="title"
                    [ngClass]="{'bold': isObjective || boldText, 'use-all-space': !rightText && !iconRight}">{{ mobileTitle ? (innerWidth >= 960 ? title : mobileTitle) : title}}</p>
                  <span *ngIf="secondWordLeft" class="title-other-word">{{secondWordLeft}}</span>
                  <span *ngIf="thirdWordLeft" class="title-other-word">{{thirdWordLeft}}</span>
                </div>
                <svg-icon *ngIf="hasTooltip" aria-hidden="true" class="info-icon" (click)="toggleTooltip($event)"
                  [src]="'assets/img/icons/gear-informativa.svg'" tooltip-class="custom-tooltip" hide-delay="250"
                  tooltip="{{('generic.LOGBOOK_TOOLTIP' | translate )}}" trigger="click" placement="top">
                </svg-icon>
              </div>
              <!-- peso e categoria per gli obiettivi -->
              <div *ngIf="isObjective && !isOpen" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px"
                class="category-class" [ngClass]="{'fixed-left': !statusToLeft}">
                <span>{{ ('generic.CATEGORY' | translate )}}: <b>{{ getCategory() }}</b></span>

                <div fxLayout="row" fxLayoutAlign="start center" style="max-height: 20px">
                  <span>{{ ('generic.WEIGHT' | translate )}}:</span>
                  <custom-input [type]="'text'" [percentage]="true" [bindValue]="weightModel" inputClass="weight-input"
                    [placeholder]="''" (onModelChanged)="emitOnWeightInput($event)" [isWeightOver]="isWeightOver"
                    inputContainerClass="header-searchBar">
                  </custom-input>
                  <span *ngIf="isWeightOver" class="warning-message" translate="goal.WARNING_WEIGHT"></span>
                  <div fxLayout="row" fxLayoutAlign="center center" *ngIf="isWeightOver" class="warning-message-icon">
                    <svg-icon fxLayout="row" fxLayoutAlign="center center" src="assets/img/icons/esclamation-mark.svg">
                    </svg-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- container immagine destra -->
      <button *ngIf="!isCheckbox && !rightText && ((iconRight && !isDisabled) || imageRight)"
        class="image-right-class no-style-button" fxLayout="row" fxLayoutAlign="center center"
        (click)="emitOnItemClicked()">
        <div style="height: 50px" fxLayout="row" fxLayoutAlign="center center">
          <svg-icon *ngIf="iconRight && !isDisabled" [src]="iconRight"></svg-icon>
        </div>
        <svg-icon [ngClass]="{ 'is-profile': isAvatar}" *ngIf="imageRight && imageRight.indexOf('svg') >= 0"
          [src]="imageRight"></svg-icon>
        <img class="avatar-image-class" [ngClass]="{ 'is-profile-img': isAvatar}"
          *ngIf="imageRight && imageRight.indexOf('svg') < 0" [src]="imageRight">
      </button>
      <div *ngIf="!isCheckbox && !rightText && statusToRight" class="circle {{statusClass}}">
        <p fxShow fxHide.xs translate="statuses.{{status}}"></p>
      </div>
      <div *ngIf="isCheckbox" class="checkbox-indicator" [ngClass]="{ 'checked': isChecked }">
        <svg-icon *ngIf="isChecked" src="../../../../assets/img/icons/check-mark.svg"></svg-icon>
      </div>
      <div *ngIf="rightText" class="right-text">
        <p>{{ rightText }}</p>
      </div>
    </div>
  </div>

</div>