/*
 * Servizio che invia i dati al DataLayer di Google Tag Manager per Google Analytics emettendo anche gli eventi di tracciamento.
 *
 * Per funzionare necessita di:
 * - Un pezzo di codice nel constructor dell'app.component:
 *     // Creo e carico lo script di Google Tag Manager per Google Analytics
 *     const script = document.createElement('script');
 *     script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
 *     new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
 *     j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
 *     'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
 *     })(window,document,'script','dataLayer','${environment.googleTagManagerCode}')`
 *     document.head.insertBefore(script, null);
 *     // Fine
 * - un attributo nell'environment che lo leghi al TagManager (es: googleTagManagerCode: "GTM-XXXXXX")
 * - poiché il googleTagManagerCode è diverso per produzione e non produzione, per la build in sviluppo o staging è necessario
 *   utilizzare la seguente sintassi, affinché venga creato il pacchetto bundle finale in stile produzione
 *   ma con il codice Google Tag Manager corretto: ng build —aot —configuration=dev
 */

import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../ngrx/app.reducers";
import * as moment from "moment";
import { environment } from "src/environments/environment";
import { User } from "atfcore-commonclasses";

/** Elenco degli userId da ignorare. Sono una copia di quelli definiti in AtfCore conf/default.json */
const USERIDS_TO_SKIP_IN_PRODUCTION = [
  "0000",
  "1111",
  "2222",
  "DEMO",
  "DEMO1",
  "DEMO2",
  "DEMO3",
  "DEMO4",
  "DEMO5",
  "DEMO6",
  "DEMO7",
  "DEMO8",
  "DEMO9",
  "DEMO10",
  "DEMO_2",
];

const STREAM_SOURCE = "Go Generali Admin Web";
@Injectable({
  providedIn: "root",
})
export class AnalyticsService {
  private store: Store<fromApp.AppState>;
  private commonCustomDimensions: CommonCustomDimensions;
  private loggedUser: User;

  // Tengo i dati dell'ultima pagina vista per poterla inviare negli eventi e evitare invii doppi (es: al refresh del token)
  private lastPageSent: string;
  private lastTitleSent: string;

  constructor(_store: Store<fromApp.AppState>) {
    this.store = _store;
  }

  /** Metodo che permette di ignorare l'invio ad Analytics di un set di utenti di test in ambiente di produzione */
  private skipSendForTestUsers(): boolean {


    // TODO Disabilito interamente l'invio dei dati ad Analytics
    return true;


    return !!(
      environment.skipSendTestUsersToAnalytics &&
      this.loggedUser &&
      USERIDS_TO_SKIP_IN_PRODUCTION.some(
        (skip) => skip == this.loggedUser.userId
      )
    );
  }

  /** Calcola le fasce partendo da una data */
  private calcYearRange(refDate: Date): string {
    if (refDate) {
      let now = moment();
      let ref = moment(refDate);
      var yearDiff = now.diff(ref, "years");
      if (yearDiff <= 2) {
        return "0-2";
      } else if (yearDiff <= 5) {
        return "3-5";
      } else if (yearDiff <= 10) {
        return "6-10";
      } else if (yearDiff <= 15) {
        return "11-15";
      } else if (yearDiff <= 20) {
        return "16-20";
      } else if (yearDiff <= 30) {
        return "21-30";
      } else if (yearDiff <= 40) {
        return "31-40";
      } else if (yearDiff <= 50) {
        return "41-50";
      } else if (yearDiff <= 60) {
        return "51-60";
      } else if (yearDiff <= 70) {
        return "61-70";
      } else if (yearDiff <= 80) {
        return "71-80";
      } else {
        return "80+";
      }
    }
    return null;
  }

  /** L'utente sta navigando su una nuova pagina */
  sendVirtualPageViewEvent(
    pageUrl: string,
    pageTitle: string,
    pageViewCustomDimensions?: VirtualPageViewCustomDimensions
  ) {
    // Se non ho ancora i dati comuni dell'utente...
    if (!this.commonCustomDimensions) {
      // ...li calcolo
      this.store.select(fromApp.getLoggedUser).subscribe((loggedUser) => {
        if (loggedUser && loggedUser.user) {
          this.loggedUser = loggedUser.user;
          this.commonCustomDimensions = {
            fasciaEta: this.calcYearRange(loggedUser.user.dataNascita),
            chiaveSesso: loggedUser.user.chiaveSesso,
            defTpDip: loggedUser.user.defTpDip,
            socAppartenenza: loggedUser.user.socAppartenenza,
            socDistacco: loggedUser.user.socDistacco,
            chief: loggedUser.user.chief,
            sedeLavoro: loggedUser.user.sedeLavoro,
          };
        }
        // ... e poi chiamo Analytics
        this._sendPageViewInternal(
          pageUrl,
          pageTitle,
          pageViewCustomDimensions,
          this.commonCustomDimensions
        );
      });
    } else {
      // Se li ho già, chiamo subito Analytics
      this._sendPageViewInternal(
        pageUrl,
        pageTitle,
        pageViewCustomDimensions,
        this.commonCustomDimensions
      );
    }
  }

  private _sendPageViewInternal(
    pageUrl: string,
    pageTitle: string,
    pageViewCustomDimensions: VirtualPageViewCustomDimensions,
    commonDimensions: CommonCustomDimensions
  ) {
    if (!this.skipSendForTestUsers()) {
      // Taglio l'url per togliere i parametri
      let pageUrlParts = pageUrl && pageUrl.split("?");
      let newPage = (pageUrlParts && pageUrlParts[0]) || null;
      // Se la nuova pagina è la stessa che ho "appena" inviato, non la invio di nuovo
      if (newPage !== this.lastPageSent || pageTitle !== this.lastTitleSent) {
        this.lastPageSent = newPage;
        this.lastTitleSent = pageTitle;
        let pvcd = <VirtualPageViewCustomDimensions>(
          (pageViewCustomDimensions || {})
        );
        let ccd = <CommonCustomDimensions>(commonDimensions || {});

        window["dataLayer"].push({
          event: "VirtualPageView",
          page: newPage,
          title: pageTitle,
          fasciaEta: ccd.fasciaEta || null,
          chiaveSesso: ccd.chiaveSesso || null,
          defTpDip: ccd.defTpDip || null,
          socAppartenenza: ccd.socAppartenenza || null,
          socDistacco: ccd.socDistacco || null,
          chief: ccd.chief || null,
          sedeLavoro: ccd.sedeLavoro || null,
          id: pvcd.id || null,
          titleDetail: pvcd.titleDetail || null,
          isMandatoryTraining:
            pvcd.isMandatoryTraining === true ||
            pvcd.isMandatoryTraining === false
              ? pvcd.isMandatoryTraining
              : null,
          itemType: pvcd.itemType || null,
          itemTypeSpecialization: pvcd.itemTypeSpecialization || null,
          streamSource: STREAM_SOURCE
        });
      }
    }
  }

  /** L'utente essendo su una pagina salva una lista nella pianificazione organici */
  sendOrganicListSavedEvent(id: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualObjectOrganicListSavedEvent",
        id: id,
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        title: this.lastTitleSent,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
          streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente essendo su una pagina salva il potenziale */
  sendPotentialSavedEvent(year: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualObjectPotentialSavedEvent",
        id: year,
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        title: this.lastTitleSent,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
          streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente essendo su una pagina conferma la lista lato HRBP nella pianificazione organici */
  sendOrganicListConfirmedByHRBPEvent(id: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualObjectOrganicListConfirmedByHRBP",
        id: id,
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        title: this.lastTitleSent,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
          streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente essendo su una pagina apre un profilo personas */
  sendPersonasProfileOpenedEvent(id: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualObjectPersonasProfileOpened",
        id: id,
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        title: this.lastTitleSent,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
          streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente essendo su una pagina conferma la survey della personas */
  sendPersonasSurveySentEvent() {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualObjectPersonasSurveySent",
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        title: this.lastTitleSent,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
          streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente essendo su una pagina salva le competenze GrowIT nel my experience */
  sendGrowItCompetencesSavedEvent() {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualObjectGrowItCompetencesSaved",
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        title: this.lastTitleSent,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
          streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente essendo su una pagina crea una nuova competenza nel my experience */
  sendMyExperienceCompetenceCreatedEvent() {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualObjectMyExperienceCompetenceCreated",
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        title: this.lastTitleSent,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
          streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente essendo su una pagina aggiorna una nuova competenza nel my experience */
  sendMyExperienceCompetenceUpdatedEvent() {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualObjectMyExperienceCompetenceUpdated",
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        title: this.lastTitleSent,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
          streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente essendo su una pagina scarica la scheda persona */
  sendPersonScheduleDownloadedEvent() {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualObjectPersonScheduleDownloaded",
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        title: this.lastTitleSent,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
          streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente essendo su una pagina scarica un report */
  sendObjectDownloadReportEvent(id: string, reportName?: string, pageUrl?: string, pageTitle?: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualObjectDownloadReport",
        id: id,
        reportName: reportName,
        page: pageUrl || this.lastPageSent,
        title: pageTitle || this.lastTitleSent,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
          streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente essendo su una pagina apre il video tutorial */
  sendVideoTutorialOpenedEvent(id: string, pageUrl?: string, pageTitle?: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualObjectVideoTutorialOpened",
        id: id,
        page: pageUrl || this.lastPageSent,
        title: pageTitle || this.lastTitleSent,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
          streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente essendo su una pagina apre le aree di forza e di miglioramento */
  sendStrengthAndImprovementAreasOpenedEvent(pageUrl?: string, pageTitle?: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualObjectStrengthAndImprovementAreasOpened",
        page: pageUrl || this.lastPageSent,
        title: pageTitle || this.lastTitleSent,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
          streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente essendo su una pagina salva una azione */
  sendActionSavedEvent(pageUrl?: string, pageTitle?: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualObjectActionSaved",
        page: pageUrl || this.lastPageSent,
        title: pageTitle || this.lastTitleSent,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
          streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente essendo su una pagina elimina una azione */
  sendActionDeletedEvent(pageUrl?: string, pageTitle?: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualObjectActionDeleted",
        page: pageUrl || this.lastPageSent,
        title: pageTitle || this.lastTitleSent,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
          streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente essendo su una pagina assegna una azione */
  sendActionAssignedEvent(pageUrl?: string, pageTitle?: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualObjectActionAssigned",
        page: pageUrl || this.lastPageSent,
        title: pageTitle || this.lastTitleSent,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
          streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente essendo su una pagina assegna una obiettivo */
  sendGoalAssignedEvent(pageUrl?: string, pageTitle?: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualObjectGoalAssigned",
        page: pageUrl || this.lastPageSent,
        title: pageTitle || this.lastTitleSent,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
          streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente essendo su una pagina cerca disponibilità oraria per il colloquio */
  sendHourlyAvailabilityForInterviewSoughtEvent(pageUrl?: string, pageTitle?: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualObjectHourlyAvailabilityForInterviewSought",
        page: pageUrl || this.lastPageSent,
        title: pageTitle || this.lastTitleSent,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
          streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente essendo su una pagina esporta il PDF della scheda del colloquio */
  sendInterviewSchedulePdfPrintedEvent(pageUrl?: string, pageTitle?: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualObjectInterviewSchedulePdfPrinted",
        page: pageUrl || this.lastPageSent,
        title: pageTitle || this.lastTitleSent,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
          streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente essendo su una pagina condivide e chiude la scheda colloquio */
  sendInterviewScheduleClosedEvent(pageUrl?: string, pageTitle?: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualObjectInterviewScheduleClosed",
        page: pageUrl || this.lastPageSent,
        title: pageTitle || this.lastTitleSent,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
          streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente essendo su una pagina conferma il colloquio */
  sendInterviewConfirmedEvent(pageUrl?: string, pageTitle?: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualObjectInterviewConfirmed",
        page: pageUrl || this.lastPageSent,
        title: pageTitle || this.lastTitleSent,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
          streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente essendo su una pagina carica gli obiettivi al processo */
  sendObjectGoalToProcessAddedEvent(year: string, processName?: string, pageUrl?: string, pageTitle?: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualGoalToProcessAdded",
        year: year,
        processName: processName,
        page: pageUrl || this.lastPageSent,
        title: pageTitle || this.lastTitleSent,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
          streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente essendo su una pagina impersonifica una persona */
  sendObjectImpersonateEvent(pageUrl?: string, pageTitle?: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualImpersonate",
        page: pageUrl || this.lastPageSent,
        title: pageTitle || this.lastTitleSent,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
          streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente essendo su una pagina salva la matrice */
  sendObjectMatrixSavedEvent(pageUrl?: string, pageTitle?: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualObjectMatrixSaved",
        page: pageUrl || this.lastPageSent,
        title: pageTitle || this.lastTitleSent,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
          streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente essendo su una pagina salva lìarea di comparazione del potenziale */
  sendObjectPotentialComparisonSavedEvent(pageUrl?: string, pageTitle?: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualObjectPotentialComparisonSaved",
        page: pageUrl || this.lastPageSent,
        title: pageTitle || this.lastTitleSent,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
          streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente essendo su una pagina salva lìarea di comparazione del potenziale */
  sendObjectPerformanceComparisonSavedEvent(pageUrl?: string, pageTitle?: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualObjectPerformanceComparisonSaved",
        page: pageUrl || this.lastPageSent,
        title: pageTitle || this.lastTitleSent,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
          streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente essendo su una pagina riapre una scheda */
  sendObjectScheduleUnlockedEvent(pageUrl?: string, pageTitle?: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualObjectScheduleUnlocked",
        page: pageUrl || this.lastPageSent,
        title: pageTitle || this.lastTitleSent,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
          streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente essendo su una pagina salva tutte le fasi */
  sendObjectAllPhasesSavedEvent(pageUrl?: string, pageTitle?: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualObjectAllPhasesSaved",
        page: pageUrl || this.lastPageSent,
        title: pageTitle || this.lastTitleSent,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
          streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente essendo su una pagina apre la sezione per il supporto */
  sendViewSupportSectionEvent() {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualViewSupportSection",
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        title: this.lastTitleSent,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
          streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente essendo su una pagina effettua una ricerca di contenuti (solo ricerca iniziale - no paginazioni successive, no filtri aggiuntivi) */
  sendSearchEvent(searchedText: string, pageUrl?: string, pageTitle?: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualSearch",
        searchedText: searchedText,
        page: pageUrl || this.lastPageSent,
        title: pageTitle || this.lastTitleSent,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
          streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente essendo su una pagina apre il logbook */
  sendLogbookOpenedEvent(year: string, pageUrl?: string, pageTitle?: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualLogbookOpened",
        year: year,
        page: pageUrl || this.lastPageSent,
        title: pageTitle || this.lastTitleSent,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
          streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente sta per lasciare la piattaforma per accedere ad un link esterno */
  sendOutboundLinkEvent(destinationUrl: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualOutboundLink",
        destinationUrl: destinationUrl,
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        title: this.lastTitleSent,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
          streamSource: STREAM_SOURCE
      });
    }
  }
}

export class CommonCustomDimensions {
  fasciaEta: string;
  chiaveSesso: string;
  defTpDip: string;
  socAppartenenza: string;
  socDistacco: string;
  chief: string;
  sedeLavoro: string;
}

export class VirtualPageViewCustomDimensions {
  id?: string; // Id del contenuto (itemId di un oggetto Library/Corso, oppure userId di un utente)
  titleDetail?: string; // Ulteriori specifiche al titolo della pagina (il nome del corso o della persona di cui sto guardando il dettaglio)
  isMandatoryTraining?: boolean; // Se corso di formazione obbligatoria (solo nella pagina di dettaglio)
  itemType?: string; // Tipologia di corso
  itemTypeSpecialization?: string; // Tipologia di corso in deroga
}
