<div fxLayout="column">
  <!-- Switch editabilità -->
  <div fxLayout="row" *ngIf="mode === 'RANGE' && label">
    <h5 translate="{{ label }}"></h5>

    <input *ngIf="showEditSwitch" type="checkbox" attr.aria-label="{{editSwitchFlag}}" [(ngModel)]="editSwitchFlag" [ngModelOptions]="{standalone: true}"
      class="material-switch">
    <label *ngIf="showEditSwitch" class="switch-label" (click)="emitToggleEditable(editSwitchFlag)">
      <span translate="awWizard.generalInfo.EDITABLE"></span>
    </label>
  </div>

  <div fxLayout="column" *ngIf="mode !== 'RANGE'" class="generali-input margin-top0">
    <div class="title-input" *ngIf="label && !required">
      <span class="title-text" translate="{{label | translate}}"></span>
    </div>
    <div class="title-input" *ngIf="label && required">
        <div class="full-width" fxLayout="row" fxLayoutAlign="start center">
        <span class="title-text" translate="{{label | translate}}"></span>
        <div *ngIf="!value" class="required-red">*</div>
        </div>
      </div>
    <div fxLayout="row" fxLayoutAlign="start center" class="time-container">
    <input #singleTimeInput [owlDateTime]="dt4" placeholder="{{ placeholder | translate }}" attr.aria-label="{{ placeholder | translate }}" class="time-input-user-performance datetimepicker-input"
      [ngModel]="value" (ngModelChange)="updateValueFromInputText(singleTimeInput);"
      (dateTimeChange)="updateValue($event);">
      <span>
          <button type="button" attr.aria-label="{{label | translate}}" [owlDateTimeTrigger]="dt4" [disabled]="disabled">
              <svg-icon src="../../../assets/img/icons/chevron-down.svg"></svg-icon>
          </button>
        </span>
    <owl-date-time [pickerType]="'timer'" hour12Timer="false" #dt4></owl-date-time>
  </div>
  </div>

  <div class="generali-input" fxLayout="row" *ngIf="mode === 'RANGE'">
    <div fxFlex="50" fxLayout="column">
      <div class="title-input">
        <h5 class="" translate="Ora inizio"></h5>
      </div>
      <input #startTimeInput placeholder="{{ placeholder | translate }}"  attr.aria-label="{{ placeholder | translate }}" class="time-input datetimepicker-input"
        [owlDateTimeTrigger]="dt5" [owlDateTime]="dt5" [ngModel]="startTime" (ngModelChange)="updateStartTimeFromInputText(startTimeInput);"
        (dateTimeChange)="updateStartTime($event);" [min]="min" [max]="max">
      <owl-date-time [pickerType]="'timer'" [startAt]="startAt" hour12Timer="false" #dt5></owl-date-time>
    </div>
    <div fxFlex="50" fxLayout="column">
      <div class="title-input">
        <h5 class="" translate="Ora fine"></h5>
      </div>
      <input #endTimeInput placeholder="{{ placeholder | translate }}" attr.aria-label="{{ placeholder | translate }}" class="time-input datetimepicker-input"
        [owlDateTimeTrigger]="dt6" [owlDateTime]="dt6" [ngModel]="endTime" (ngModelChange)="updateEndTimeFromInputText(endTimeInput);"
        (dateTimeChange)="updateEndTime($event);" [min]="min" [max]="max">
      <owl-date-time [pickerType]="'timer'" [startAt]="startAt" hour12Timer="false" #dt6></owl-date-time>
    </div>
  </div>

  <div fxLayout="row" *ngIf="fControl  && (fControl.dirty || fControl.touched) && fControl.invalid" class="alert alert-danger">
    <div [hidden]="!fControl.errors.required">
      {{ errors.required | translate }}
    </div>
    <div [hidden]="!fControl.errors.minlength">
      {{ errors.minlength | translate }}
    </div>
    <div [hidden]="!fControl.errors.maxlength">
      {{ errors.maxlength | translate }}
    </div>
    <div [hidden]="!fControl.errors.invalidStartDateTime">
      {{ 'errors.forms.dates.ERROR_INVALID_START_DATETIME' | translate }}
    </div>
    <div [hidden]="!fControl.errors.invalidEndDateTime">
      {{ 'errors.forms.dates.ERROR_INVALID_END_DATETIME' | translate }}
    </div>
    <div [hidden]="!fControl.errors.invalidRangeOfTime">
      {{ 'errors.forms.dates.ERROR_INVALID_RANGE_OF_TIME' | translate }}
    </div>
  </div>

</div>