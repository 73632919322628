<div class="card-container">
  <div class="image-container">
    <img [src]="image ? image : 'assets/img/placeholder-image.svg'">
  </div>
  <div class="info-container">
    <div class="header-info-container">
      <div class="first-row">
        <div class="season-box">
          <p>{{index}}</p>
        </div>
        <menu-list [label]="'generic.ACTIONS' | translate" menuClass="table-link" [menuItems]="menuItems"
          (onMenuItemClicked)="menuAction($event)"></menu-list>
      </div>
      <div class="divisor-line"></div>
      <div class="second-row">
        <p>{{ title | translate }}</p>
      </div>
      <p *ngIf="duration" class="duration-text">{{ duration }}</p>
    </div>
    <div class="description-container">
      <p [innerHTML]="description"></p>
    </div>
    <div *ngIf="categories && categories.length > 0" class="type-container">
      <div *ngFor="let cat of categories" class="container-box categories-tag-text" [title]="cat">
        <p>{{ cat }}</p>
      </div>
    </div>
    <!--<div class="type-container">
      <div *ngIf="category" class="container-box">
        <p>{{ category | translate }}</p>
      </div>
      <div *ngIf="subCategory" class="container-box">
        <p>{{ subCategory | translate }}</p>
      </div>
    </div>
  </div>-->
</div>
