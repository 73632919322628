import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RedirectService } from 'src/app/shared/services/redirect.service';

@Component({
    selector: 'input-search',
    templateUrl: './input-search.component.html',
    styleUrls: ['./input-search.component.scss']
})

export class InputSearchComponent implements OnInit {
    @Input() placeholder: string;
    @Input() bindValue: string;
    @Input() disabled: boolean;
    @Input() fromNinebox: boolean;
    @Input() button = false;
    @Input() noMarginTop: boolean;
    @Output() onStartSearch = new EventEmitter();
    @Output() onSearchedTextUpdated = new EventEmitter();

    constructor(
        public redirectService: RedirectService,
        private translate: TranslateService) {
    }

    ngOnInit() {
    }

    updateSearchedText(text){
        this.onSearchedTextUpdated.emit(text); 
    }

    onSubmit(){
        this.onStartSearch.emit(this.bindValue);
    }
}