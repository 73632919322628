// Import dello State dell'autenticazione, che è quello che selezioneremo con lo slice dallo Store
import * as fromApp from '../../ngrx/app.reducers';

import { Component, OnDestroy } from '@angular/core';

import { DeviceDetectorService } from 'ngx-device-detector';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { UrlService } from '../../shared/services/url.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-login-sso',
  templateUrl: './login-sso.component.html',
  styleUrls: ['./login-sso.component.scss'],
})
export class LoginSSOComponent implements OnDestroy {

  redirectUrl?: string;

  private _unsubscribeSignal$: Subject<void> = new Subject();

  constructor(
    private store: Store<fromApp.AppState>,
    private deviceService: DeviceDetectorService,
    private urlService: UrlService
  ) {
    this.store.select(fromApp.getRedirectUrl)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe((url) => {
        this.redirectUrl = url;
      });

    // Prima di chiamare il servizio per il login, identifico l'userAgent e il tipo di device dell'utente
    const deviceInfo = this.deviceService.getDeviceInfo();
    const userAgent = deviceInfo && deviceInfo.userAgent;
    let deviceType;
    if (this.deviceService.isMobile()) {
      // Salvo il fatto che è uno smartphone
      deviceType = 'P';
    } else if (this.deviceService.isTablet()) {
      // Salvo il fatto che è un tablet
      deviceType = 'T';
    } else if (this.deviceService.isDesktop()) {
      // Salvo il fatto che è un computer desktop
      deviceType = 'D';
    }
    // Ridireziono l'utente verso il sistema di SSO
    let redirectUrlAfterLogin = '';
    if (this.redirectUrl && this.redirectUrl.length) {
      redirectUrlAfterLogin = '/#' + this.redirectUrl;
    } else {
      redirectUrlAfterLogin = '/#/admin/home';
    }
    const redirectUrl = this.urlService.getSSOUrl(
      deviceType,
      userAgent,
      redirectUrlAfterLogin
    );
    window.location.href = redirectUrl;
  }

  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }
}
