/*
*    Dichiarazione dei moduli comuni e delle direttive
*/

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppTranslateModule } from '../translate/translate.module';
import { PaginationComponent } from '../core/pagination/pagination.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { MomentModule } from 'ngx-moment';
import { SidebarModule } from 'ng-sidebar';
import { TooltipModule } from 'ng2-tooltip-directive';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BaseComponent } from './components/base-component.component';
import { ItemListComponent } from './components/item-list/item-list.component';
import { BaseSubscriberComponent } from './components/base-subscriber.component';
import { BaseFormComponent } from './components/base-form.component';
import { DatepickerComponent } from '../core/datepicker/datepicker.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { DisableFormControlDirective } from './directive/disable-form-control.directive';
import { FileUploadModule } from 'ng2-file-upload';
import { ModalComponent } from './components/modal/modal.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { GenericListPlaceholderComponent } from './components/placeholders/generic-list-placeholder/generic-list-placeholder.component';
import { CustomButtonComponent } from './components/custom-button/custom-button.component';
import { CustomInputComponent } from './components/input/input.component';
import { InputSearchComponent } from './components/input-search/input-search.component';
import { TabComponent } from './components/tab/tab.component';
import { ItemActionComponent } from './components/item-action/item-action.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SelectComponent } from './components/select/select.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { SelectWithCheckboxComponent } from './components/select-with-checkbox/select-with-checkbox.component';
import { TimepickerComponent } from '../core/timepicker/timepicker.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { AccordionContentComponent } from './components/accordion-content/accordion-content.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { PageContainerComponent } from './components/page-container/page-container.component';
import { WatchVideoComponent } from './components/watch-video/watch-video.component';
import { KalturaPlayerComponent } from '../core/kaltura-player/kaltura-player.component';
import { PlayerService } from "../core/kaltura-player/player.service";
import { VideoIntroComponent } from './components/video-intro/video-intro.component';
import { BadgesComponent } from './components/badges/badges.component';
import { StarRatingComponent } from './components/star-rating/star-rating.component';
import { CircularIndicatorComponent } from './components/circular-indicator/circular-indicator.component';
import { MenuListComponent } from './components/menu-list/menu-list.component';
import { CKEditorModule } from 'ng2-ckeditor';
import { CardEpisodeComponent } from './components/card-episode/card-episode.component';
import { CardObjectComponent } from './components/card-object/card-object.component';
import { CardLiveComponent } from './components/card-live/card-live.component';
import { ExpandableListComponent } from './components/expandable-list/expandable-list.component';
import { LoaderComponent } from './components/loader/loader.component';

@NgModule({
    declarations: [
        ModalComponent,
        PaginationComponent,
        BaseComponent,
        ItemListComponent,
        BaseSubscriberComponent,
        BaseFormComponent,
        DatepickerComponent,
        TimepickerComponent,
        DisableFormControlDirective,
        GenericListPlaceholderComponent,
        CustomButtonComponent,
        CustomInputComponent,
        InputSearchComponent,
        LoaderComponent,
        TabComponent,
        ItemActionComponent,
        SelectComponent,
        SelectWithCheckboxComponent,
        TextareaComponent,
        SafeHtmlPipe,
        AccordionContentComponent,
        AccordionComponent,
        PageContainerComponent,
        WatchVideoComponent,
        KalturaPlayerComponent,
        VideoIntroComponent,
        BadgesComponent,
        StarRatingComponent,
        CircularIndicatorComponent,
        MenuListComponent,
        CardEpisodeComponent,
        CardObjectComponent,
        CardLiveComponent,
        ExpandableListComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgxPaginationModule,
        FlexLayoutModule,
        AppTranslateModule.forRoot(),
        FileUploadModule,
        MomentModule,
        SidebarModule,
        CKEditorModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        AngularSvgIconModule,
        TooltipModule,
        FormsModule,
        NgSelectModule
    ],
    exports: [
        CommonModule,
        AppTranslateModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        ItemListComponent,
        CKEditorModule,
        FileUploadModule,
        PaginationComponent,
        LoaderComponent,
        MenuListComponent,
        ModalComponent,
        MomentModule,
        SidebarModule,
        BaseComponent,
        BaseSubscriberComponent,
        BaseFormComponent,
        DatepickerComponent,
        TimepickerComponent,
        DisableFormControlDirective,
        AngularSvgIconModule,
        TooltipModule,
        GenericListPlaceholderComponent,
        CustomButtonComponent,
        CustomInputComponent,
        InputSearchComponent,
        TabComponent,
        ItemActionComponent,
        SelectWithCheckboxComponent,
        SelectComponent,
        NgSelectModule,
        TextareaComponent,
        SafeHtmlPipe,
        AccordionContentComponent,
        AccordionComponent,
        PageContainerComponent,
        WatchVideoComponent,
        KalturaPlayerComponent,
        VideoIntroComponent,
        BadgesComponent,
        StarRatingComponent,
        CircularIndicatorComponent,
        CardEpisodeComponent,
        CardObjectComponent,
        CardLiveComponent,
        ExpandableListComponent
    ],
    providers: [
        PlayerService,
    ],
})

export class SharedModule {
}
