import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'watch-video',
  templateUrl: './watch-video.component.html',
  styleUrls: ['./watch-video.component.scss']
})
export class WatchVideoComponent implements OnInit {
  @Input() title: string;
  @Input() videoUrl: string;
  @Input() likes: number;
  @Input() comments: number;
  @Input() videoScript: string;
  @Input() customId: string;
  @Input() playerVisible: boolean;
  @Output() onLikeButtonClicked: EventEmitter<any> = new EventEmitter();
  @Output() onCommentsButtonClicked: EventEmitter<any> = new EventEmitter();
  
  constructor(
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.customId = this.customId || 'introKalturaVideo';
  }
}
