/*
*  Lista di azioni riguardanti i processi admin
*/

import { Action } from '@ngrx/store';

// Salva la lista di processi recuperati
export const SET_PROCESSES_ADMIN = 'SET_PROCESSES_ADMIN';

// Recupera i processi da remoto
export const GET_PROCESSES_ADMIN = 'GET_PROCESSES_ADMIN';

// Setta il contatore dei processi recuperato da remoto
export const SET_PROCESSES_COUNT_ADMIN = 'SET_PROCESSES_COUNT_ADMIN';

// Setta la paginazione
export const SET_PAGINATION_ADMIN = 'SET_PAGINATION_ADMIN';

// Setta la pagina corrente
export const SET_PAGE_ADMIN = 'SET_PAGE_ADMIN';


// Salva la lista di utenti recuperati
export const SET_USERS_ADMIN = 'SET_USERS_ADMIN';

// Recupera i utenti da remoto
export const GET_USERS_ADMIN = 'GET_USERS_ADMIN';

// Salva la lista di utenti totali recuperati
export const SET_TOTAL_USERS_ADMIN = 'SET_TOTAL_USERS_ADMIN';

// Recupera i utenti totali da remoto
export const GET_TOTAL_USERS_ADMIN = 'GET_TOTAL_USERS_ADMIN';

// Setta il contatore dei utenti recuperato da remoto
export const SET_USERS_COUNT_ADMIN = 'SET_USERS_COUNT_ADMIN';

// Setta la paginazione degli utenti
export const SET_PAGINATION_USERS_ADMIN = 'SET_PAGINATION_USERS_ADMIN';

// Setta la pagina corrente degli utenti
export const SET_PAGE_USERS_ADMIN = 'SET_PAGE_USERS_ADMIN';

// Salva la lista di processi recuperati per i setup processi
export const SET_SETUP_PROCESSES_ADMIN = 'SET_SETUP_PROCESSES_ADMIN';

// Recupera i processi da remoto per i setup processi
export const GET_SETUP_PROCESSES_ADMIN = 'GET_SETUP_PROCESSES_ADMIN';

// Setta il contatore dei processi recuperato da remoto per i setup processi
export const SET_SETUP_PROCESSES_COUNT_ADMIN = 'SET_SETUP_PROCESSES_COUNT_ADMIN';

// Setta la paginazione per i setup processi
export const SET_SETUP_PAGINATION_ADMIN = 'SET_SETUP_PAGINATION_ADMIN';

// Setta la pagina corrente per i setup processi
export const SET_SETUP_PAGE_ADMIN = 'SET_SETUP_PAGE_ADMIN';


// Salva la lista di mail reminds recuperati per i mail reminds
export const SET_MAIL_REMINDS_ADMIN = 'SET_MAIL_REMINDS_ADMIN';

// Recupera i mail reminds da remoto per i mail reminds
export const GET_MAIL_REMINDS_ADMIN = 'GET_MAIL_REMINDS_ADMIN';

// Setta il contatore dei mail reminds recuperato da remoto per i mail reminds
export const SET_MAIL_REMINDS_COUNT_ADMIN = 'SET_MAIL_REMINDS_COUNT_ADMIN';

// Setta la paginazione per i mail reminds
export const SET_MAIL_REMINDS_PAGINATION_ADMIN = 'SET_MAIL_REMINDS_PAGINATION_ADMIN';

// Setta la pagina corrente per i mail reminds
export const SET_MAIL_REMINDS_PAGE_ADMIN = 'SET_MAIL_REMINDS_PAGE_ADMIN';

export class SetAdminProcesses implements Action {
    readonly type = SET_PROCESSES_ADMIN;

    constructor(public payload: any[]) { }
}

export class GetAdminProcesses implements Action {
    readonly type = GET_PROCESSES_ADMIN;

    constructor(public payload?: any) { }
}

export class SetCountAdminProcesses implements Action {
    readonly type = SET_PROCESSES_COUNT_ADMIN;

    constructor(public payload: number) { }
}

export class SetAdminPage implements Action {
    readonly type = SET_PAGE_ADMIN;

    constructor(public payload: number) { }
}

export class SetAdminPagination implements Action {
    readonly type = SET_PAGINATION_ADMIN;

    constructor(public payload: { fromRecord: number, numRecords: number }) { }
}


export class SetAdminUsers implements Action {
    readonly type = SET_USERS_ADMIN;

    constructor(public payload: any[]) { }
}

export class GetAdminUsers implements Action {
    readonly type = GET_USERS_ADMIN;

    constructor(public payload?: any) { }
}

export class SetAdminTotalUsers implements Action {
    readonly type = SET_TOTAL_USERS_ADMIN;

    constructor(public payload: any[]) { }
}

export class GetAdminTotalUsers implements Action {
    readonly type = GET_TOTAL_USERS_ADMIN;

    constructor(public payload?: any) { }
}

export class SetCountAdminUsers implements Action {
    readonly type = SET_USERS_COUNT_ADMIN;

    constructor(public payload: number) { }
}

export class SetAdminPageUsers implements Action {
    readonly type = SET_PAGE_USERS_ADMIN;

    constructor(public payload: number) { }
}

export class SetAdminPaginationUsers implements Action {
    readonly type = SET_PAGINATION_USERS_ADMIN;

    constructor(public payload: { fromRecord: number, numRecords: number }) { }
}


export class SetSetupProcessesAdmin implements Action {
    readonly type = SET_SETUP_PROCESSES_ADMIN;

    constructor(public payload: any[]) { }
}

export class GetSetupProcessesAdmin implements Action {
    readonly type = GET_SETUP_PROCESSES_ADMIN;

    constructor(public payload?: { IsEditDataAdminPage?: boolean }) { }
}

export class SetCountSetupProcessesAdmin implements Action {
    readonly type = SET_SETUP_PROCESSES_COUNT_ADMIN;

    constructor(public payload: number) { }
}

export class SetSetupPageAdmin implements Action {
    readonly type = SET_SETUP_PAGE_ADMIN;

    constructor(public payload: number) { }
}

export class SetSetupPaginationAdmin implements Action {
    readonly type = SET_SETUP_PAGINATION_ADMIN;

    constructor(public payload: { fromRecord: number, numRecords: number }) { }
}


export class SetMailRemindsAdmin implements Action {
    readonly type = SET_MAIL_REMINDS_ADMIN;

    constructor(public payload: any[]) { }
}

export class GetMailRemindsAdmin implements Action {
    readonly type = GET_MAIL_REMINDS_ADMIN;

    constructor(public payload?: { IsEditDataAdminPage?: boolean }) { }
}

export class SetCountMailRemindsAdmin implements Action {
    readonly type = SET_MAIL_REMINDS_COUNT_ADMIN;

    constructor(public payload: number) { }
}

export class SetMailRemindsPageAdmin implements Action {
    readonly type = SET_MAIL_REMINDS_PAGE_ADMIN;

    constructor(public payload: number) { }
}

export class SetMailRemindsPaginationAdmin implements Action {
    readonly type = SET_MAIL_REMINDS_PAGINATION_ADMIN;

    constructor(public payload: { fromRecord: number, numRecords: number }) { }
}

export type AdminProcessesActions = SetAdminProcesses |
    SetCountAdminProcesses |
    SetAdminPagination |
    SetAdminPage |
    GetAdminProcesses |
    SetAdminUsers |
    SetCountAdminUsers |
    SetAdminPaginationUsers |
    SetAdminPageUsers |
    GetAdminUsers |
    SetAdminTotalUsers |
    GetAdminTotalUsers |
    SetSetupProcessesAdmin |
    GetSetupProcessesAdmin |
    SetSetupPaginationAdmin |
    SetSetupPageAdmin |
    SetCountSetupProcessesAdmin |
    SetMailRemindsAdmin |
    GetMailRemindsAdmin |
    SetMailRemindsPaginationAdmin |
    SetMailRemindsPageAdmin |
    SetCountMailRemindsAdmin