import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss']
})
export class TabComponent implements OnInit {
  activeTab: any;
  @Input() tabs: any;
  @Input() currentTabId: string;
  @Output() onTabClicked: EventEmitter<string> = new EventEmitter();
  
  constructor() { }

  ngOnInit() {
  }

  // Seleziona una tab dato il suo ID
  selectTab(id: any) {
      let newTab = this.tabs.filter((x: any) => {
        return x.id == id;
      })
      if(newTab && newTab.length) {
      this.currentTabId = newTab[0].id
      this.activeTab = newTab[0];
      this.onTabClicked.emit(id);
      }
    }
  }
