<div class="" [ngClass]="{disabled: disabled}">
  <div fxLayout="row" class="picker-responsive"
    [ngClass]="{'small': !isFromProfilePage, 'from-user-feedback': fromUserFeedback, 'gray-background': grayBackground}"
    fxLayoutAlign="start center">

    <span *ngIf="fromAdminPhaseTable" class="input-group-btn admin-phase-datepicker">
      <button type="button" aria-label="Scegli data" [owlDateTimeTrigger]="dt2" [disabled]="disabled">
        <svg-icon src="../../../assets/img/icons/edit-admin-datepicker.svg"></svg-icon>
      </button>
    </span>
    <input #datePickerInput class="datetimepicker-input" [ngClass]="{'admin-phase-datepicker': fromAdminPhaseTable, 'admin-tfa': true}"
      attr.aria-label="{{ placeholder | translate }}" [owlDateTime]="dt2" [disabled]="disabled"
      placeholder="{{ placeholder | translate }}" [ngModel]="value" [min]="minDate || newDate"
      (ngModelChange)="updateModelFromInputvalue(datePickerInput);" (dateTimeChange)="updateModel(this, $event);"
      [max]="maxDate">

    <span *ngIf="!fromAdminPhaseTable" class="input-group-btn" [ngClass]="{'from-user-feedback': fromUserFeedback}">
      <button type="button" aria-label="Scegli data" [owlDateTimeTrigger]="dt2" [disabled]="disabled">
        <svg-icon *ngIf="!fromUserFeedback" style="width: 24px; height: 24px;" src="../../../assets/img/icons/gear-calendario.svg"></svg-icon>
        <svg-icon *ngIf="fromUserFeedback" src="../../../assets/img/icons/calendar-icon-user.svg"></svg-icon>
      </button>
    </span>
    <owl-date-time class="date-picker" #dt2 [firstDayOfWeek]="options.firstDayOfWeek" [disabled]="disabled"
      [pickerMode]="pickerMode" [pickerType]="pickerType" (afterPickerClosed)="cleanOpenedStyle()"></owl-date-time>
  </div>

  <div fxLayout="row" *ngIf="fControl  && (fControl.dirty || fControl.touched) && fControl.invalid"
    class="alert alert-danger">
    <div [hidden]="!fControl.errors.required">
      {{ errors.required | translate }}
    </div>
    <div [hidden]="!fControl.errors.minlength">
      {{ errors.minlength | translate }}
    </div>
    <div [hidden]="!fControl.errors.maxlength">
      {{ errors.maxlength | translate }}
    </div>
    <div [hidden]="!fControl.errors.invalidStartDateTime">
      {{ 'errors.forms.dates.ERROR_INVALID_START_DATETIME' | translate }}
    </div>
    <div [hidden]="!fControl.errors.invalidEndDateTime">
      {{ 'errors.forms.dates.ERROR_INVALID_END_DATETIME' | translate }}
    </div>
    <div [hidden]="!fControl.errors.invalidEndEnrollDate">
      {{ 'errors.forms.dates.ERROR_INVALID_END_ENROLLMENT_DATE' | translate }}
    </div>
    <div [hidden]="!fControl.errors.invalidRangeOfTime">
      {{ 'errors.forms.dates.ERROR_INVALID_RANGE_OF_TIME' | translate }}
    </div>
  </div>

</div>
