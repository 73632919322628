import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'card-live',
  templateUrl: './card-live.component.html',
  styleUrls: ['./card-live.component.scss']
})
export class CardLiveComponent {
  @Input() cardObject: any;
  @Input() liveTitle: string;
  @Input() liveDescription: string;
  @Input() date: string;
  @Input() image: string;
  @Input() categories?;
  //@Input() subCategory: string;
  
  @Output() cardSelected = new EventEmitter();
  
  showAllData: boolean = false;
  constructor() { }

  ngOnInit() {
  }

  emitOnModelChanged() {
    this.cardSelected.emit();
  }

  showAllInfo() {
    this.showAllData = !this.showAllData;
  }
}
