import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'menu-list',
  templateUrl: './menu-list.component.html',
  styleUrls: ['./menu-list.component.scss']
})
export class MenuListComponent implements OnInit {

  menuOpen = false;
  clicked = false;
  
  @Input() menuItems = [];
  @Input() label;
  @Input() menuClass;
  
  @Output() onMenuItemClicked = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  menuClicked(action: string) {
    this.onMenuItemClicked.emit(action);
  }

  dotsClicked() {
    this.clicked = true;
    this.menuOpen = !this.menuOpen;
  }

  @HostListener('window:click', ['$event']) onClicks(e) {
    if(this.menuOpen == true && this.clicked == false) {
      this.menuOpen = false
    }
    this.clicked = false;
  }

}
