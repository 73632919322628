import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromProfile from '../users/profile/ngrx/profile.reducers';
import * as fromAuth from '../auth/ngrx/auth.reducers';
import * as fromCore from '../core/ngrx/core.reducers';
import * as fromAdminProcesses from '../admin/ngrx/process.reducers';

export interface AppState {
    user: fromProfile.ProfileState;
    auth: fromAuth.AuthState;
    core: fromCore.CoreState;
}

export const reducers: ActionReducerMap<AppState> = {
    user: fromProfile.profileReducer,
    auth: fromAuth.authReducer,
    core: fromCore.coreReducer
};

export const getAuthAppState = createFeatureSelector<fromAuth.AuthState>('auth');
export const getCoreAppState = createFeatureSelector<fromCore.CoreState>('core');
export const getProfileAppState = createFeatureSelector<fromProfile.ProfileState>('user');
export const getAdminProcessesAppState = createFeatureSelector<fromAdminProcesses.ProcessState>('admin');

export const getAuthState = createSelector(
    getAuthAppState,
    (state: fromAuth.AuthState) => state
);
export const getToken = createSelector(
    getAuthState,
    fromAuth.getToken
);
export const getUserAcknowledges = createSelector(
    getAuthState,
    (state: fromAuth.AuthState) => state.userAcknowledges
);

// Selettori relativi allo state del core
export const getCoreState = createSelector(
    getCoreAppState,
    (state: fromCore.CoreState) => state
);
export const isAuthenticated = createSelector(
    getAuthState,
    (state: fromAuth.AuthState) => state.authenticated
);
export const isFetchingLangs = createSelector(
    getCoreAppState,
    fromCore.isFetchingLangs
);
export const getRedirectUrl = createSelector(
    getCoreAppState,
    fromCore.getRedirectUrl
);
export const getApplicationContext = createSelector(
    getCoreAppState,
    fromCore.getApplicationContext
);
export const getGlobalApplicationData = createSelector(
    getCoreAppState,
    fromCore.getGlobalApplicationData
);
export const showApplicationLoader = createSelector(
    getCoreAppState,
    fromCore.showApplicationLoader
);
export const isMainMenuSidebarOpened = createSelector(
    getCoreAppState,
    fromCore.isMainMenuSidebarOpened
);
export const getApplicationLang = createSelector(
    getCoreAppState,
    fromCore.getApplicationLang
);
export const getDefaultLang = createSelector(
    getCoreAppState,
    fromCore.getDefaultLang
);
export const isProfileMenuSidebarOpened = createSelector(
    getCoreAppState,
    fromCore.isProfileMenuSidebarOpened
);

export const getProfileState = createSelector(
    getProfileAppState,
    (state: fromProfile.ProfileState) => state
);
export const getLoggedUser = createSelector(
    getProfileState,
    fromProfile.getLoggedUser
);
export const getAvailableLangs = createSelector(
    getProfileState,
    fromProfile.getAvailableLangs
);
export const getNotificationsCounter = createSelector(
    getCoreAppState,
    fromCore.getNotificationsCounter
);

export const getPhasesInfo = createSelector(
    getCoreAppState,
    fromCore.getPhasesInfo
);

export const isFetchingPhasesInfo = createSelector(
    getCoreAppState,
    fromCore.isFetchingPhasesInfo
);

export const getIsImpersonification = createSelector(
    getCoreAppState,
    fromCore.getIsImpersonification
);

export const getAskedCountCompetences = createSelector(
    getCoreAppState,
    fromCore.getAskedCountCompetences
);
export const isFetchingNumberAskedCompetences = createSelector(
    getCoreAppState,
    fromCore.isFetchingAskedCountCompetences
);

// Selettori relativi allo state dei mail reminds Admin
export const getMailReminds = createSelector(
    getAdminProcessesAppState,
    fromAdminProcesses.getMailReminds
);
export const isFetchingMailReminds = createSelector(
    getAdminProcessesAppState,
    fromAdminProcesses.isFetchingMailReminds
);
export const getMailRemindsCount = createSelector(
    getAdminProcessesAppState,
    fromAdminProcesses.getMailRemindsCount
);
export const getMailRemindsFromRecord = createSelector(
    getAdminProcessesAppState,
    fromAdminProcesses.getMailRemindsFromRecord
);
export const getMailRemindsNumRecords = createSelector(
    getAdminProcessesAppState,
    fromAdminProcesses.getMailRemindsNumRecords
);
export const getMailRemindsPage = createSelector(
    getAdminProcessesAppState,
    fromAdminProcesses.getMailRemindsPage
);

// Selettori relativi allo state dei setup processi Admin
export const getAdminSetupProcesses = createSelector(
    getAdminProcessesAppState,
    fromAdminProcesses.getSetupProcesses
);
export const isFetchingAdminSetupProcesses = createSelector(
    getAdminProcessesAppState,
    fromAdminProcesses.isFetchingSetupProcesses
);
export const getAdminSetupProcessesCount = createSelector(
    getAdminProcessesAppState,
    fromAdminProcesses.getSetupProcessesCount
);
export const getAdminSetupProcessesFromRecord = createSelector(
    getAdminProcessesAppState,
    fromAdminProcesses.getSetupProcessesFromRecord
);
export const getAdminSetupProcessesNumRecords = createSelector(
    getAdminProcessesAppState,
    fromAdminProcesses.getSetupProcessesNumRecords
);
export const getAdminSetupProcessesPage = createSelector(
    getAdminProcessesAppState,
    fromAdminProcesses.getSetupProcessesPage
);

// Selettori relativi allo state dei processi Admin
export const getAdminProcesses = createSelector(
    getAdminProcessesAppState,
    fromAdminProcesses.getProcesses
);
export const isFetchingAdminProcesses = createSelector(
    getAdminProcessesAppState,
    fromAdminProcesses.isFetchingProcesses
);
export const getAdminProcessesCount = createSelector(
    getAdminProcessesAppState,
    fromAdminProcesses.getProcessesCount
);
export const getAdminProcessesFromRecord = createSelector(
    getAdminProcessesAppState,
    fromAdminProcesses.getProcessesFromRecord
);
export const getAdminProcessesNumRecords = createSelector(
    getAdminProcessesAppState,
    fromAdminProcesses.getProcessesNumRecords
);
export const getAdminProcessesPage = createSelector(
    getAdminProcessesAppState,
    fromAdminProcesses.getProcessesPage
);

export const getAdminUsers = createSelector(
    getAdminProcessesAppState,
    fromAdminProcesses.getUsers
);
export const getAdminTotalUsers = createSelector(
    getAdminProcessesAppState,
    fromAdminProcesses.getTotalUsers
);
export const isFetchingAdminUsers = createSelector(
    getAdminProcessesAppState,
    fromAdminProcesses.isFetchingUsers
);
export const isFetchingTotalAdminUsers = createSelector(
    getAdminProcessesAppState,
    fromAdminProcesses.isFetchingTotalUsers
);
export const getAdminUsersCount = createSelector(
    getAdminProcessesAppState,
    fromAdminProcesses.getUsersCount
);
export const getAdminUsersFromRecord = createSelector(
    getAdminProcessesAppState,
    fromAdminProcesses.getUsersFromRecord
);
export const getAdminUsersNumRecords = createSelector(
    getAdminProcessesAppState,
    fromAdminProcesses.getUsersNumRecords
);
export const getAdminUsersPage = createSelector(
    getAdminProcessesAppState,
    fromAdminProcesses.getUsersPage
);

export const getShowFooter = createSelector(
    getCoreAppState,
    fromCore.getShowFooter
);

export const getCurrentYear = createSelector(
    getCoreAppState,
    fromCore.getCurrentYear
)