<div class="container-action" fxLayout="row" fxLayoutAlign="space-between center"
    [ngClass]="{ 'hidden': hide, 'past': isPast}">
    <div class="item-action-padding full-width" fxLayout="column" fxLayoutAlign="start start">
        <!--svg-icon *ngIf="iconLeft" aria-hidden="true" [src]="iconLeft"></svg-icon-->
        <div class="full-width" fxLayout="row" fxLayoutAlign="space-between center">
            <div tabindex="0" fxLayout="row" fxLayoutAlign="start center" class="title">
                {{title}}<div *ngIf="required" class="required-red">*</div>:
            </div>
            <button attr.aria-label="{{'generic.OPEN_MODAL' | translate}}" *ngIf="hasTooltip" class="icon-style-button" (click)="toggleTooltip($event)">
            <svg-icon aria-hidden="true" class="info-icon"
                [src]="'assets/img/icons/gear-informativa.svg'">
            </svg-icon>
        </button>
        <button attr.aria-label="{{'generic.OPEN_CATEGORIES_MODAL' | translate}}" *ngIf="hasCategoryTooltip" class="icon-style-button" (click)="toggleCategoryTooltip($event)">
            <svg-icon aria-hidden="true" class="info-icon"
                [src]="'assets/img/icons/gear-informativa.svg'">
            </svg-icon>
        </button>
        </div>
        <div class="full-width" fxLayout="column">
            <ng-content></ng-content>
        </div>
    </div>
</div>