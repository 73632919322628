<div fxLayout="column">
    <item-list [isDisabledUserCompetence]="isDisabledUserCompetence" [fromGSHistory]="fromGSHistory"
        [requestCompetenceStatus]="requestCompetenceStatus" [isInDashboard]="isInDashboard"
        (onItemClicked)="onItemClicked()" [imageRight]="imageRight" [isOpen]="accordion.isOpened"
        [iconLeft]="accordion.iconLeft"
        [iconRight]="(accordion.hideRightIcon || accordion.isDisabledUser) ? '' : accordion.isOpened ? 'assets/img/icons/arrow-right.svg' : 'assets/img/icons/arrow-down.svg'"
        [isDisabled]="isDisabled" [isDisabledText]="isDisabledText" [imageSrc]="accordion.imageSrc"
        [isDisabledUser]="accordion.isDisabledUser" [status]="accordion.status" [statusClass]="accordion.statusClass"
        [statusToLeft]="accordion.statusToLeft" [statusToRight]="accordion.statusToRight" [title]="accordion.title"
        [mobileTitle]="accordion.mobileTitle" [isPastObjective]="isPastObjective" [isCheckbox]="isCheckboxList"
        [isChecked]="accordion.isChecked" [rightText]="rightText" [useHighlighTextPipe]="useHighlighTextPipe"
        [searchedText]="searchedText" [order]="accordion.order" [isObjective]="accordion.isObjective"
        [contents]="accordion.objectiveContents" [isRequired]="accordion.isRequired" [hasTooltip]="hasTooltip"
        [weightEdit]="weightEdit" [weightModel]="weightModel" (onWeightChanged)="emitOnWeightChanged($event)"
        [boldText]="boldText" [hideStatus]="hideStatus" [secondWordLeft]="accordion.secondWordLeft"
        [isWeightOver]="isWeightOver" [userWeight]="userWeight" [showTrainingActionInfo]="showTrainingActionInfo"
        [hasMoreTrainingAction]="hasMoreTrainingAction" [actionCategory]="actionCategory"
        [isModalAction]="isModalAction" [isDisabledModalText]="isDisabledModalText">
    </item-list>
    <accordion-content [ngClass]="{'hidden': !isDesktop && !accordion.isOpened}" [overflowUnset]="overflowUnset"
        [withPadding]="withPadding" [isOpen]="accordion.isOpened" [isPastObjective]="isPastObjective"
        [negativeMarginContent]="negativeMarginContent">
        <ng-content></ng-content>
    </accordion-content>
</div>